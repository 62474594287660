import React from 'react';
import Button from '@material-ui/core/Button';

const PrimaryButton = ({ children, ...rest }) => {
  let text = typeof children === 'string' ? children : '';

  let variant = rest.variant || 'contained';
  let color = rest.color || (variant === 'contained' ? 'primary' : 'inherit');


  return (
    <Button variant={variant} color={color} aria-label={text} {...rest}>
      {children}
    </Button>
  );
};

export default PrimaryButton;
