const ENVIRONMENT_LICENSES = {
  data: {
    url: "/backend/EnvironmentLicensing",
    createItemUrl:
      "/backend/environmentLicensing/installLicenses?accountToken={accountToken}",
    deleteItemUrl:
      "/backend/environmentLicensing/revokeAndDeleteOnline?accountToken={accountToken}&productKeyId={id}",
    dataKey: "environmentLicenses",
    idKeyProp: "ProductKeyId",
    onBeforeRequest: (config, request) => {
      let url = request.url;

      if (request.type === "createItem" || request.type === "deleteItem") {
        url = url
          .split("{accountToken}")
          .join(encodeURIComponent(config.dataProps.accountToken));
      }

      if (request.type === "deleteItem" && config.dataProps.offline) {
        url =
          "/backend/environmentLicensing/revokeAndReleaseOffline?productKeyId={id}";
        url = url.split("{id}").join(encodeURIComponent(config.id));
      }

      if (request.type === "createItem" && config.dataProps.offline) {
        url = "/backend/environmentLicensing/installOfflineLicense";
      }

      return {
        url
      };
    }
  },
  placeholders: {
    label: data => data.Key,
    type: () => "license",
    icon: () => "license",
    delete: () => "release",
    deleting: () => "releasing"
  }
};

export default ENVIRONMENT_LICENSES;
