//@ts-check
import React from "react";
import { withRouter } from "react-router-dom";
import PageLayout from "../components/PageLayout";
import { DataItem } from "ecrion-components";

const ManageApp = ({ match, history }) => {
  let appId = match && match.params && match.params.id ? match.params.id : null;

  function redirectAppPage(app) {
    if (!app) return <div />;

    if (!app.MicroserviceAppInstallation) history.replace("/app/" + appId);

    return <div />;
  }

  return (
    <PageLayout
      variant="small"
      title="Install App"
      navBarType="closable"
      returnTo="/apps"
    >
      <DataItem dataType="availableMicroserviceApp" id={appId}>
        {({ value }) => redirectAppPage(value)}
      </DataItem>
    </PageLayout>
  );
};

export default withRouter(ManageApp);
