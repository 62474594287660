//@ts-check
import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { Grid, FormLabel, Tooltip } from "@material-ui/core";
import PageLayout from "../components/PageLayout";
import PageAvatar from "../components/PageAvatar";
import { Form, withUserInfo, Icon, Utils, Modals } from "ecrion-components";
import { withStyles, createStyles } from "@material-ui/core/styles";
import SubTitle from "../components/SubTitle";
import { useSettings } from "../context/SettingsContext";
import Button from "../components/Button";

const formGridProps = {
  alignItems: "center",
};

const deleteLogoButtonProps = {
  width: "3px",
  height: "3px",
  float: "right",
  padding: "1px",
  fontSize: "14px",
};

const styles = (theme) =>
  createStyles({
    organizationAvatar: {
      "&:hover": {
        backgroundColor: "blue !important",
      },
    },
    organizationAvatarContainer: {
      margin: "auto",
      "& div": {
        "&:focus": {
          outline: "0px",
        },
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
    organizationLogoWrapper: {
      width: 96,
      height: 96,
      border: "1px solid #000000",
      borderRadius: "50%",
      margin: "auto",
      display: "flex",
      justifyContent: "center",
    },
    organizationLogo: {
      width: "50px",
    },
  });

const Organization = ({ history, userInfo, classes }) => {
  const [hasLogo, setHasLogo] = useState(false);
  const [refreshLogo, setRefreshLogo] = useState(0);

  const settings = useSettings();

  function getPageAvatar(file) {
    if (file) setHasLogo(true);
    else setHasLogo(false);

    return !file ? (
      <div className={classes.organizationLogoWrapper}>
        <img
          className={classes.organizationLogo}
          alt="MHC logo"
          src="/Images/icons/mhc-logo-black.svg"
        />
      </div>
    ) : (
      <PageAvatar src={file.preview}>organization </PageAvatar>
    );
  }

  function deleteOrganizationLogo() {
    Modals.open({
      type: Modals.TYPES.CONFIRMATION,
      props: {
        title: `Remove logo`,
        message: `Are you sure you want to remove your organization logo?`,
        onConfirm: () => {
          return Utils.request({
            url: `/backend/organization`,
            method: "DELETE",
          }).then(() => {
            setRefreshLogo(refreshLogo + 1);
          });
        },
      },
    });
  }

  return (
    <PageLayout variant="small" title="Organization" navBarType="closable">
      {userInfo.environmentName && (
        <Form.Fragment
          key={refreshLogo}
          dataItemProps={{
            dataType: "organization",
            id: userInfo.environmentName,
          }}
          renderContent={({ values }) => (
            <React.Fragment>
              <Grid container direction="column">
                <Grid item className={classes.organizationAvatarContainer}>
                  <SubTitle includedInFormFragment>
                    {hasLogo && (
                      <Icon
                        type="remove"
                        label="Remove logo"
                        tooltipPlacement="right"
                        style={deleteLogoButtonProps}
                        onClick={() => deleteOrganizationLogo()}
                      />
                    )}
                    <Tooltip placement="right" title="Change logo">
                      <Grid item>
                        <Form.Fields.LocalFile
                          name="LogoUrl"
                          preview
                          label={null}
                          accept="image/*"
                          className={classes.organizationAvatar}
                          maxSize={102400}
                        >
                          {(file) => getPageAvatar(file)}
                        </Form.Fields.LocalFile>
                      </Grid>
                    </Tooltip>
                    <Grid
                      container
                      direction="row"
                      justify="center"
                      alignItems="center"
                    >
                      <Grid item>
                        <Icon
                          type="workspace"
                          hoverable
                          label="Configure Workspaces"
                          tooltipPlacement="left"
                          onClick={() => history.push("/workspaces")}
                        />
                      </Grid>
                      <Grid item>
                        <Icon
                          type="key"
                          label="Configure Licenses"
                          tooltipPlacement="right"
                          onClick={() => history.push("/licensing")}
                          hoverable
                        />
                      </Grid>
                    </Grid>
                  </SubTitle>
                </Grid>
                <Grid item>
                  <Grid container {...formGridProps}>
                    <Grid item sm={4} xs={12}>
                      <FormLabel htmlFor="Name">Organization Name</FormLabel>
                    </Grid>
                    <Grid item sm xs>
                      <Form.Fields.Name
                        name="Name"
                        id="name"
                        label={null}
                        type="organizationName"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container {...formGridProps}>
                    <Grid item sm={4} xs={12}>
                      <FormLabel htmlFor="Timezone">Default Timezone</FormLabel>
                    </Grid>
                    <Grid item sm xs>
                      <Form.Fields.DataSelect
                        name="Timezone"
                        label={null}
                        dataType="timezone"
                        id="timezone"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Form.Fields.Checkbox
                    name="TimezoneLocked"
                    label="Set the timezone for all users"
                  />
                </Grid>
              </Grid>
            </React.Fragment>
          )}
          onSuccess={(data) => {
            history.push("/?environment=" + encodeURIComponent(data.Name));
          }}
          onCancel={() => {}}
          renderFooterLeft={() =>
            settings &&
            !settings.CloudDeployment && (
              <Button
                variant="outlined"
                style={{ marginLeft: 8 }}
                onClick={() => {
                  history.push("/sso");
                }}
              >
                Configure Single Sign-On
              </Button>
            )
          }
        />
      )}
    </PageLayout>
  );
};

export default withStyles(styles)(withUserInfo(withRouter(Organization)));
