//@ts-check

/**@type {import('data/types').DataType<CloudEnvironmentsModel>} */
const ORGANIZATION_DATA_TYPE = {
  data: {
    url: '/backend/cloudenvironments',
    dataKey: 'environments',
    idKeyProp: 'name',
    itemUrl: '/backend/cloudenvironments/{id}',
    onAfterResponse: (options, request, responseBody) => {
      return {
        Data: responseBody,
        TotalCount: responseBody.length,
      };
    },
  },
};

export default ORGANIZATION_DATA_TYPE;
