import { Utils } from "ecrion-components";

export const parseUrl = () => {
  const parsed = Utils.url.parseUrl(window.location.search).query;

  return {
    ...parsed,
    expiresAt: parsed.expiresAt ? Number(parsed.expiresAt) : Number.MAX_VALUE
  };
};

const stringifySearchParams = searchParams => {
  let result = "";

  for (const key of Object.keys(searchParams)) {
    if (result) result += "&";
    result +=
      encodeURIComponent(key) + "=" + encodeURIComponent(searchParams[key]);
  }
  return result;
};

const Auth = function(options) {
  return {
    authorize: ({ redirectParams } = {}) => {
      const ssoSearch = {
        "redirect-url":
          options.redirectUri + "?" + stringifySearchParams(redirectParams)
      };

      window.location =
        options.loginUrl +
        "?redirect-url=" +
        encodeURIComponent(ssoSearch["redirect-url"]);
    }
  };
};

export default Auth;
