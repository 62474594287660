//@ts-check
import React from 'react';
import { Grid, Typography, List, LinearProgress } from '@material-ui/core';
import { Icon, DataFilter, DataAction } from 'ecrion-components';
import Item from '../components/Item';
import SubTitle from '../components/SubTitle';
import PageLayout from '../components/PageLayout';
import ProgressiveDataList from '../components/ProgressiveDataList';

const buttonProps = {
  hoverable: true,
};
const Groups = ({ history, location }) => {
  function deleteGroup(groupName) {
    DataAction.item({
      dataType: 'group',
      id: groupName,
    })
      .delete({
        useConfirmation: true,
      })
      .then(res => {
        history.return('/groups');
      });
  }

  return (
    <PageLayout variant="small" title="Groups" navBarType="closable"  returnTo="/users">
      <SubTitle>
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item>
            <DataFilter
              history={history}
              location={location}
              searchIn="query"
            />
          </Grid>
          <Grid item>
            <div
              style={{
                border: '1px solid',
                height: '100%',
                marginLeft: 8,
                minHeight: 8 * 4,
              }}
            />
          </Grid>
          <Grid item>
            <Icon
              type="add"
              label="Add"
              tooltipPlacement="right"
              onClick={() => history.push('/group')}
              {...buttonProps}
            />
          </Grid>
        </Grid>
      </SubTitle>
      <Grid container justify="center">
        <Grid item xs>
          <ProgressiveDataList
            key={location.search}
            dataType="group"
            pageStart={0}
            dataProps={{
              filter: DataFilter.createFromLocation(location),
            }}
          >
            {({ state }) => {
              return (
                <div>
                  {state.data.length === 0 ? (
                    state.view.page === 0 ? (
                      state.view.isLoading ? (
                        <LinearProgress />
                      ) : (
                        <div style={{ textAlign: 'center' }}>
                          {state.view.page === 0 && (
                            <Typography color="inherit">No groups.</Typography>
                          )}
                        </div>
                      )
                    ) : (
                      <div />
                    )
                  ) : (
                    <List>
                      {state.data.map(
                        group =>
                          typeof group !== 'undefined' && (
                            <Item
                              key={group.Name}
                              label={group.Name}
                              to={'/group/' + group.Name}
                              iconType="group"
                              onDelete={{
                                click: () => deleteGroup(group.Name),
                                disabled:
                                  group.Name === 'Administrators' ||
                                  group.Name === 'Everyone',
                              }}
                            />
                          )
                      )}
                    </List>
                  )}
                  {state.view.isLoading && <LinearProgress />}
                </div>
              );
            }}
          </ProgressiveDataList>
        </Grid>
      </Grid>
    </PageLayout>
  );
};

export default Groups;
