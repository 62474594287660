//@ts-check
import React from "react";
import { Form } from "ecrion-components";
import { Grid, FormLabel } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { useAccountsAuth } from "../../../context/AccountsContext";

const formGridProps = {
  alignItems: "center",
  style: { marginTop: 8 * 2 },
};

const AddLicense = ({ match, history }) => {
  const { token } = useAccountsAuth();

  function renderContent() {
    return (
      <>
        <Grid container direction="column">
          <Grid container {...formGridProps}>
            <Grid item xs={3}>
              <FormLabel htmlFor="UserNames">Licenses</FormLabel>
            </Grid>
            <Grid item xs>
              <Form.Fields.DataSelect
                name="Licenses"
                dataType="accountsEnvironmentLicenses"
                id="accountsEnvironmentLicenses"
                label={null}
                multi
                dataProps={{
                  accountToken: token,
                }}
                itemsPerPage={999999}
              />
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }

  return (
    <Form.Fragment
      submitLabel="Save"
      submittingLabel="Saving..."
      dataItemProps={{
        dataType: "environmentLicenses",
        dataProps: {
          accountToken: token,
        },
      }}
      onValidate={(data) => {
        const errors = {};
        if (!data.Licenses || data.Licenses.length === 0) {
          errors.Licenses = "Licenses are required.";
        }

        return errors;
      }}
      renderContent={(values) => renderContent()}
      onSuccess={() => {
        history.return("/licensing");
      }}
      onCancel={() => {}}
    />
  );
};

export default withRouter(AddLicense);
