//@ts-check

/**
 * @typedef { object } UserGroup
 * @property { number } Id Groups's id
 * @property { string } GroupName Groups's name
  * @property { string } GroupName Groups's name

/**@type {import('data/types').DataType<GroupWorkspacesModel>} */
const APP_DATA_TYPE = {
  data: {
    url: '/backend/groupWorkspacesGrid?groupName={groupName}&include={include}',
    itemUrl: '/backend/groupWorkspacesGrid',
    createItemUrl: '/backend/groupWorkspacesGrid?groupName={groupName}',
    deleteItemUrl:
      '/backend/groupWorkspacesGrid?groupName={groupName}&workspaceName={id}',
    dataKey: 'groupWorkspaces',
    idKeyProp: 'WorkspaceName',
    onBeforeRequest: (config, request) => {
      if (!config.dataProps) return;
      if (
        request.type !== 'createItem' &&
        request.type !== 'loadView' &&
        request.type !== 'deleteItem'
      )
        return;
      let {
        dataProps: { groupName, include },
      } = config;

      let url = request.url;
      url = url
        .split('{groupName}')
        .join(encodeURIComponent(groupName))
        .split('{include}')
        .join(include);
      return {
        url,
      };
    },
  },

  placeholders: {
    label: data => data.WorkspaceName,
    type: () => 'access',
    icon: () => 'group',
    delete: () => 'remove',
    deleting: () => 'removing',
  },
};

export default APP_DATA_TYPE;
