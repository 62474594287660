//@ts-check
import React from "react";
import { Icon, Formatter } from "ecrion-components";
import Avatar from "@material-ui/core/Avatar";
import { withStyles } from "@material-ui/core/styles";

const SIZE = 96;

const styles = (theme) => ({
  letterAvatar: {
    height: SIZE,
    width: SIZE,
    fontSize: Math.floor(SIZE / 3),
    margin: "auto",
  },
  appAvatar: {
    width: SIZE,
    height: SIZE,
    margin: "auto",
    borderRadius: "5% !important",
  },
});

const PageAvatar = ({ alt, src, type, children, classes }) => {
  if (src) return <Avatar className={classes.appAvatar} alt={alt} src={src} />;

  if (type === "icon") {
    return (
      <div
        style={{
          width: SIZE,
          height: SIZE,
          border: "1px solid #000000",
          borderRadius: "50%",
          margin: "auto",
        }}
      >
        <Icon
          type={children}
          style={{
            width: SIZE,
            height: SIZE,
            fontSize: Math.floor(SIZE / 3),
            lineHeight: `${SIZE}px`,
            display: "inline-block",
            textAlign: "center",
          }}
        />
      </div>
    );
  }

  if (type === "text")
    return <Formatter.LetterAvatar text={children} classes={classes} />;

  return null;
};

PageAvatar.defaultProps = {
  alt: "Page avatar",
  src: "",
  type: "text", //icon
};

export default withStyles(styles, { withTheme: true })(PageAvatar);
