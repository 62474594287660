//@ts-check
import React from "react";
import { withRouter } from "react-router-dom";
import { Grid, FormLabel, Chip } from "@material-ui/core";
import PageLayout from "../../../../components/PageLayout";
import PageAvatar from "../../../../components/PageAvatar";
import SubTitle from "../../../../components/SubTitle";
import { Form, withUserInfo, Icon } from "ecrion-components";
import { withStyles, createStyles } from "@material-ui/core/styles";

const formGridProps = {
  alignItems: "center",
};

const styles = (theme) =>
  createStyles({
    avatar: {
      "&:hover": {
        backgroundColor: "blue !important",
      },
    },
    avatarContainer: {
      margin: "auto",
      "& div": {
        "&:focus": {
          outline: "0px",
        },
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
    notVisible: {
      position: 'absolute',
      top: '-9999px', 
      left: '-9999px',
    }
  });

const CreateUserPage = ({ history, email, back, classes }) => {
  function getPageAvatar(file) {
    if (!file) {
      return (
        <SubTitle includedInFormFragment>
          <PageAvatar type="icon">user</PageAvatar>
          <Chip
            style={{ marginTop: 8 }}
            icon={
              <Icon
                className="fa fa-user-circle"
                style={{ width: "22px", height: "22px", fontSize: "22px" }}
              />
            }
            label={email}
            onDelete={back}
          />
        </SubTitle>
      );
    }

    return (
      <SubTitle includedInFormFragment>
        <PageAvatar src={file.preview}>user</PageAvatar>
        <Chip
          style={{ marginTop: 8 }}
          icon={
            <Icon
              className="fa fa-user-circle"
              style={{ width: "22px", height: "22px", fontSize: "22px" }}
            />
          }
          label={email}
          onDelete={back}
        />
      </SubTitle>
    );
  }

  return (
    <PageLayout
      variant="small"
      title="Create New User"
      navBarType="closable"
      returnTo="/users"
    >
      <Form.Fragment
        autocomplete="off"
        dataItemProps={{
          dataType: "user",
        }}
        defaultValue={{
          Email: email,
          Enabled: true,
          AutoGenerate: true,
          SendEmailInvitation: true,
          Groups: ["Everyone"],
          Invite: false,
        }}
        onValidate={(data) => {
          const errors = {};
          if(data.Groups == null || !data.Groups.includes("Everyone"))
            errors.Groups = "Everyone group cannot be removed";

          if (data.AutoGenerate === false && data.Password && data.Password.length < 6)
            errors.UserPassword = "Password must have at least 6 characters";

          if (data.AutoGenerate === false && data.ConfirmPassword !== data.Password)
            errors.ConfirmPassword = "Confirm password doesn't match";

          return errors;
        }}
        renderContent={() => (
          <React.Fragment>  
            <Grid container direction="column">
              <Grid item className={classes.avatarContainer}>
                <div style={{ textAlign: "center" }}>
                  <Form.Fields.LocalFile
                    name="ProfileImage"
                    preview
                    label={null}
                    accept="image/*"
                    className={classes.avatar}
                  >
                    {(file) => getPageAvatar(file)}
                  </Form.Fields.LocalFile>
                </div>
              </Grid>
              <Grid item>
                <Grid container {...formGridProps}>
                  <Grid item sm={4} xs={12}>
                    <FormLabel htmlFor="FirstName">First Name</FormLabel>
                  </Grid>
                  <Grid item sm xs>
                    <Form.Fields.Name
                      name="FirstName"
                      id="firstName"
                      label={null}
                      type="firstName"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container {...formGridProps}>
                  <Grid item sm={4} xs={12}>
                    <FormLabel htmlFor="LastName">Last Name</FormLabel>
                  </Grid>
                  <Grid item sm xs>
                    <Form.Fields.Name
                      name="LastName"
                      id="lastName"
                      label={null}
                      type="lastName"
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item>              
                <Grid container {...formGridProps}>
                  <Grid item sm={4} xs={12}>
                    <FormLabel htmlFor="Groups">Groups</FormLabel>
                  </Grid>
                  <Grid item xs>
                    <Form.Fields.DataSelect
                      name="Groups"
                      multi
                      label={null}
                      dataType="group"
                      id="groups"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item style={{ height: "42px" }}>
                <Form.Fields.Checkbox
                  name="AutoGenerate"
                  label="Auto Generate Password"
                />
              </Grid>
              <Form.Condition when="AutoGenerate" is={false}>
                {/* the below fields are added here to take the autocomplete option out of the rest of fields. Please do not remove!!! */}
              <input autoComplete="on" className={classes.notVisible} type="text" name="username" />
              <input autoComplete="on" className={classes.notVisible}  type="password" name="password" />
                <Grid item>
                  <Grid container {...formGridProps}>
                    <Grid item sm={4} xs={12}>
                      <FormLabel htmlFor="Password" required>
                        Password
                      </FormLabel>
                    </Grid>
                    <Grid item sm xs>
                      <Form.Fields.Password
                        name="Password"
                        id="password"
                        label={null}
                        required
                        autoComplete="off"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container {...formGridProps}>
                    <Grid item sm={4} xs={12}>
                      <FormLabel htmlFor="ConfirmPassword" required>
                        Confirm Password
                      </FormLabel>
                    </Grid>
                    <Grid item sm xs>
                      <Form.Fields.Password
                        name="ConfirmPassword"
                        id="confirmPassword"
                        label={null}
                        required
                        autoComplete="off"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Form.Condition>
              <Grid item>
                <Form.Fields.Checkbox name="Enabled" label="Enabled" />
              </Grid>
              <Grid item>
                <Form.Fields.Checkbox
                  name="SendEmailInvitation"
                  label="Send an email invitation"
                  disabled
                />
              </Grid>
            </Grid>
          </React.Fragment>
        )}
        onSuccess={() => {
          history.push("/users");
        }}
        submitLabel="Create"
        onCancel={() => { }}
      />
    </PageLayout>
  );
};

export default withStyles(styles)(withUserInfo(withRouter(CreateUserPage)));
