//@ts-check
import React from 'react';
import { withRouter } from 'react-router-dom';
import { Grid, FormLabel } from '@material-ui/core';
import PageLayout from '../../components/PageLayout';
import PageAvatar from '../../components/PageAvatar';
import { Form, withUserInfo } from 'ecrion-components';
import SubTitle from '../../components/SubTitle';

const formGridProps = {
  alignItems: 'center',
};

const fieldStyle = {
  paddingTop: "8px",
};

const NewGroup = ({ history, userInfo }) => {
  return (
    <PageLayout
      variant="small"
      title="New Group"
      navBarType="closable"
      returnTo="/groups"
    >
      {userInfo.environmentName && (
        <Form.Fragment
          dataItemProps={{
            dataType: 'group',
          }}
          onValidate={data => {
            const errors = {};
            if (!data.Name)
              errors.Name = 'Name is required';

            return errors;
          }}
          defaultValue={{
            Enabled: true,
            AutoGenerate: true,
          }}
          renderContent={() => (
            <React.Fragment>
              <SubTitle includedInFormFragment>
                <PageAvatar src="" type="icon">
                  group
                </PageAvatar>
              </SubTitle>
              <Grid container direction="column">
                <Grid item>
                  <Grid container {...formGridProps}>
                    <Grid item sm={4} xs={12} style={fieldStyle}>
                      <FormLabel htmlFor="Name" required>
                        Name
                      </FormLabel>
                    </Grid>
                    <Grid item sm xs>
                      <Form.Fields.Name
                        name="Name"
                        id="name"
                        label={null}
                        required
                        type="groupName"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container {...formGridProps}>
                    {/* <Grid item sm={4} xs={12} /> */}
                    <Grid item sm xs>
                      <Form.Fields.Checkbox
                        name="HasEnvironmentRights"
                        label="Can manage organization"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container {...formGridProps}>
                    <Grid item sm={4} xs={12} style={fieldStyle}>
                      <FormLabel htmlFor="Groups">Workspaces</FormLabel>
                    </Grid>
                    <Grid item xs>
                      <Form.Fields.DataSelect
                        name="workspacesIds"
                        multi
                        label={null}
                        dataType="workspace"
                        id="workspace"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </React.Fragment>
          )}
          onSuccess={() => {
            history.push('/groups' + history.location.search);
          }}
          onCancel={() => { }}
        />
      )}
    </PageLayout>
  );
};

export default withUserInfo(withRouter(NewGroup));
