//@ts-check
import React from "react";
import Typography from "@material-ui/core/Typography";
import PageLayout from "../components/PageLayout";

const NotFound = () => {
  return (
    <PageLayout variant="small" title="Page not found">
      <Typography variant="h3" color="inherit" style={{ textAlign: "center" }}>
        Page not found
      </Typography>
    </PageLayout>
  );
};

export default NotFound;
