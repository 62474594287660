export const handlePermissions = (userInfo, history, permissions) => {
  if (!userInfo) return;

  if (!userInfo.permissions || !userInfo.userName) return;

  if (
    permissions &&
    permissions.length > 0 &&
    !permissions.some(p => userInfo.permissions.indexOf(p) > -1)
  )
    history.replace('/');
};
