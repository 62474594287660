//@ts-check
import React from "react";
import { withRouter } from "react-router-dom";
import { Grid, FormLabel } from "@material-ui/core";
import PageLayout from "../../../../components/PageLayout";
import PageAvatar from "../../../../components/PageAvatar";
import SubTitle from "../../../../components/SubTitle";
import { Form, Utils, withUserInfo, Modals } from "ecrion-components";

const ChooseEmailPage = ({ history, userInfo, setEmail, setCreateNewUser }) => {
  return (
    <PageLayout
      variant="small"
      title="New User"
      navBarType="closable"
      returnTo="/users"
    >
      <SubTitle>
        <PageAvatar type="icon">user</PageAvatar>
      </SubTitle>
      <Form.Fragment
        onValidate={(data) => {
          const errors = {};
          if (!data.Email)
            errors.Email = "Email is required";

          return errors;
        }}
        renderContent={() => (
          <React.Fragment>
            <Grid container direction="column">
              <Grid item>
                <Grid container alignItems="center">
                  <Grid item sm={4} xs={12}>
                    <FormLabel htmlFor="Email" required>
                      Email
                    </FormLabel>
                  </Grid>
                  <Grid item sm xs>
                    <Form.Fields.Email
                      name="Email"
                      id="email"
                      label={null}
                      required
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </React.Fragment>
        )}
        submitLabel="Continue"
        onSuccess={(data) => {
          return Utils.request({
            url: `/backend/environments?email=${encodeURIComponent(
              data.Email
            )}&throwErrorIfNotExists=false`,
            method: "GET",
          }).then((environments) => {
            if (!environments) return;

            if (environments.length === 0) {
              setCreateNewUser(true);
              setEmail(data.Email);
              return;
            }

            if (
              environments.findIndex(
                (e) =>
                  e.toLowerCase() === userInfo.environmentName.toLowerCase()
              ) > -1
            ) {
              Modals.open({
                type: "ALERT",
                props: {
                  title: "Error",
                  message: `A user with the email ${data.Email} already exists in your organization. Please insert another one.`,
                },
              });
              return;
            } else {
              Modals.open({
                type: "YES_NO",
                props: {
                  title: "A user with same email exist in other organization",
                  message: `Would you like to grant access or add a new user with the same email?`,
                  yes: {
                    label: "Grant Access",
                    onClick: () => {
                      setCreateNewUser(false);
                      setEmail(data.Email);
                    },
                  },
                  no: {
                    label: "Add New User",
                    onClick: () => {
                      setCreateNewUser(true);
                      setEmail(data.Email);
                    },
                  },
                },
              });
            }
          });
        }}
        onCancel={() => { }}
      />
    </PageLayout>
  );
};

export default withUserInfo(withRouter(ChooseEmailPage));
