//@ts-check
import React from "react";
import { withRouter } from "react-router-dom";
import { Grid, FormLabel } from "@material-ui/core";
import PageLayout from "../components/PageLayout";
import PageAvatar from "../components/PageAvatar";
import SubTitle from "../components/SubTitle";
import { Form, DataItem, DataList } from "ecrion-components";

const formGridProps = {
  alignItems: "center",
  style: { marginTop: 8 * 2 }
};

const AppInstall = ({ history, match }) => {
  let appId = match && match.params && match.params.id ? match.params.id : null;

  function renderHeader(app) {
    if (!app) return <div />;

    return (
      <SubTitle includedInFormFragment>
        <PageAvatar src={app.MicroserviceAppManifest.IconURL} type="icon">
          {app.MicroserviceAppManifest.Name}
        </PageAvatar>
      </SubTitle>
    );
  }

  function renderContent(app) {
    return (
      <React.Fragment>
        {renderHeader(app)}
        <Grid container direction="column">
          <Grid item>
            <Grid container {...formGridProps}>
              <Grid item xs={4}>
                <FormLabel
                  htmlFor="MicroserviceAppInstallation.Category"
                  required
                >
                  Category
                </FormLabel>
              </Grid>
              <Grid item xs>
                <Form.Fields.DataSelect
                  name="MicroserviceAppInstallation.Category"
                  dataType="availableModuleCategory"
                  id="categories"
                  label={null}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container {...formGridProps}>
              <Grid item xs={4}>
                <FormLabel htmlFor="MicroserviceAppInstallation.PrincipalIds">
                  Access Rights
                </FormLabel>
              </Grid>
              <Grid item xs>
                <Form.Fields.DataSelect
                  name="MicroserviceAppInstallation.PrincipalIds"
                  multi
                  label={null}
                  dataType="principal"
                  id="principals"
                  required
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }

  return (
    <DataItem dataType="availableMicroserviceApp" id={appId}>
      {({ value: app }) => (
        <DataItem dataType="group" id="Everyone">
          {({ value: group }) =>
            app ? (
              <PageLayout
                variant="small"
                title={app.MicroserviceAppManifest.Name}
                navBarType="closable"
              >
                <DataList
                  dataType="availableModuleCategory"
                  itemsPerPage={99999}
                  id="categories"
                >
                  {({ state }) =>
                    !state.view.isLoading ? (
                      <Form.Fragment
                        onValidate={data => {
                          const errors = {};
                          if (!data.MicroserviceAppInstallation.Category) {
                            errors.MicroserviceAppInstallation.Category =
                              "Category is required";
                          }

                          return errors;
                        }}
                        submitLabel="Install"
                        submittingLabel="Installing..."
                        defaultValue={{
                          MicroserviceAppInstallation: {
                            Id: appId,
                            Category:
                              app.MicroserviceAppManifest
                                .DefaultInstallCategory &&
                              state.data.findIndex(
                                c =>
                                  c.Name ===
                                  app.MicroserviceAppManifest
                                    .DefaultInstallCategory
                              ) > -1
                                ? app.MicroserviceAppManifest
                                    .DefaultInstallCategory
                                : state.data.length > 0 && state.data[0].Name,
                            PrincipalIds:
                              app.MicroserviceAppManifest.DefaultPrincipals
                                .length > 0
                                ? app.MicroserviceAppManifest.DefaultPrincipals.map(
                                    p => p.Id
                                  )
                                : group
                                ? [group.PrincipalId]
                                : []
                          }
                        }}
                        dataItemProps={{
                          dataType: "microserviceApp"
                        }}
                        renderContent={renderContent.bind(this, app)}
                        onSuccess={(response, payload) => {
                          if (payload && payload.RedirectURL)
                          {
                            let redirectUrl = payload.RedirectURL;
                            if(redirectUrl.indexOf("https://") !==0 &&  redirectUrl.indexOf("http://") !==0)
                                redirectUrl="https://"+ redirectUrl;
                                window.location.href = redirectUrl;
                          }                          
                          else history.push("/");
                        }}
                        onCancel={() => {}}
                      />
                    ) : (
                      <div />
                    )
                  }
                </DataList>
              </PageLayout>
            ) : (
              <div />
            )
          }
        </DataItem>
      )}
    </DataItem>
  );
};

export default withRouter(AppInstall);
