//@ts-check

/**
 * @typedef { object } MicroserviceCategoryModel Data model returned by the sever representing a Microservice Category
 * @property { string } Name Microservice category name
 * */

/**@type {import('data/types').DataType<MicroserviceCategoryModel>} */
const APP_DATA_TYPE = {
  data: {
    url: "/backend/micorservicesCategories",
    dataKey: "microserviceCategories",
    idKeyProp: "Name"
  },
  placeholders: {
    label: data => data.Name,
    type: () => "microservice category",
    icon: () => "category"
  }
};

export default APP_DATA_TYPE;
