import React from 'react';
import { Router as BrowserRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Utils } from 'ecrion-components';

const history = Utils.history.enhance(
  createBrowserHistory({
    basename: process.env.REACT_APP_BASE_PATH,
  })
);
export default function Router({ children }) {
  return <BrowserRouter history={history}>{children}</BrowserRouter>;
}
