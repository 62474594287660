import React from "react";
import { ModalWindow } from "ecrion-components";
import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    content: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  })
);

const Alert = ({ title, message, onClose }) => {
  const classes = useStyles({});

  return (
    <ModalWindow
      onClose={onClose}
      header={title}
      footerButtons={{
        submit: {
          children: "Ok",
          onClick: () => onClose(),
        },
      }}
      content={<div className={classes.content}>{message}</div>}
    />
  );
};

export default Alert;
