//@ts-check
import React from "react";
import {
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemIcon,
  Avatar,
  ListItemSecondaryAction,
  Tooltip,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { Icon, Formatter } from "ecrion-components";
import { Link } from "react-router-dom";
import IconAvatar from "./IconAvatar";
import Zoom from "@material-ui/core/Zoom";

const AdapterLink = React.forwardRef((props, ref) => {
  const { to, ...rest } = props;

  if (to.startsWith("http://") || to.startsWith("https://"))
    // eslint-disable-next-line
    return <a innerRef={ref} href={to} {...rest} />;

  return <Link innerRef={ref} to={to} {...rest} />;
});

const styles = (theme) => ({
  root: {
    height: "auto",
    minHeight: theme.spacing(7),
    padding: theme.spacing(1),
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    cursor: "pointer",
  },
  rootFocusVisible: {
    backgroundColor: "transparent",
  },
  rootButton: {
    "&:hover": {
      backgroundColor: "#F3F5F7",
    },
    "&:active": {
      backgroundColor: "#E0E5EB",
    },
  },
  itemAvatar: {
    height: theme.spacing(4),
    width: theme.spacing(4),
    fontSize: "1em",
    "& > *": {
      height: theme.spacing(4),
      width: theme.spacing(4),
      fontSize: "1em",
    },
  },
  appAvatarRebranding: {
    borderRadius: "5% !important",
  },
  action: {
    right: theme.spacing(2),
  },
  letterAvatar: {
    height: theme.spacing(4),
    width: theme.spacing(4),
    fontSize: "1em",
  },
});

const Item = ({
  classes,
  to,
  label,
  secondaryLabel,
  iconURL,
  iconType,
  letterAvatar,
  onDelete,
  onRemove,
  tooltip,
  labelColor,
}) => {
  const renderListItems = () => {
    return (
      <ListItem
        button
        component={to ? AdapterLink : "Typography"}
        to={to}
        classes={{
          root: classes.root,
          focusVisible: classes.rootFocusVisible,
          button: classes.rootButton,
        }}
      >
        {iconURL && (
          <ListItemAvatar className={classes.itemAvatar}>
            <Avatar
              className={classes.appAvatarRebranding}
              alt={label}
              src={iconURL}
            />
          </ListItemAvatar>
        )}
        {iconType && (
          <ListItemIcon
            style={{
              marginRight: 2,
              marginLeft: 8,
            }}
          >
            <IconAvatar icon={iconType} classes={classes} />
          </ListItemIcon>
        )}
        {letterAvatar && (
          <ListItemAvatar>
            <Formatter.LetterAvatar text={letterAvatar} classes={classes} />
          </ListItemAvatar>
        )}
        <ListItemText
          primary={label}
          secondary={secondaryLabel}
          primaryTypographyProps={{
            color: labelColor,
            variant: "body2",
            noWrap: true,
            style: {
              color: labelColor,
              fontWeight: "bold",
            },
          }}
          secondaryTypographyProps={{
            color: labelColor,
            variant: "caption",
            noWrap: true,
            style: {
              fontStyle: "italic",
              color: labelColor,
            },
          }}
        />
        {onDelete && (
          <ListItemSecondaryAction>
            <Icon
              type="delete"
              onClick={onDelete.click}
              hoverable
              label={onDelete.label || "Delete"}
              tooltipPlacement="right"
              disabled={onDelete.disabled}
            />
          </ListItemSecondaryAction>
        )}
        {onRemove && (
          <ListItemSecondaryAction>
            <Icon
              type="close"
              onClick={onRemove.click}
              hoverable
              label={onRemove.label || "Remove"}
              tooltipPlacement="right"
              disabled={onRemove.disabled}
            />
          </ListItemSecondaryAction>
        )}
      </ListItem>
    );
  };

  if (tooltip)
    return (
      <Tooltip title={tooltip} TransitionComponent={Zoom} placement="left">
        {renderListItems()}
      </Tooltip>
    );

  return renderListItems();
};

Item.defaultProps = {
  secondaryLabel: "",
  iconURL: undefined,
  iconType: undefined,
  labelColor: "inherit",
};

Item.propTypes = {
  /**  */
  to: PropTypes.string.isRequired,

  label: PropTypes.string.isRequired,

  iconURL: PropTypes.string,

  iconType: PropTypes.string,

  letterAvatar: PropTypes.string,

  onDelete: PropTypes.object,
  onRemove: PropTypes.object,

  labelColor: PropTypes.string,

  secondaryLabel: PropTypes.string,
  tooltip: PropTypes.string,
};

export default withStyles(styles, { withTheme: true })(Item);
