//@ts-check
import React, { useEffect, useState } from "react";
import { Utils, Form } from "ecrion-components";
import { withStyles } from "@material-ui/core/styles";
import { Typography, LinearProgress } from "@material-ui/core";
import PageAvatar from "./PageAvatar";
import AppInfo from "./AppInfo";
import Button from "./Button";

/**
 * @typedef {object} Props
 * @property {import('../data/microserviceApp').MicroserviceAppModel} app
 *
 **/

const styles = (theme) => ({
  root: {
    height: "auto",
    minHeight: theme.spacing(10),
    padding: theme.spacing(2),
    cursor: "pointer",
  },
  rootFocusVisible: {
    backgroundColor: "transparent",
  },
  rootButton: {
    "&:hover": {
      backgroundColor: "#F3F5F7",
    },
    "&:active": {
      backgroundColor: "#E0E5EB",
    },
  },
  action: {
    right: theme.spacing(2),
  },
});

const AppAutoInstall = ({ history, appName }) => {
  const [installation, setInstallation] = useState({
    initialRequest: true,
    hasErrorsInitialRequest: false,
    initialRequestError: "",
    app: null,
    installing: false,
    hasInstallingErrors: false,
    instalationError: "",
  });

  useEffect(() => {
    Utils.request({
      url: `/backend/availableMicroservices?name=${appName}`,
    }).then(loadApp, onDataError);
  }, []);

  function loadApp(app) {
    if (app.MicroserviceAppInstallation) history.replace("/");
    else {
      const canInstall = canAutoInstall(app.MicroserviceAppManifest);

      setInstallation((prevState) => {
        return {
          ...prevState,
          initialRequest: false,
          app: app,
          installing: canInstall,
        };
      });

      if (canInstall) {
        Utils.request({
          url: "/backend/microservices2",
          method: "POST",
          data: {
            MicroserviceAppInstallation: {
              Name: appName,
              Groups: ["Everyone"],
              Category: "Apps",
            },
          },
        }).then(onFinishInstall, onFailInstall);
      }
    }
  }

  function onFailInstall(error) {
    setInstallation((prevState) => {
      return {
        ...prevState,
        hasInstallingErrors: true,
        instalationError: error.response.body.Message,
      };
    });
  }

  function onFinishInstall() {
    history.replace("/");
  }

  function canAutoInstall(manifest) {
    const unavailableType = manifest.States.find(
      (s) => s.Type.toLowerCase() === "unavailable"
    );

    if (!unavailableType) return false;

    const getItNow = unavailableType.Actions.find(
      (a) => a.Type.toLowerCase() === "getitnow"
    );

    return !!getItNow;
  }

  function onDataError(error) {
    setInstallation((prevState) => {
      return {
        ...prevState,
        initialRequest: false,
        installing: false,
        hasErrorsInitialRequest: true,
        initialRequestError: error.response.body.Message,
      };
    });
  }

  function renderHeader(app) {
    if (!app) return <div />;

    return (
      <div>
        <PageAvatar src={app.MicroserviceAppManifest.IconURL} type="icon">
          {app.MicroserviceAppManifest.Name}
        </PageAvatar>
        <Typography
          style={{
            marginTop: 8 * -6,
            textAlign: "center",
            marginBottom: 8 * 6,
          }}
          color="inherit"
        >
          {app.MicroserviceAppManifest.Name}
        </Typography>
      </div>
    );
  }

  function renderContent() {
    if (installation.initialRequest)
      return (
        <div>
          <Typography color="inherit">Requesting '{appName}' ...</Typography>
          <LinearProgress />
        </div>
      );

    if (installation.hasErrorsInitialRequest)
      return (
        <div>
          <Form.Error>
            Failed to load '{appName}'. {installation.initialRequestError}
          </Form.Error>
          <Button
            style={{ marginTop: 8 * 2 }}
            onClick={() => history.push("/apps")}
          >
            Manage Apps
          </Button>
        </div>
      );

    if (installation.app) {
      if (installation.hasInstallingErrors)
        return (
          <div>
            <div style={{ marginBottom: 8 * 2 }}>
              <Form.Error>
                Failed to install: {installation.instalationError}
              </Form.Error>
            </div>
            {renderHeader(installation.app)}
            <Button
              onClick={() => history.push("/app/" + appName + "/install")}
            >
              Manual Install
            </Button>
          </div>
        );

      if (installation.installing)
        return (
          <div>
            {renderHeader(installation.app)}
            <Typography color="inherit">Installing '{appName}' ...</Typography>
            <LinearProgress />
          </div>
        );

      return <AppInfo appName={appName} />;
    }
  }

  return <div style={{ textAlign: "center" }}>{renderContent()}</div>;
};

AppAutoInstall.defaultProps = {
  appName: null,
};

export default withStyles(styles, { withTheme: true })(AppAutoInstall);
