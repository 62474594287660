import React, { useState, useEffect } from "react";
import Auth, { parseUrl } from "../utils/accountsAuth";
import { useSettings } from "./SettingsContext";
import { withUserInfo } from "ecrion-components";

const AccountsContext = React.createContext();
let expiresAt = 0;

const getRedirectUrl = () => {
  let urlRoot =
    window.location.protocol +
    "//" +
    window.location.hostname +
    (window.location.port ? ":" + window.location.port : "");
  if (process && process.env.REACT_APP_BASE_PATH)
    urlRoot += process.env.REACT_APP_BASE_PATH;

  return `${urlRoot}/accounts-auth-callback`;
};

function AccountsProviderBase(props) {
  const { userInfo } = props;
  const [token, setToken] = React.useState(null);

  const [auth, setAuth] = useState(null);
  const settings = useSettings();

  useEffect(() => {
    if (settings) {
      setAuth(
        new Auth({
          loginUrl: settings["AccountsURL"] + "/ServiceLogin.aspx",
          redirectUri: getRedirectUrl()
        })
      );
    }
  }, [settings]);

  const setSession = authResult => {
    setToken(authResult.accountsToken);
    // javascript timestamp is in milliseconds
    expiresAt = authResult.expiresAt * 1000;
  };

  const handleAuthentication = () => {
    setSession(parseUrl());
  };

  const signIn = ({ routePath } = {}) => {
    if (!auth) return;

    auth.authorize({
      redirectParams: { routePath, environment: userInfo.environmentName }
    });
  };

  const isAuthenticated = () => {
    return new Date().getTime() < expiresAt;
  };

  return (
    <AccountsContext.Provider
      value={{
        token,
        isAuthenticated,
        handleAuthentication,
        signIn
      }}
      {...props}
    />
  );
}

function useAccountsAuth() {
  const context = React.useContext(AccountsContext);
  if (context === undefined)
    throw new Error("useAccountsAuth must be used within a AuthProvider");

  return context;
}

const AccountsProvider = withUserInfo(AccountsProviderBase);

export { AccountsProvider, useAccountsAuth };
