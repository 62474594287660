import React, { useState, useEffect } from 'react';
import { Utils } from 'ecrion-components';

const SettingsContext = React.createContext();

function SettingsProvider(props) {
  const [settings, setSettings] = useState(null);

  useEffect(() => {
    function loadEnvironmentSettings(response) {
      if (response) setSettings(response[0]);
    }

    function errorEnvironmentSettings(response) {
      setSettings({ Error: response });
    }

    Utils.request({
      url: '/backend/environmentSettings',
    })
      .then(loadEnvironmentSettings)
      .catch(errorEnvironmentSettings);
  }, []);

  return <SettingsContext.Provider value={settings} {...props} />;
}

function useSettings() {
  const context = React.useContext(SettingsContext);
  if (context === undefined)
    throw new Error('useSettings must be used within a SettingsProvider');

  return context;
}

export { SettingsProvider, useSettings };
