//@ts-check

import React from "react";
import { withRouter } from "react-router-dom";
import PageLayout from "../components/PageLayout";
import {
  Icon,
  DataFilter,
  DataAction,
  DataItem,
  withUserInfo,
} from "ecrion-components";
import { Grid, LinearProgress, Typography, List } from "@material-ui/core";
import ProgressiveDataList from "../components/ProgressiveDataList";
import Item from "../components/Item";
import PageAvatar from "../components/PageAvatar";
import SubTitle from "../components/SubTitle";
import { getDisplayName } from "../utils/getDisplayName";

const buttonProps = {
  hoverable: true,
};

const UserGroups = ({ history, location, match, userInfo }) => {
  let userName =
    match && match.params && match.params.id ? match.params.id : null;

  function removeUserGroup(id) {
    DataAction.item({
      dataType: "userGroups",
      id: id,
      dataProps: {
        userName: userName,
      },
    }).delete({
      useConfirmation: true,
      message:
        "Are you sure you want to remove user " +
        userName +
        " from group " +
        id +
        "?",
    });
  }

  return (
    <PageLayout
      variant="small"
      title="User Groups"
      navBarType="closable"
      returnTo={`/user/${userName}`}
    >
      <SubTitle>
        <DataItem dataType="user" id={userName}>
          {({ value: user }) =>
            user ? (
              user.ProfileImage ? (
                <PageAvatar src={user.ProfileImage}>user</PageAvatar>
              ) : (
                <PageAvatar type="text">
                  {getDisplayName(user.FirstName, user.LastName, user.UserName)}
                </PageAvatar>
              )
            ) : (
              <div />
            )
          }
        </DataItem>
        <Typography
          style={{
            marginTop: 8,
            textAlign: "center",
          }}
          color="inherit"
        >
          {userName}
        </Typography>
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item>
            <DataFilter
              history={history}
              location={location}
              searchIn="query"
            />
          </Grid>
          <Grid item>
            <div
              style={{
                border: "1px solid",
                height: "100%",
                marginLeft: 8,
                minHeight: 8 * 4,
              }}
            />
          </Grid>
          <Grid item>
            <Icon
              type="add"
              onClick={() =>
                history.push(
                  "/user/" + userName + "/groups/add" + history.location.search
                )
              }
              {...buttonProps}
            />
          </Grid>
        </Grid>
      </SubTitle>
      <Grid container direction="column" justify="center">
        <Grid item>
          <ProgressiveDataList
            key={location.search}
            dataType="userGroups"
            pageStart={0}
            dataProps={{
              filter: DataFilter.createFromLocation(location),
              userName: userName,
            }}
          >
            {({ state }) => {
              return (
                <div>
                  {state.data.length === 0 ? (
                    state.view.isLoading ? (
                      <LinearProgress />
                    ) : (
                      <div style={{ textAlign: "center" }}>
                        <Typography color="inherit">No groups.</Typography>
                      </div>
                    )
                  ) : (
                    <List>
                      {state.data.map(
                        (group) =>
                          typeof group !== "undefined" && (
                            <Item
                              key={group.Name}
                              label={group.Name}
                              iconType="group"
                              onRemove={{
                                click: () => removeUserGroup(group.Name),
                                disabled:
                                  group.Name === "Everyone" ||
                                  (group.Name === "Administrators" &&
                                    userInfo.userName === userName),
                              }}
                            />
                          )
                      )}
                    </List>
                  )}
                  {state.view.isLoading && <LinearProgress />}
                </div>
              );
            }}
          </ProgressiveDataList>
        </Grid>
      </Grid>
    </PageLayout>
  );
};

export default withUserInfo(withRouter(UserGroups));
