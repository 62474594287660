//@ts-check

/**@type {import('data/types').DataType<MicroserviceAppModel>} */
const APP_DATA_TYPE = {
  data: {
    url: "/backend/availableMicroservices",
    dataKey: "microservices",
    idKeyProp: "MicroserviceAppManifest.Id",
    itemUrl: "/backend/availableMicroservices?id={id}"
  }
};

export default APP_DATA_TYPE;
