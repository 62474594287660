import React from "react";
import { ModalWindow } from "ecrion-components";
import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    content: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  })
);

const YesNo = ({ title, message, yes, no, onClose }) => {
  const classes = useStyles({});

  return (
    <ModalWindow
      onClose={onClose}
      header={title}
      footerButtons={{
        submit: {
          children: yes.label,
          onClick: () => {
            if (yes.onClick) yes.onClick();

            onClose();
          },
        },
        cancel: {
          children: no.label,
          onClick: () => {
            if (no.onClick) no.onClick();

            onClose();
          },
        },
      }}
      content={<div className={classes.content}>{message}</div>}
    />
  );
};

YesNo.defaultProps = {
  title: "Title",
  messages: "Question?",
  yes: {
    label: "yes",
    onClick: null,
  },
  no: {
    label: "no",
    onClick: null,
  },
};

export default YesNo;
