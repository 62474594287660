//@ts-check
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Grid, FormLabel, Tooltip } from "@material-ui/core";
import PageLayout from "../components/PageLayout";
import PageAvatar from "../components/PageAvatar";
import SubTitle from "../components/SubTitle";
import Button from "../components/Button";
import Loading from "../components/Loading";
import { Form, withUserInfo, Utils, Modals, Icon, withAuth } from "ecrion-components";
import { withStyles, createStyles } from "@material-ui/core/styles";
import UserConfirm from "./UserConfirm";

const formGridProps = {
  alignItems: "center",
};

const deleteAvatarButtonProps = {
  width: "3px",
  height: "3px",
  float: "right",
  padding: "1px",
  fontSize: "14px",
  marginBottom: "12px"
};

const styles = (theme) =>
  createStyles({
    avatar: {
      "&:hover": {
        backgroundColor: "blue !important",
      },
    },
    avatarContainer: {
      margin: "auto",
      "& div": {
        "&:focus": {
          outline: "0px",
        },
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
  });

const Account = ({ history, userInfo, classes, logoutUser }) => {
  const [loading, setLoading] = useState(true);
  const [ssoEnabled, setSSOEnabled] = useState(false);
  const [editProfileURL, setEditProfileURL] = useState(null);
  const [hasAvatar, setHasAvatar] = useState(false);
  const [refreshAvatar, setRefreshAvatar] = useState(0)

  function getPageAvatar(file) {
    if (!file) {
      setHasAvatar(false);
      return (
        <SubTitle includedInFormFragment>
          <PageAvatar type="text">{getDisplayName()}</PageAvatar>
        </SubTitle>
      );
    }

    setHasAvatar(true);
    return (
      <SubTitle includedInFormFragment>
        <PageAvatar src={file.preview}>{getDisplayName()}</PageAvatar>
      </SubTitle>
    );
  }

  function deletePageAvatar() {
    Modals.open({
      type: Modals.TYPES.CONFIRMATION,
      props: {
        title: `Remove profile avatar`,
        message: `Are you sure you want to remove your profile avatar?`,
        onConfirm: () => {
          return Utils.request({
            url: `/backend/userlogo?userName=${encodeURIComponent(userInfo.userName)}`,
            method: 'DELETE',
          }).then(() => { setRefreshAvatar(refreshAvatar + 1) });
        },
      },
    });

  }

  function getDisplayName() {
    if (userInfo.firstName && userInfo.lastName)
      return userInfo.firstName + " " + userInfo.lastName;
    if (userInfo.firstName) return userInfo.firstName;
    if (userInfo.lastName) return userInfo.lastName;

    return userInfo.userName || "";
  }

  useEffect(() => {
    Utils.request({
      url: `/backend/ssourl`,
    }).then((sso) => {
      if (sso && sso.Type && sso.Type.toLowerCase() !== "enterprise") {
        setSSOEnabled(true);

        if (sso.EditProfileUrl) setEditProfileURL(sso.EditProfileUrl);
      }

      setLoading(false);
    });
  }, []);

  return (
    <PageLayout
      variant="small"
      title="My Account"
      navBarType="closable"
      permissions={[]}
    >
      {loading ? (
        <Loading />
      ) : (
        <Form.Fragment
          key={refreshAvatar}
          dataItemProps={{
            dataType: "user",
            id: userInfo.userName,
          }}
          confirmSubmit={(data) => { return UserConfirm.onSave(userInfo.userName !== data.Email, true) }}
          submitDisabled={ssoEnabled}
          onValidate={(data) => {
            const errors = {};
            if (!data.Email)
              errors.Email = "Email is required";
            return errors;
          }}
          renderContent={() => (
            <React.Fragment>
              <Grid container direction="column">
                <Grid item className={classes.avatarContainer}>
                  {hasAvatar &&
                    <Icon
                      type="remove"
                      label="Remove avatar"
                      tooltipPlacement="right"
                      style={deleteAvatarButtonProps}
                      onClick={() => deletePageAvatar()}
                    />}
                  <Tooltip placement="right" title="Change avatar">
                    <Grid item>
                      <Form.Fields.LocalFile
                        name="ProfileImage"
                        preview
                        label={null}
                        accept="image/*"
                        className={classes.avatar}
                        maxSize={102400}
                      >
                        {(file) => getPageAvatar(file)}
                      </Form.Fields.LocalFile>
                    </Grid>
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Grid container {...formGridProps}>
                    <Grid item sm={4} xs={12}>
                      <FormLabel htmlFor="FirstName" disabled={ssoEnabled} >
                        First Name
                      </FormLabel>
                    </Grid>
                    <Grid item sm xs>
                      <Form.Fields.Name
                        name="FirstName"
                        id="firstName"
                        label={null}
                        disabled={ssoEnabled}
                        type="firstName"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container {...formGridProps}>
                    <Grid item sm={4} xs={12}>
                      <FormLabel htmlFor="LastName" disabled={ssoEnabled} >
                        Last Name
                      </FormLabel>
                    </Grid>
                    <Grid item sm xs>
                      <Form.Fields.Name
                        name="LastName"
                        id="lastName"
                        label={null}
                        disabled={ssoEnabled}
                        type="lastName"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container {...formGridProps}>
                    <Grid item sm={4} xs={12}>
                      <FormLabel disabled={ssoEnabled} htmlFor="Email" required>
                        Email
                      </FormLabel>
                    </Grid>
                    <Grid item sm xs>
                      <Form.Fields.Email
                        name="Email"
                        id="email"
                        label={null}
                        required
                        disabled={ssoEnabled}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </React.Fragment>
          )
          }
          onSuccess={(data) => {
            //if the current user has changed their username
            if (userInfo.userName !== data.Email) {
              logoutUser();
            } else {
              history.push("/" + history.location.search);
            }
          }}
          renderFooterLeft={() =>
            ssoEnabled ? (
              editProfileURL && (
                <Button
                  variant="outlined"
                  onClick={() => window.open(editProfileURL, "_blank")}
                  style={{ marginLeft: 8 }}
                >
                  Edit Profile
                </Button>
              )
            ) : (
              <Button
                variant="outlined"
                onClick={() =>
                  Modals.open({
                    type: "CHANGE_PASSWORD",
                  })
                }
                style={{ marginLeft: 8 }}
              >
                Change Password
              </Button>
            )
          }
          onCancel={() => { }}
        />
      )}
    </PageLayout >
  );
};

export default withStyles(styles)(withUserInfo(withAuth(withRouter(Account))));
