//@ts-check
import React from "react";
import { EcrionProvider, Utils } from "ecrion-components";
import Router, { Routes } from "./router";
import theme from "./theme";
import dataTypes from "./data";
import { SettingsProvider } from "./context/SettingsContext";
import { AccountsProvider } from "./context/AccountsContext";
import { createBrowserHistory } from "history";
import modals from "./components/Dialog";

const history = Utils.history.enhance(createBrowserHistory());

const App = () => (
  <Router>
    <EcrionProvider
      history={history}
      theme={theme}
      dataTypes={dataTypes}
      modals={modals}
    >
      <SettingsProvider>
        <AccountsProvider>
          <Routes />
        </AccountsProvider>
      </SettingsProvider>
    </EcrionProvider>
  </Router>
);

export default App;
