// @ts-nocheck

import React from 'react';
import { withRouter } from 'react-router-dom';
import { Icon, DataFilter, DataAction } from 'ecrion-components';
import { LinearProgress, Typography, List, Grid } from '@material-ui/core';
import Item from '../components/Item';
import PageLayout from '../components/PageLayout';
import ProgressiveDataList from '../components/ProgressiveDataList';
import SubTitle from '../components/SubTitle';

const buttonProps = {
  hoverable: true,
};

const Organization = ({ history, location }) => {
  return (
    <PageLayout
      variant="small"
      title="Workspaces"
      navBarType="closable"
      returnTo="/organization"
    >
      <SubTitle>
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item>
            <DataFilter
              history={history}
              location={location}
              searchIn="query"
            />
          </Grid>
          <Grid item>
            <div
              style={{
                border: '1px solid',
                height: '100%',
                marginLeft: 8,
                minHeight: 8 * 4,
              }}
            />
          </Grid>
          <Grid item>
            <Icon
              type="add"
              label="Add"
              tooltipPlacement="right"
              onClick={() =>
                history.push('/workspace' + history.location.search)
              }
              {...buttonProps}
            />
          </Grid>
        </Grid>
      </SubTitle>
      <Grid container justify="center">
        <Grid item xs>
          <ProgressiveDataList
            key={location.search}
            dataType="workspace"
            pageStart={0}
            dataProps={{
              filter: DataFilter.createFromLocation(location),
            }}
          >
            {({ state }) => {
              return (
                <div>
                  {state.data.length === 0 ? (
                    state.view.page === 0 ? (
                      state.view.isLoading ? (
                        <LinearProgress />
                      ) : (
                        <div style={{ textAlign: 'center' }}>
                          {state.view.page === 0 && (
                            <Typography color="inherit">
                              No workspaces.
                            </Typography>
                          )}
                        </div>
                      )
                    ) : (
                      <div />
                    )
                  ) : (
                    <List>
                      {state.data.map(
                        workspace =>
                          typeof workspace !== 'undefined' && (
                            <Item
                              key={workspace.Name}
                              label={workspace.Name}
                              iconType="workspace"
                              to={
                                '/workspace/' +
                                workspace.Name +
                                history.location.search
                              }
                              onDelete={{
                                click: () =>
                                  DataAction.item({
                                    dataType: 'workspace',
                                    id: workspace.Name,
                                  })
                                    .delete({
                                      useConfirmation: true,
                                    })
                                    .then(() => history.push('/workspaces')),
                              }}
                            />
                          )
                      )}
                    </List>
                  )}
                  {state.view.isLoading && <LinearProgress />}
                </div>
              );
            }}
          </ProgressiveDataList>
        </Grid>
      </Grid>
    </PageLayout>
  );
};

export default withRouter(Organization);
