//@ts-check
import React from 'react';
import { Grid, Typography, List, LinearProgress } from '@material-ui/core';
import { Icon, DataFilter, DataAction, withUserInfo } from 'ecrion-components';
import Item from '../components/Item';
import PageLayout from '../components/PageLayout';
import ProgressiveDataList from '../components/ProgressiveDataList';
import { getDisplayName } from '../utils/getDisplayName';
import SubTitle from '../components/SubTitle';

const buttonProps = {
  hoverable: true,
};

const Users = ({ history, location, userInfo }) => {
  function deleteUser(userName) {
    DataAction.item({
      dataType: 'user',
      id: userName,
    })
      .delete({
        useConfirmation: true,
      })
      .then(res => {
        history.push('/users');
      });
  }

  return (
    <PageLayout variant="small" title="Users" navBarType="closable">
      <SubTitle>
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item>
            <DataFilter
              history={history}
              location={location}
              searchIn="query"
            />
          </Grid>
          <Grid item>
            <div
              style={{
                border: '1px solid',
                height: '100%',
                marginLeft: 8,
                minHeight: 8 * 4,
              }}
            />
          </Grid>
          <Grid item>
            <Icon
              type="add"
              label="Add"
              tooltipPlacement="bottom"
              onClick={() => history.push('/user')}
              {...buttonProps}
            />
          </Grid>
          <Grid item>
            <Icon
              type="group"
              label="Groups"
              tooltipPlacement="bottom"
              onClick={() => history.push('/groups' + history.location.search)}
              {...buttonProps}
            />
          </Grid>
        </Grid>
      </SubTitle>
      <Grid container justify="center">
        <Grid item xs>
          <ProgressiveDataList
            key={location.search}
            dataType="user"
            pageStart={0}
            dataProps={{
              filter: DataFilter.createFromLocation(location),
            }}
          >
            {({ state }) => {
              return (
                <div>
                  {state.data.length === 0 ? (
                    state.view.page === 0 ? (
                      state.view.isLoading ? (
                        <LinearProgress />
                      ) : (
                        <div style={{ textAlign: 'center' }}>
                          {state.view.page === 0 && (
                            <Typography color="inherit">No users.</Typography>
                          )}
                        </div>
                      )
                    ) : (
                      <div />
                    )
                  ) : (
                    <List>
                      {state.data.map(
                        user =>
                          typeof user !== 'undefined' && (
                            <Item
                              key={user.UserName}
                              label={
                                user.DisplayName
                                  ? user.DisplayName
                                  : user.UserName
                              }
                              to={'/user/' + user.UserName}
                              iconURL={user.ProfileImage}
                              letterAvatar={
                                !user.ProfileImage &&
                                getDisplayName(
                                  user.FirstName,
                                  user.LastName,
                                  user.UserName
                                )
                              }
                              onDelete={{
                                label:
                                  userInfo.userName !== user.UserName
                                    ? 'Delete'
                                    : 'Current user cannot be deleted',
                                click: () => deleteUser(user.UserName),
                                disabled: userInfo.userName === user.UserName,
                              }}
                            />
                          )
                      )}
                    </List>
                  )}
                </div>
              );
            }}
          </ProgressiveDataList>
        </Grid>
      </Grid>
    </PageLayout>
  );
};

export default withUserInfo(Users);
