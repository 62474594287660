//@ts-check
import React from "react";
import { withRouter } from "react-router-dom";
import { Grid, FormLabel } from "@material-ui/core";
import PageLayout from "../../../components/PageLayout";
import PageAvatar from "../../../components/PageAvatar";
import SubTitle from "../../../components/SubTitle";
import { Form, withUserInfo } from "ecrion-components";
import { withStyles, createStyles } from "@material-ui/core/styles";

const formGridProps = {
  alignItems: "center",
};

const styles = (theme) =>
  createStyles({
    avatar: {
      "&:hover": {
        backgroundColor: "blue !important",
      },
    },
    avatarContainer: {
      margin: "auto",
      "& div": {
        "&:focus": {
          outline: "0px",
        },
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
  });

const NewUser = ({ history, userInfo, classes }) => {
  function getPageAvatar(file) {
    if (!file) {
      return (
        <SubTitle includedInFormFragment>
          <PageAvatar type="icon">user</PageAvatar>
        </SubTitle>
      );
    }

    return (
      <SubTitle includedInFormFragment>
        <PageAvatar src={file.preview}>user</PageAvatar>
      </SubTitle>
    );
  }

  return (
    <PageLayout
      variant="small"
      title="New User"
      navBarType="closable"
      returnTo="/users"
    >
      <Form.Fragment
        dataItemProps={{
          dataType: "user",
        }}
        onValidate={(data) => {
          const errors = {};
          if (!data.Email)
            errors.Email = "Email is required";

          return errors;
        }}
        defaultValue={{
          Enabled: true,
          AutoGenerate: true,
          SendEmailInvitation: true,
          Groups: ["Everyone"],
          SSO: true,
        }}
        renderContent={() => (
          <React.Fragment>
            <Grid container direction="column">
              <Grid item className={classes.avatarContainer}>
                <Form.Fields.LocalFile
                  name="ProfileImage"
                  preview
                  label={null}
                  accept="image/*"
                  className={classes.avatar}
                >
                  {(file) => getPageAvatar(file)}
                </Form.Fields.LocalFile>
              </Grid>
              <Grid item>
                <Grid container {...formGridProps}>
                  <Grid item sm={4} xs={12}>
                    <FormLabel htmlFor="Email" required>
                      Email
                    </FormLabel>
                  </Grid>
                  <Grid item sm xs>
                    <Form.Fields.Email
                      name="Email"
                      id="email"
                      label={null}
                      required
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container {...formGridProps}>
                  <Grid item sm={4} xs={12}>
                    <FormLabel htmlFor="Groups">Groups</FormLabel>
                  </Grid>
                  <Grid item xs>
                    <Form.Fields.DataSelect
                      name="Groups"
                      multi
                      label={null}
                      dataType="group"
                      id="groups"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Form.Fields.Checkbox name="Enabled" label="Enabled" />
              </Grid>
              <Grid item>
                <Form.Fields.Checkbox
                  name="SendEmailInvitation"
                  label="Send an email invitation"
                  disabled
                />
              </Grid>
            </Grid>
          </React.Fragment>
        )}
        onSuccess={() => {
          history.push("/users" + history.location.search);
        }}
        onCancel={() => { }}
      />
    </PageLayout>
  );
};

export default withStyles(styles)(withUserInfo(withRouter(NewUser)));
