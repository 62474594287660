//@ts-check

/**
 * @typedef { object } Group
 * @property { number } Id Groups's name
 * @property { string } Name Groups's name

/**@type {import('data/types').DataType<GroupModel>} */
const APP_DATA_TYPE = {
  data: {
    url: '/backend/groups?idsOperator={idsOperator}',
    itemUrl: '/backend/groups?name={id}',
    dataKey: 'groups',
    idKeyProp: 'Name',
    filterUrlKey: 'Query',
    onBeforeRequest: (config, request) => {
      if (!config.dataProps) return;
      if (request.type !== 'loadView') return;
      let {
        dataProps: { idsOperator },
      } = config;

      if (!idsOperator) idsOperator = 'in';
      let url = request.url;
      url = url.split('{idsOperator}').join(idsOperator);
      return {
        url,
      };
    },
  },
  placeholders: {
    label: data => data.Name,
    type: () => 'group',
    icon: () => 'group',
  },
};

export default APP_DATA_TYPE;
