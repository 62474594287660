//@ts-check

/**@type {import('data/types').DataType<CloudEnvironmentsModel>} */
const ORGANIZATION_DATA_TYPE = {
  extends: 'environment',
  data: {
    dataKey: 'organization',
  },
  placeholders: {
    type: () => 'organization',
    icon: () => 'organization',
  },
};

export default ORGANIZATION_DATA_TYPE;
