//@ts-check
import React, { useState, useEffect, useRef } from "react";
import { Form, Utils, Button } from "ecrion-components";
import { Grid, FormLabel } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";

const AddLicense = ({ match, history, theme }) => {
  const [offlineRequestKey, setOfflineRequestKey] = useState(null);

  const keyPreview = useRef(null);

  useEffect(() => {
    Utils.request({
      url: "/backend/environmentLicensing/getInstallOfflineKey"
    }).then(function(key) {
      setOfflineRequestKey(key);
    });
  }, []);

  function renderContent() {
    return (
      <>
        <Grid container direction="column">
          <Grid item>
            <Grid container direction="column">
              <Grid item style={{ marginBottom: theme.spacing(2) }}>
                <FormLabel htmlFor="EngageKey">
                  Step 1. Log-in into your Ecrion Account and follow the
                  instructions to activate a product for this environment. You
                  will need the following request key:
                </FormLabel>
              </Grid>
              <Grid item xs style={{ textAlign: "right" }}>
                <Form.Input.Text
                  rows="7"
                  rowsMax="7"
                  name="EngageKey"
                  multiline
                  value={offlineRequestKey}
                  inputRef={keyPreview}
                  readOnly
                />
                <Button
                  variant="text"
                  onClick={() =>
                    Utils.copyToClipboard(offlineRequestKey, keyPreview.current)
                  }
                >
                  Copy to clipboard
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction="column">
              <Grid item>
                <FormLabel htmlFor="InstallKey">
                  Step 2. Copy and paste the activation key in the box below:
                </FormLabel>
              </Grid>
              <Grid item xs>
                <Form.Fields.Description
                  rows="7"
                  rowsMax="7"
                  name="InstallKey"
                  multiline
                  label={null}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }

  return (
    <Form.Fragment
      submitLabel="Save"
      submittingLabel="Saving..."
      dataItemProps={{
        dataType: "environmentLicenses",
        dataProps: {
          offline: true
        }
      }}
      renderContent={values => renderContent()}
      onSuccess={() => {
        history.return("/licensing");
      }}
      onCancel={() => {}}
    />
  );
};

export default withStyles({}, { withTheme: true })(withRouter(AddLicense));
