//@ts-check

/**
 * @typedef { object } Group
 * @property { string } Id Timezone id
 * @property { string } DisplayName Timezone display name

/**@type {import('data/types').DataType<TimezoneModel>} */
const APP_DATA_TYPE = {
  data: {
    url: "/backend/timezone2",
    dataKey: "timezones",
    idKeyProp: "Id"
  },
  placeholders: {
    label: data => data.DisplayName,
    type: () => "group",
    icon: () => "group"
  }
};

export default APP_DATA_TYPE;
