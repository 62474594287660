//@ts-check
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Typography, Grid, FormLabel } from '@material-ui/core';
import PageLayout from '../components/PageLayout';
import PageAvatar from '../components/PageAvatar';
import { Form, DataAction, Icon, Modals, Utils } from 'ecrion-components';
import Button from '../components/Button';
import FormaterDataList from '../components/FormaterDataList';
import Chip from '../components/Chip';
import SubTitle from '../components/SubTitle';
import { withStyles, createStyles } from '@material-ui/core/styles';
import NotFound from './NotFound';
import Loading from '../components/Loading';
import dompurify from 'dompurify';

const formGridProps = {
  alignItems: 'center',
};

const styles = (theme) =>
  createStyles({
    chip: {
      backgroundColor: '#fff',
      color: '#000',
    },
  });

const sanitizer = dompurify.sanitize;

const AppEdit = ({ history, match, classes }) => {
  let appId = match && match.params && match.params.id ? match.params.id : null;
  const [pageLoading, setPageLoading] = useState(true);
  const [appEntity, setAppEntity] = useState(null);

  useEffect(() => {
    Utils.request({
      url: `/backend/microservices2?appId=${encodeURIComponent(appId)}`,
    }).then(loadApp, onDataError);
  }, []);

  function loadApp(manifest) {
    setAppEntity(manifest);
    setPageLoading(false);
  }

  function onDataError() {
    setPageLoading(false);
  }

  function renderFooter(app) {
    if (!app) return <div />;

    return (
      <div style={{ marginTop: 8 * 6, marginBottom: 8 * 2 }}>
        {renderActions(app.MicroserviceAppManifest)}
      </div>
    );
  }

  function renderActions(manifest) {
    const availableType = manifest.States.find(
      (s) => s.Type.toLowerCase() === 'available'
    );
    if (!availableType) return <div />;

    return (
      <div>
        <Typography
          color="inherit"
          dangerouslySetInnerHTML={{
            __html: sanitizer(availableType.Description),
          }}
        />
        <Grid
          container
          justify="center"
          spacing={2}
          style={{ marginTop: 8 * 6, marginBottom: 8 * 2 }}
        >
          {availableType.Actions.map((a) => {
            return (
              <Grid key={a.Type} item>
                {renderAction(a)}
              </Grid>
            );
          })}
        </Grid>
      </div>
    );
  }

  function removeApp() {
    DataAction.item({
      dataType: 'microserviceApp',
      id: appId,
    })
      .delete({
        useConfirmation: true,
      })
      .then((response) => {
        if (response && response.payload && response.payload.RedirectURL)
        {
          let redirectUrl = response.payload.RedirectURL;
          if(redirectUrl.indexOf("https://") !==0 &&  redirectUrl.indexOf("http://") !==0)
              redirectUrl="https://"+ redirectUrl;
              window.location.href = redirectUrl;
        }      
        else history.push('/');
      });
  }

  function renderAction(action) {
    switch (action.Type.toLowerCase()) {
      case 'remove':
        return (
          <Button
            variant="outlined"
            onClick={removeApp}
            style={{ marginRight: 8 }}
          >
            Remove
          </Button>
        );
      case 'link':
        return (
          <Button
            variant="outlined"
            onClick={() => window.open(action.Url, '_blank')}
            style={{ marginRight: 8 }}
          >
            {action.Caption}
          </Button>
        );

      default:
        break;
    }
  }

  function renderContent(app, values) {
    return (
      <>
        <SubTitle includedInFormFragment>
          <PageAvatar src={app.MicroserviceAppManifest.IconURL} type="icon">
            {app.MicroserviceAppManifest.Name}
          </PageAvatar>
          {app.MicroserviceAppInstallation.Upgrade && (
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid item>
                <Icon
                  className="fa fa-arrow-circle-up"
                  hoverable
                  label={`Update ${app.MicroserviceAppManifest.Name} to version ${app.MicroserviceAppInstallation.Upgrade.Version}`}
                  tooltipPlacement="left"
                  onClick={() => {
                    Modals.open({
                      type: Modals.TYPES.CONFIRMATION,
                      props: {
                        title: `Update ${app.MicroserviceAppManifest.Name}`,
                        message: `Are you sure you want to update ${app.MicroserviceAppManifest.Name} to version ${app.MicroserviceAppInstallation.Upgrade.Version}?`,
                        onConfirm: () => {
                          return Utils.request({
                            url: `/backend/microservices2/update?appId=${encodeURIComponent(
                              app.MicroserviceAppManifest.Id
                            )}`,
                            method: 'PUT',
                          }).then(() => {
                            history.push('/');
                          });
                        },
                      },
                    });
                  }}
                />
              </Grid>
            </Grid>
          )}
        </SubTitle>
        <Grid container direction="column">
          <Grid item>
            <Grid container {...formGridProps}>
              <Grid item xs={4}>
                <FormLabel htmlFor="MicroserviceAppInstallation.Category">
                  Category
                </FormLabel>
              </Grid>
              <Grid item xs>
                <Form.Fields.DataSelect
                  name="MicroserviceAppInstallation.Category"
                  dataType="availableModuleCategory"
                  id="categories"
                  label={null}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container {...formGridProps}>
              <Grid item xs={4}>
                <FormLabel>Access Rights</FormLabel>
              </Grid>
              <Grid item>
                <FormaterDataList
                  id={appId}
                  maxCount={3}
                  render={renderPrincipalItem}
                  renderMore={renderMorePrincipalsItems}
                  dataType="microserviceAppPrincipal"
                  dataProps={{
                    appId: appId,
                  }}
                  actionLabel="Manage Access"
                  actionClick={() =>
                    history.push('/app/' + appId + '/edit/accessRights')
                  }
                  disabled={app.MicroserviceAppManifest.CoreFunctionality}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }

  function renderMorePrincipalsItems(moreNumber) {
    if (moreNumber <= 0) return;

    return <Typography color="inherit">+ {moreNumber} more</Typography>;
  }

  function renderPrincipalItem(principal) {
    return (
      <Chip iconType={principal.PrincipalType} label={principal.DisplayName} />
    );
  }

  function renderForm(app) {
    if (!app) return <div />;

    return (
      <Form.Fragment
        onValidate={(data) => {
          const errors = {};
          if (
            data &&
            data.MicroserviceAppInstallation &&
            !data.MicroserviceAppInstallation.Category
          ) {
            errors.MicroserviceAppInstallation.Category =
              'Category is required';
          }

          return errors;
        }}
        submitLabel="Save"
        submittingLabel="Saving..."
        dataItemProps={{
          dataType: 'microserviceApp',
          id: appId,
        }}
        renderContent={(values) => renderContent(app, values)}
        onSuccess={() => {
          history.push('/');
        }}
        renderFooterLeft={() => (
          <Button
            variant="outlined"
            onClick={() => removeApp()}
            style={{ marginLeft: 8 }}
          >
            Uninstall
          </Button>
        )}
        onCancel={() => {}}
      />
    );
  }

  return pageLoading ? (
    <Loading />
  ) : appEntity ? (
    <PageLayout
      variant="small"
      title={appEntity.MicroserviceAppManifest.Name}
      navBarType="closable"
      returnTo="/apps"
    >
      <div>
        {renderForm(appEntity)}
        {renderFooter(appEntity)}
      </div>
    </PageLayout>
  ) : (
    <NotFound />
  );
};

export default withStyles(styles, { withTheme: true })(withRouter(AppEdit));
