//@ts-check
import React from "react";
import { Grid, Typography, List, CircularProgress } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { DataList } from "ecrion-components";
import Item from "./Item";

/**
 * @typedef {object} Props
 * @property {string} moduleName
 * @property {int} columnsCount
 *
 **/

function processApps(columnsCount, apps) {
  let result = [];
  const itemsPerColumn = Math.ceil(apps.length / columnsCount);

  let column = [];

  for (let index = 0; index < apps.length; index++) {
    const element = apps[index];

    column.push(element);

    if (column.length === itemsPerColumn) {
      result.push(column);
      column = [];
    }
  }

  if (column.length > 0) result.push(column);

  return result;
}

function getSecondaryLabel(app) {
  if (app.MicroserviceAppInstallation)
    if (
      app.MicroserviceAppInstallation.Instalation.Status === "Installing" ||
      app.MicroserviceAppInstallation.Instalation.Status === "Uninstalling"
    )
      return `${app.MicroserviceAppInstallation.Instalation.Status}...`;
  return "";
}

function getRedirect(app) {
  if (app.MicroserviceAppInstallation)
    if (app.MicroserviceAppInstallation.Instalation.RedirectURL)
      return app.MicroserviceAppInstallation.Instalation.RedirectURL;
  return app.MicroserviceAppManifest.HomeURL;
}

const Module = ({ moduleName, columnsCount }) => {
  return (
    <DataList
      id={"microserviceApp_" + moduleName}
      dataType="microserviceApp"
      dataProps={{ category: moduleName }}
      refreshInterval={10000}
      itemsPerPage={999999}
    >
      {({ state }) =>
        state.view.isLoading ? (
          <div style={{ textAlign: "center" }}>
            <CircularProgress />
          </div>
        ) : state.data.length === 0 ? (
          <div style={{ textAlign: "center" }}>
            <Typography color="inherit">No apps installed.</Typography>
          </div>
        ) : (
          <Grid container direction="column">
            <Grid
              item
              style={{
                paddingBottom: 8,
                paddingTop: 8,
                marginTop: "0.1rem",
                borderBottom: "1px solid rgb(211, 211, 211)",
              }}
            >
              <Typography
                variant="h6"
                color="inherit"
                style={{
                  fontWeight: "bold",
                  paddingLeft: "30px",
                  cursor: "default",
                  fontSize: "0.98rem"
                }}
              >
                {moduleName}
              </Typography>
            </Grid>
            <Grid item>
              <Grid container direction="row" spacing={2}>
                {processApps(columnsCount, state.data).map((column, index) => (
                  <Grid item key={index} xs={Math.ceil(12 / columnsCount)}>
                    <List>
                      {column.map((app) => (
                        <Item
                          key={app.MicroserviceAppManifest.Id}
                          label={app.MicroserviceAppManifest.Name}
                          secondaryLabel={getSecondaryLabel(app)}
                          to={getRedirect(app)}
                          iconURL={app.MicroserviceAppManifest.IconURL}
                        />
                      ))}
                    </List>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        )
      }
    </DataList>
  );
};

Module.defaultProps = {
  moduleName: null,
};

export default withRouter(Module);
