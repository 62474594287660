import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const styles = theme => ({
  subTitle: {
    marginBottom: theme.spacing(3),
  },
  subTitleFormFragment: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1) * -2,
  },
});

const SubTitle = ({ children, classes, includedInFormFragment }) => {
  return (
    <div
      className={
        !includedInFormFragment
          ? classes.subTitle
          : classes.subTitleFormFragment
      }
    >
      {children}
    </div>
  );
};

SubTitle.defaultProps = {
  includedInFormFragment: false,
};

SubTitle.propTypes = {
  children: PropTypes.any.isRequired,
  classes: PropTypes.object.isRequired,
  includedInFormFragment: PropTypes.bool,
};

export default withStyles(styles, { withTheme: true })(SubTitle);
