//@ts-check
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Icon } from 'ecrion-components';

const styles = theme =>
  createStyles({
    chip: {
      borderRadius: 6,
      display: 'inline-block',
      padding: '4px 8px',
      fontSize: '0.7rem',
      backgroundColor: '#fff',
      color: '#000',
    },
    icon: {
      marginRight: '4px',
      fontSize: '0.7rem',
      marginBottom: '-2px',
    },
  });

const Chip = ({ iconType, label, classes, backgroundColor, color }) => {
  return (
    <span
      className={classes.chip}
      style={{
        backgroundColor: backgroundColor,
        color: color,
      }}
    >
      {iconType && <Icon className={classes.icon} type={iconType} />}
      <span>{label}</span>
    </span>
  );
};

Chip.defaultProps = {};

Chip.propTypes = {
  /** Specify a dataType to listen for data changes */
  label: PropTypes.string.isRequired,

  /** Icon */
  iconType: PropTypes.string,

  /** background */
  backgroundColor: PropTypes.string,

  /** color */
  color: PropTypes.string,
};

export default withStyles(styles, { withTheme: true })(Chip);
