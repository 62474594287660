const ENVIRONMENT_LICENSES = {
  data: {
    url: "/backend/AccountsEnvironmentLicences?accountToken={accountToken}",

    dataKey: "accountsEnvironmentLicenses",
    idKeyProp: "Id",
    onBeforeRequest: (config, request) => {
      let url = request.url;

      if (request.type === "loadView") {
        url = url
          .split("{accountToken}")
          .join(encodeURIComponent(config.dataProps.accountToken));
      }

      return {
        url,
      };
    },
  },
  placeholders: {
    label: function (data) {
      let label = data.Key;

      if (data.UsersCount !== 0) label += ` (${data.UsersCount} users`;
      else label += ` (1 user`;

      const date = new Date(data.EndDate);
      const month = date.getMonth() + 1;

      label += `, ${month}/${date.getDate()}/${date.getFullYear()}`; // add expire date
      label += `, License Id: ${data.Id})`; // add license id

      return label;
    },
    type: () => "licenses",
    icon: () => "licenses",
  },
};

export default ENVIRONMENT_LICENSES;
