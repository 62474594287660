//@ts-check
import React, { useState } from "react";
import ChooseEmailPage from "./ChooseEmailPage";
import GrantAccessPage from "./GrantAccessPage";
import CreateUserPage from "./CreateUserPage";

const NewLocalUser = () => {
  const [email, setEmail] = useState(null);
  const [createNewUser, setCreateNewUser] = useState(true);

  function back() {
    setEmail(null);
    setCreateNewUser(true);
  }

  return email ? (
    createNewUser ? (
      <CreateUserPage email={email} back={back} />
    ) : (
      <GrantAccessPage email={email} back={back} />
    )
  ) : (
    <ChooseEmailPage setEmail={setEmail} setCreateNewUser={setCreateNewUser} />
  );
};

export default NewLocalUser;
