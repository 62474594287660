//@ts-check
import React from "react";
import { Icon, Page } from "ecrion-components";
import Grid from "@material-ui/core/Grid";
import { withRouter } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import {
  Button,
  Menu,
  MenuItem,
  withStyles,
  createStyles,
  Avatar,
  ButtonGroup,
} from "@material-ui/core";
import { DataList, withUserInfo } from "ecrion-components";

const EnvironmentMenu = ({ classes, theme, state, userInfo, history }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  function handleMenu(event) {
    setAnchorEl(event.currentTarget);
  }

  const handleClick = (url) => () => {
    setAnchorEl(null);
    window.location = url;
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const redirectHome = () => {
    if (history.location.pathname === "/") return;

    setAnchorEl(null);
    history.push("/");
  };

  return (
    <>
      <ButtonGroup variant="text" color="inherit" aria-label="split button">
        <Button
          color="inherit"
          onClick={redirectHome}
          className={classes.envButton}
        >
          <Page.NavBar.Environment
            classes={{
              environmentText: classes.environmentText,
              environmentAvatar: classes.environmentAvatar,
              defaultEnvironmentLogo: classes.defaultEnvironmentLogo,
            }}
          />
        </Button>
        {state.data.length > 1 && (
          <Button
            className={classes.multipleEnvs}
            aria-owns={open ? "menu-appbar" : undefined}
            aria-haspopup="true"
            aria-label="select merge strategy"
            onClick={state.data.length > 1 ? handleMenu : null}
            color="inherit"
            size="small"
            variant="text"
            style={{
              backgroundColor: "#FFF",
            }}
          >
            <Icon type="down" className={classes.downIcon} />
          </Button>
        )}
      </ButtonGroup>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        onClose={handleClose}
        className={classes.menuEnvironments}
      >
        {state.data.length === 1 && (
          <MenuItem onClick={handleClose} disabled selected={false}>
            <Typography>
              {state.view.isLoading ? "Loading..." : "No other environments"}
            </Typography>
          </MenuItem>
        )}
        {state.data.length > 1 &&
          state.data.map((environment) => (
            <MenuItem
              key={environment.name}
              onClick={handleClick(environment.url)}
              selected={environment.name === userInfo.environmentName}
            >
              <Grid container alignItems="center" wrap="nowrap" spacing={2}>
                <Grid item>
                  {environment.logo ? (
                    <Avatar
                      src={environment.logo}
                      className={classes.environmentAvatar}
                      alt="Environment logo"
                    />
                  ) : (
                    <img
                      className={classes.environmentLogoWrapper}
                      alt="MHC logo"
                      src="/Images/icons/mhc-logo.svg"
                    />
                  )}
                </Grid>
                <Grid item xs>
                  <Typography color="inherit" noWrap>
                    {environment.name}
                  </Typography>
                </Grid>
              </Grid>
            </MenuItem>
          ))}
      </Menu>
    </>
  );
};

const Environment = ({ classes, theme, userInfo, history }) => {
  return (
    <div>
      <DataList id="environments" dataType="environments" itemsPerPage={999999}>
        {({ state }) => (
          <EnvironmentMenu
            classes={classes}
            theme={theme}
            state={state}
            userInfo={userInfo}
            history={history}
          />
        )}
      </DataList>
    </div>
  );
};

const styles = (theme) =>
  createStyles({
    envButton: {
      borderRight: "0px solid transparent !important",
      padding: "0px",
    },
    environmentText: {
      fontSize: theme.typography.fontSize,
    },
    environmentAvatar: {
      height: theme.spacing(4),
      width: theme.spacing(4),
    },
    environmentLogoWrapper: {
      height: "20px",
      marginTop: "3px",
      marginRight: "3px",
    },
    multipleEnvs: {
      "&:hover": {
        backgroundColor: "#F3F5F7 !important",
      },
      "&:active": {
        backgroundColor: "#E0E5EB !important",
      },
    },
    downIcon: {
      fontSize: theme.typography.fontSize,
      lineHeight: `${theme.common.Page.NavBar.style.height}px`,
      color: "#000000",
      fontWeight: "bold",
    },
    defaultEnvironmentLogo: {
      "& > *": {
        fontSize: "1.5rem",
      },
    },
    menuEnvironments: {
      "& .MuiMenu-list": {
        backgroundColor: "#FFFFFF"
      }
    }
  });

export default withStyles(styles, { withTheme: true })(
  withUserInfo(withRouter(Environment))
);
