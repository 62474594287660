// @ts-nocheck

import React from 'react';
import { Icon, DataFilter, DataAction } from 'ecrion-components';
import { LinearProgress, Typography, List, Grid } from '@material-ui/core';
import Item from '../components/Item';
import PageLayout from '../components/PageLayout';
import ProgressiveDataList from '../components/ProgressiveDataList';
import SubTitle from '../components/SubTitle';

const buttonProps = {
  hoverable: true,
};

const Categories = ({ history, location }) => {
  function removeCategory(name) {
    DataAction.item({
      dataType: 'availableModuleCategory',
      id: name,
    })
      .delete({
        useConfirmation: true,
      })
      .then();
  }

  return (
    <PageLayout
      variant="small"
      title="Categories"
      navBarType="closable"
      returnTo="/apps"
    >
      <SubTitle>
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item>
            <DataFilter history={history} location={location} searchIn="name" />
          </Grid>
          <Grid item>
            <div
              style={{
                border: '1px solid',
                height: '100%',
                marginLeft: 8,
                minHeight: 8 * 4,
              }}
            />
          </Grid>
          <Grid item>
            <Icon
              type="add"
              onClick={() =>
                history.push('/category' + history.location.search)
              }
              label="Add"
              tooltipPlacement="bottom"
              {...buttonProps}
            />
          </Grid>
        </Grid>
      </SubTitle>
      <Grid container direction="column" justify="center">
        <Grid item xs>
          <ProgressiveDataList
            key={location.search}
            dataType="availableModuleCategory"
            pageStart={0}
            dataProps={{
              filter: DataFilter.createFromLocation(location),
            }}
          >
            {({ state }) => {
              return (
                <div>
                  {state.data.length === 0 ? (
                    state.view.isLoading ? (
                      <LinearProgress />
                    ) : (
                        <div style={{ textAlign: 'center' }}>
                          <Typography color="inherit">No categories.</Typography>
                        </div>
                      )
                  ) : (
                      <List>
                        {state.data.map(
                          category =>
                            typeof category !== 'undefined' && (
                              <Item
                                key={category.Name}
                                label={category.Name}
                                iconType="category"
                                to={
                                  '/category/' +
                                  category.Name +
                                  history.location.search
                                }
                                onDelete={{
                                  click: () => removeCategory(category.Name),
                                }}
                              />
                            )
                        )}
                      </List>
                    )}
                  {state.view.isLoading && <LinearProgress />}
                </div>
              );
            }}
          </ProgressiveDataList>
        </Grid>
      </Grid>
    </PageLayout>
  );
};

export default Categories;
