import React from "react";
import { DataList } from "ecrion-components";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Button from "./Button";

const FormaterDataList = ({
  id,
  dataType,
  maxCount,
  separator,
  render,
  renderMore,
  fallback,
  dataProps,
  actionLabel,
  actionClick,
  disabled
}) => {
  return (
    <div style={{ minHeight: "34px" }}>
      <div
        style={{
          position: "absolute",
          whiteSpace: "nowrap",
          minHeight: "34px"
        }}
      >
        <div
          style={{
            display: "inline-block",
            verticalAlign: "sub"
          }}
        >
          <DataList
            id={"formater" + dataType + "-" + id}
            dataType={dataType}
            // {...dataProps}
            itemsPerPage={maxCount}
            dataProps={{
              ...dataProps
            }}
          >
            {({ state }) =>
              state.data.length === 0 ? (
                fallback
              ) : (
                <React.Fragment>
                  {state.data.map((t, idx) => (
                    <React.Fragment key={idx}>
                      {render(t)}
                      {idx < state.data.length - 1 ? separator : null}
                    </React.Fragment>
                  ))}
                  {renderMore(state.count - maxCount)}
                </React.Fragment>
              )
            }
          </DataList>
        </div>
        {actionLabel && actionClick && (
          <Button
            variant="outlined"
            onClick={actionClick}
            style={{ marginLeft: 8, position: "absolute" }}
            disabled={disabled}
          >
            {actionLabel}
          </Button>
        )}
      </div>
    </div>
  );
};

FormaterDataList.defaultProps = {
  fallback: (
    <Typography color="inherit" component="span" style={{ display: "inline" }}>
      <i>None</i>
    </Typography>
  ),
  separator: " ",
  disabled: false
};

FormaterDataList.propTypes = {
  id: PropTypes.string.isRequired,

  /** Specify a dataType to listen for data changes */
  dataType: PropTypes.string.isRequired,

  /** Additional data that is required by the business logic to get the items.
   * e.g. to display ads inside a promotion this would be { promotionId: 4 } */
  dataProps: PropTypes.object,

  /* Specify the max number of displayed entries */
  maxCount: PropTypes.number.isRequired,

  /* Specify separator between entries */
  separator: PropTypes.string,

  /* Specify render function for each element  */

  render: PropTypes.func.isRequired,

  /* Specify render function for rest of elements  */

  renderMore: PropTypes.func.isRequired,

  /* Specify action of formater data list */
  actionLabel: PropTypes.string,
  actionClick: PropTypes.func,

  fallback: PropTypes.element,
  disabled: PropTypes.bool
};

export default FormaterDataList;
