//@ts-check
import React from "react";
import DocumentTitle from "react-document-title";
import { Grid, Typography } from "@material-ui/core";
import NavBar from "../components/NavBar";
import { withUserInfo, Page, Utils } from "ecrion-components";
import { withRouter } from "react-router-dom";
import { handlePermissions } from "../utils/handlePermissions";
import { useSettings } from "../context/SettingsContext";

/**
 * @typedef {object} Props
 * @property {React.ReactNode} children
 * @property {number} maxWidth
 * @property {string} title
 * @property {"h6" | "button" | "caption" | "h1" | "h2" | "h3" | "h4" | "h5" | "inherit" | "overline" | "subtitle1" | "subtitle2" | "body1" | "body2" | "srOnly" } titleVariant
 * @property {'default' | 'closable'} navBarType
 * @property {string} returnTo
 * @property {'small' | 'large'} variant
 *  @property {object} history
 *  @property {object} userInfo
 * @property {object} permissions
 *
 * @param {Props} props
 */
const PageLayout = ({
  children,
  maxWidth,
  title,
  titleVariant,
  navBarType,
  returnTo,
  variant,
  history,
  userInfo,
  permissions,
}) => {
  handlePermissions(userInfo, history, permissions);
  const settings = useSettings();

  if (settings && settings.Error && settings.Error.response) {
    const { status } = settings.Error;
    const error = Utils.getPrettyError(settings.Error);

    return (
      <React.Fragment>
        <DocumentTitle
          title={title === "Engage" ? "Home - Error" : title + " - Error"}
        />
        <Typography
          style={{
            textAlign: "center",
            marginTop: 8 * 10,
          }}
          color="inherit"
        >
          <br />
          {status === 402
            ? "Your trial has expired."
            : `Organization could not be loaded. Error: ${error}`}
          <br />
          Please contact{" "}
          <a
            href="https://accounts.ecrion.com/home/support"
            style={{ color: "inherit" }}
          >
            Ecrion Support
          </a>
          .
        </Typography>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <DocumentTitle
          title={
            title === "EngageCX"
              ? "Home - MHC EngageCX"
              : title + " - MHC EngageCX"
          }
        />
        <Page.Common navBarHidden toolBarHidden margin="none">
          <NavBar type={navBarType} returnTo={returnTo} />
          {(() => {
            if (title !== "EngageCX") {
              return (
                <Typography
                  style={{
                    textAlign: "center",
                    marginBottom: 8 * 6,
                    marginTop: 8 * 5,
                  }}
                  variant={titleVariant}
                  color="inherit"
                >
                  {title}
                </Typography>
              );
            }
          })()}
          <div
            style={
              title !== "EngageCX"
                ? {
                    margin: "auto",
                    maxWidth,
                  }
                : {
                    margin: "auto",
                    maxWidth,
                    position: "relative",
                    top: "30%",
                    transform: "translateY(-30%)",
                  }
            }
          >
            {variant === "small" ? (
              <Grid container justify="center">
                <Grid item />
                <Grid item md={6} xs={12}>
                  {children}
                </Grid>
                <Grid item />
              </Grid>
            ) : (
              children
            )}
          </div>
        </Page.Common>
      </React.Fragment>
    );
  }
};

PageLayout.defaultProps = {
  maxWidth: 1100,
  navBarType: "default",
  titleVariant: "h6",
  variant: "large",
  permissions: ["ManageEnvironment"],
  returnTo: "/",
};

export default withRouter(withUserInfo(PageLayout));
