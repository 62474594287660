//@ts-check

/**
 * @typedef { object } UserGroup
 * @property { number } Id Groups's name
 * @property { string } Name Groups's name

/**@type {import('data/types').DataType<UserGroupModel>} */
const APP_DATA_TYPE = {
  data: {
    url: '/backend/userGroupsGrid?userName={userName}&include={include}',
    createItemUrl: '/backend/userGroupsGrid?userName={userName}',
    deleteItemUrl: '/backend/userGroupsGrid?userName={userName}&groupName={id}',
    dataKey: 'userGroups',
    idKeyProp: 'Name',
    onBeforeRequest: (config, request) => {
      if (!config.dataProps) return;
      if (
        request.type !== 'createItem' &&
        request.type !== 'loadView' &&
        request.type !== 'deleteItem'
      )
        return;
      let {
        dataProps: { userName, include },
      } = config;

      let url = request.url;
      url = url.split('{userName}').join(userName);
      url = url.split('{include}').join(include);
      return {
        url,
      };
    },
  },

  placeholders: {
    label: data => data.Name,
    type: () => 'user',
    icon: () => 'user',
    delete: () => 'remove',
    deleting: () => 'removing',
  },
};

export default APP_DATA_TYPE;
