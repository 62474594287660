//@ts-check

import { Grid, Typography, LinearProgress, List } from '@material-ui/core';
import { DataFilter, Icon, DataAction } from 'ecrion-components';
import React from 'react';
import { withRouter } from 'react-router-dom';
import PageLayout from '../components/PageLayout';
import ProgressiveDataList from '../components/ProgressiveDataList';
import Item from '../components/Item';
import PageAvatar from '../components/PageAvatar';
import SubTitle from '../components/SubTitle';

const buttonProps = {
  hoverable: true,
};

const GroupWorkspaces = ({ history, location, match }) => {
  let groupName =
    match && match.params && match.params.id ? match.params.id : null;

  function renderHeader() {
    return (
      <SubTitle>
        <PageAvatar src="" type="icon">
          group
        </PageAvatar>
        <Typography
          style={{
            marginTop: 8,
            textAlign: 'center',
          }}
          color="inherit"
        >
          {groupName}
        </Typography>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          style={{ marginTop: 8 }}
        >
          <Grid item>
            <DataFilter
              history={history}
              location={location}
              searchIn="query"
            />
          </Grid>
          <Grid item>
            <div
              style={{
                border: '1px solid',
                height: '100%',
                marginLeft: 8,
                minHeight: 8 * 4,
              }}
            />
          </Grid>
          <Grid item>
            <Icon
              type="add"
              onClick={() =>
                history.push(
                  '/group/' +
                    groupName +
                    '/workspaces/add' +
                    history.location.search
                )
              }
              {...buttonProps}
            />
          </Grid>
        </Grid>
      </SubTitle>
    );
  }

  function removeWorkspacePermissions(id) {
    DataAction.item({
      dataType: 'groupWorkspaces',
      id: id,
      dataProps: {
        groupName: groupName,
      },
    }).delete({
      useConfirmation: true,
      message:
        'Are you sure you want to remove access of group "' +
        groupName +
        '" to workspace "' +
        id +
        '"?',
    });
  }

  return (
    <PageLayout
      variant="small"
      title="Group Workspaces"
      navBarType="closable"
      returnTo={`/group/${groupName}`}
    >
      {renderHeader()}
      <Grid container direction="column" justify="center">
        <Grid item>
          <ProgressiveDataList
            key={location.search}
            dataType="groupWorkspaces"
            pageStart={0}
            dataProps={{
              filter: DataFilter.createFromLocation(location),
              groupName: groupName,
            }}
          >
            {({ state }) => {
              return (
                <div>
                  {state.data.length === 0 ? (
                    state.view.isLoading ? (
                      <LinearProgress />
                    ) : (
                      <div style={{ textAlign: 'center' }}>
                        <Typography color="inherit">No workspaces.</Typography>
                      </div>
                    )
                  ) : (
                    <List>
                      {state.data.map(
                        groupWorkspace =>
                          typeof groupWorkspace !== 'undefined' && (
                            <Item
                              key={groupWorkspace.WorkspaceName}
                              label={groupWorkspace.WorkspaceName}
                              iconType="workspace"
                              to={`/workspace/${
                                groupWorkspace.WorkspaceName
                              }?returnUrl=group/${groupName}/workspaces`}
                              onRemove={{
                                click: () =>
                                  removeWorkspacePermissions(
                                    groupWorkspace.WorkspaceName
                                  ),
                              }}
                            />
                          )
                      )}
                    </List>
                  )}
                  {state.view.isLoading && <LinearProgress />}
                </div>
              );
            }}
          </ProgressiveDataList>
        </Grid>
      </Grid>
    </PageLayout>
  );
};

export default withRouter(GroupWorkspaces);
