import React from "react";
import PropTypes from "prop-types";
import { Icon } from "ecrion-components";

const SIZE = "32px";

const IconAvatar = ({ icon, color, classes }) => {
  return (
    <div
      className={classes && classes.iconAvatar}
      style={{
        border: "1px solid",
        borderRadius: "50%",
        margin: "auto",
        height: SIZE,
        width: SIZE,
        color: color ? color : "#000000",
      }}
    >
      <Icon
        type={icon}
        color={color}
        style={{
          display: "inline-block",
          textAlign: "center",
          lineHeight: SIZE,
          height: SIZE,
          width: SIZE,
          fontSize: "1em",
          marginLeft: "-1px",
        }}
      />
    </div>
  );
};

IconAvatar.defaultProps = {};

IconAvatar.propTypes = {
  /** Specify a text to be added to letter avatar */
  icon: PropTypes.string.isRequired,
  classes: PropTypes.object,
  color: PropTypes.string,
};

export default IconAvatar;
