//@ts-check
import React from "react";
import { withRouter } from "react-router-dom";
import { Grid, FormLabel } from "@material-ui/core";
import PageLayout from "../../components/PageLayout";
import PageAvatar from "../../components/PageAvatar";
import { Form, DataAction } from "ecrion-components";
import Button from "../../components/Button";
import SubTitle from "../../components/SubTitle";

const formGridProps = {
  alignItems: "center",
};

const EditCategory = ({ history, match }) => {
  let name = match && match.params && match.params.id ? match.params.id : null;

  function removeCategory() {
    DataAction.item({
      dataType: "availableModuleCategory",
      id: name,
    })
      .delete({
        useConfirmation: true,
      })
      .then((res) => {
        history.goBack();
      });
  }

  return (
    <PageLayout
      variant="small"
      title="Edit Category"
      navBarType="closable"
      returnTo="/categories"
    >
      <Form.Fragment
        dataItemProps={{
          dataType: "availableModuleCategory",
          id: name,
        }}
        renderFooterLeft={() => (
          <Button
            variant="outlined"
            onClick={() => removeCategory()}
            style={{ marginLeft: 8 }}
          >
            Delete Category
          </Button>
        )}
        renderContent={() => (
          <React.Fragment>
            <SubTitle includedInFormFragment>
              <PageAvatar src="" type="icon">
                category
              </PageAvatar>
            </SubTitle>
            <Grid container direction="column">
              <Grid item>
                <Grid container {...formGridProps}>
                  <Grid item sm={4} xs={12}>
                    <FormLabel htmlFor="Name" required>
                      Name
                    </FormLabel>
                  </Grid>
                  <Grid item sm xs>
                    <Form.Fields.Name
                      name="Name"
                      id="name"
                      label={null}
                      required
                      type="appCategoryName"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container {...formGridProps}>
                  <Grid item sm={4} xs={12}>
                    <FormLabel htmlFor="Order" required>
                      Order
                    </FormLabel>
                  </Grid>
                  <Grid item sm xs>
                    <Form.Fields.Number
                      name="Order"
                      id="Order"
                      label={null}
                      required
                      validate={(value) =>
                        value < 1 && "Order number should be greater than 0"
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container {...formGridProps}>
                  <Grid item sm={4} xs={12}>
                    <FormLabel htmlFor="Layout" required>
                      Layout
                    </FormLabel>
                  </Grid>
                  <Grid item sm xs>
                    <Form.Fields.MultiSelect
                      name="Columns"
                      placeholder="Search..."
                      label={null}
                      options={[
                        {
                          label: "1 - Column",
                          value: 1,
                        },
                        {
                          label: "2 - Columns",
                          value: 2,
                        },
                        {
                          label: "3 - Columns",
                          value: 3,
                        },
                      ]}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </React.Fragment>
        )}
        onSuccess={() => {
          history.push("/categories");
        }}
        onCancel={() => {}}
      />
    </PageLayout>
  );
};

export default withRouter(EditCategory);
