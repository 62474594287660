//@ts-check

/**
 * @typedef { object } Group
 * @property { number } Id Groups's name
 * @property { string } Name Groups's name

/**@type {import('data/types').DataType<MicroserviceGroupModel>} */
const APP_DATA_TYPE = {
  data: {
    url: "/backend/microservicegroups?appId={appId}&include={include}",
    itemUrl: "/backend/microservicegroups?appId={appId}&groupName={Id}",
    createItemUrl: "/backend/microservicegroups?appId={appId}",
    deleteItemUrl: "/backend/microservicegroups?appId={appId}&groupName={id}",
    dataKey: "groups",
    idKeyProp: "Name",
    onBeforeRequest: (config, request) => {
      if (!config.dataProps) return;
      if (
        request.type !== "createItem" &&
        request.type !== "deleteItem" &&
        request.type !== "loadView"
      )
        return;
      let {
        dataProps: { appId, include }
      } = config;

      if (include === undefined) include = true;

      let url = request.url;
      url = url.split("{appId}").join(appId);
      url = url.split("{include}").join(include);
      return {
        url
      };
    }
  },

  placeholders: {
    label: data => data.Name,
    type: () => "group",
    icon: () => "group"
  }
};

export default APP_DATA_TYPE;
