//@ts-check

/**
 * @typedef { object } UserGroup
 * @property { number } Id Groups's name
 * @property { string } Name Groups's name

/**@type {import('data/types').DataType<UserGroupModel>} */
const APP_DATA_TYPE = {
  data: {
    url: '/backend/groupUsersGrid?groupName={groupName}&include={include}',
    itemUrl: '/backend/groupUsersGrid',
    createItemUrl: '/backend/groupUsersGrid',
    deleteItemUrl:
      '/backend/groupUsersGrid?userName={id}&groupName={groupName}',
    dataKey: 'groupUsers',
    idKeyProp: 'UserName',
    filterUrlKey: 'Query',
    onBeforeRequest: (config, request) => {
      if (!config.dataProps) return;
      if (request.type !== 'loadView' && request.type !== 'deleteItem') return;
      let {
        dataProps: { groupName, include },
      } = config;

      let url = request.url;
      url = url.split('{groupName}').join(encodeURIComponent(groupName));
      url = url.split('{include}').join(include);
      return {
        url,
      };
    },
  },

  placeholders: {
    label: data => data.Name + (data.Name !== data.Email ? " (" + data.Email + ")" : ""),
    type: () => 'user',
    icon: () => 'user',
    delete: () => 'remove',
    deleting: () => 'removing',
  },
};

export default APP_DATA_TYPE;
