//@ts-check
import React from "react";
import { withManifest, Icon, Page } from "ecrion-components";
import Grid from "@material-ui/core/Grid";
import { withRouter, Link } from "react-router-dom";
import { withStyles, createStyles, Typography } from "@material-ui/core";
import Environment from "./Environment";
import { Utils } from "ecrion-components";
import { makeStyles } from "@material-ui/core/styles";

const AnchorLink = ({ href, ...rest }) => (
  <Link to={href.replace("/home", "")} {...rest} />
);

const HomeNavBar = (props) => (
  <Grid
    container
    direction="row"
    style={{
      zIndex: 1,
      position: "relative",
      backgroundColor: "#FFF",
      boxShadow: "0px 0px 6px 3px rgba(0, 0, 0, 0.1)",
    }}
    alignItems="center"
  >
    <Grid item xs>
      <Environment history={props.history} />
    </Grid>
    {props.type === "closable" ? (
      <>
        <Grid item style={{ flexGrow: 1 }} />
        <Grid item>
          <Icon
            style={{ color: "black", fontWeight: "bold" }}
            type="close"
            onClick={() => {
              const parsed = Utils.url.parseUrl(window.location.search).query;
              if (
                parsed &&
                parsed["returnUrl"] &&
                (parsed["returnUrl"].startsWith("http://") ||
                  parsed["returnUrl"].startsWith("https://"))
              ) {
                window.location.href = parsed["returnUrl"];
              } else props.history.return(props.returnTo);
            }}
          />
        </Grid>
      </>
    ) : (
      <>
        <Grid item xs style={{ textAlign: "center", cursor: "default" }}>
          <Typography
            component="span"
            style={{
              color: "#000000",
              fontWeight: "bold",
            }}
          >
            EngageCX
          </Typography>
        </Grid>
        <Grid item xs style={{ textAlign: "right" }}>  
          <Page.NavBar.User
              classes={props.classes}
              linkComponent={AnchorLink}
              style={{float: "right"}}
            />         
          <Grid item className={useStyles({}).helpMenu}>
                <div className={useStyles({}).helpMenuDotWrapper}>
                  <div className={useStyles({}).helpMenuDot}>
                    <Page.NavBar.HelpMenu classes={props.classes}/>
                  </div>
                </div>
              </Grid>
                   
        </Grid>
      </>
    )}
  </Grid>
);

const styles = (theme) =>
  createStyles({
    userBoxText: {
      fontSize: theme.typography.fontSize,
    },
    userBoxPicture: {
      "& > *": {
        height: 24,
        width: 24,
        fontSize: 12,
      },
    },
  });

  const useStyles = makeStyles((theme) =>
  createStyles({
    helpMenu: {
      color: theme.common.Page.NavBar.style.color,
      float: "right",
      '&:hover': {
        backgroundColor: '#F3F5F7',
      },
      '&:active, &:focus': {
        backgroundColor: '#E0E5EB',
      },
    },
    helpMenuDot: {
      backgroundColor: '#E0E5EB',
      borderRadius: '50%',
      width: '24px',
      height: '24px',
      marginLeft: '8px',
      display: 'flex',
      justifyContent: 'center',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      '&:active, &:focus': {
        backgroundColor: '#FFF',
      },
    },
    helpMenuDotWrapper : {
      height: '50px',
      width: '40px',
      position: 'relative',
      '&:before': {
        position: 'absolute',
        height: '60%',
        content: '""',
        left: '0',
        bottom: '0',
        borderLeft: '1px solid rgb(211, 211, 211)',
        marginBottom: '10px',
      },
      '&:after': {
        position: 'absolute',
        height: '60%',
        content: '""',
        right: '0',
        bottom: '0',
        borderRight: '1px solid rgb(211, 211, 211)',
        marginBottom: '10px',
      },
    },
  })
  );

export default withManifest(withStyles(styles)(withRouter(HomeNavBar)));
