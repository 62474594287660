//@ts-check
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import PageLayout from "../components/PageLayout";
import AppInfo from "../components/AppInfo";
import { Utils } from "ecrion-components";
import NotFound from "./NotFound";
import Loading from "../components/Loading";

const ManageApp = ({ match }) => {
  const appId = match && match.params && match.params.id ? match.params.id : null;

  const [pageLoading, setPageLoading] = useState(true);
  const [appEntity, setAppEntity] = useState(null);

  useEffect(() => {
    Utils.request({
      url: `/backend/availableMicroservices?id=${encodeURIComponent(appId)}`
    }).then(loadApp, onDataError);
  }, []);

  function loadApp(manifest) {
    setAppEntity(manifest);
    setPageLoading(false);
  }

  function onDataError() {
    setPageLoading(false);
  }

  return (
    pageLoading ? <Loading />
      : (appEntity && appEntity.MicroserviceAppManifest && appEntity.MicroserviceAppManifest.Name
        ? (<PageLayout
          variant="small"
          title={appEntity.MicroserviceAppManifest.Name}
          returnTo="/apps"
          navBarType="closable"
        >
          <AppInfo appId={appId} />
        </PageLayout>)
        : (<NotFound />))
  );
};

export default withRouter(ManageApp);
