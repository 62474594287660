//@ts-check
import React from 'react';
import { withRouter } from 'react-router-dom';
import PageLayout from '../components/PageLayout';
import PageAvatar from '../components/PageAvatar';
import Button from '../components/Button';
import { Form, DataAction } from 'ecrion-components';
import { Grid, FormLabel } from '@material-ui/core';
import SubTitle from '../components/SubTitle';

const formGridProps = {
  alignItems: 'center',
};

const Workspace = ({ match, history }) => {
  let workspaceId =
    match && match.params && match.params.id ? match.params.id : null;

  return (
    <PageLayout
      variant="small"
      title="Workspace"
      navBarType="closable"
      returnTo="/workspaces"
    >
      <Form.Fragment
        dataItemProps={{
          dataType: 'workspace',
          id: workspaceId,
        }}
        renderContent={({ data }) => (
          <React.Fragment>
            <SubTitle includedInFormFragment>
              <PageAvatar src="" type="icon">
                workspace
              </PageAvatar>
            </SubTitle>
            <Grid container direction="column">
              <Grid item>
                <Grid container {...formGridProps}>
                  <Grid item sm={4} xs={12}>
                    <FormLabel htmlFor="Name" required>
                      Name
                    </FormLabel>
                  </Grid>
                  <Grid item sm xs>
                    <Form.Fields.Name
                      required
                      name="Name"
                      id="name"
                      label={null}
                      type="workspaceName"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </React.Fragment>
        )}
        renderFooterLeft={() =>
          workspaceId ? (
            <Button
              variant="outlined"
              onClick={() =>
                DataAction.item({
                  dataType: 'workspace',
                  id: workspaceId,
                })
                  .delete({
                    useConfirmation: true,
                  })
                  .then(() => history.push('/workspaces'))
              }
            >
              Delete
            </Button>
          ) : null
        }
        onSuccess={() => {
          history.push('/workspaces');
        }}
        onCancel={() => {
          history.push('/workspaces');
        }}
      />
    </PageLayout>
  );
};

export default withRouter(Workspace);
