import React from "react";
import { CircularProgress } from "@material-ui/core";

const Loading = () => {
  return (
    <CircularProgress
      size={60}
      variant="indeterminate"
      disableShrink
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        marginLeft: "-25px",
        marginTop: "-25px",
      }}
    />
  );
};

export default Loading;
