//@ts-check
import React from 'react';
import { withRouter } from 'react-router-dom';
import { Grid, FormLabel, Typography } from '@material-ui/core';
import PageLayout from '../components/PageLayout';
import PageAvatar from '../components/PageAvatar';
import SubTitle from '../components/SubTitle';
import { Form, withUserInfo } from 'ecrion-components';

const formGridProps = {
  alignItems: 'center',
};

const EditGroup = ({ history, userInfo, match }) => {
  let groupName =
    match && match.params && match.params.id ? match.params.id : null;

  function renderHeader() {
    return (
      <SubTitle includedInFormFragment>
        <PageAvatar src="" type="icon">
          group
        </PageAvatar>
        <Typography
          style={{
            marginTop: 8,
            textAlign: 'center',
          }}
          color="inherit"
        >
          {groupName}
        </Typography>
      </SubTitle>
    );
  }

  return (
    <PageLayout
      variant="small"
      title="Add user to group"
      navBarType="closable"
      returnTo={`/group/${groupName}/users`}
    >
      <Form.Fragment
        dataItemProps={{
          dataType: 'groupUsers',
        }}
        onValidate={data => {
          const errors = {};
          if (!data.UserName) errors.Name = 'Empty username is not allowed';

          return errors.Name;
        }}
        submitLabel="Add"
        submittingLabel="Adding..."
        defaultValue={{
          groupName: groupName,
        }}
        renderContent={() => (
          <React.Fragment>
            {renderHeader()}
            <Grid container direction="column">
              <Grid item>
                <Grid container {...formGridProps}>
                  <Grid item sm={4} xs={12}>
                    <FormLabel htmlFor="Name" required>
                      User
                    </FormLabel>
                  </Grid>
                  <Grid item sm xs>
                    <Form.Fields.DataSelect
                      name="UserName"
                      dataType="groupUsers"
                      id="groups"
                      label={null}
                      dataProps={{
                        include: false,
                        groupName: groupName,
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </React.Fragment>
        )}
        onSuccess={() => {
          history.return(`/group/${groupName}/users`);
        }}
        onCancel={() => { }}
      />
    </PageLayout>
  );
};

export default withUserInfo(withRouter(EditGroup));
