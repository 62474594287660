//@ts-check

import React from 'react';
import { withRouter } from 'react-router-dom';
import PageLayout from '../components/PageLayout';
import { Form } from 'ecrion-components';
import { Grid, Typography, FormLabel } from '@material-ui/core';
import PageAvatar from '../components/PageAvatar';

const formGridProps = {
  alignItems: 'center',
};

const UserGroupsAdd = ({ history, match }) => {
  let userName =
    match && match.params && match.params.id ? match.params.id : null;

  return (
    <PageLayout
      variant="small"
      title="Add group to user"
      navBarType="closable"
      returnTo={`/user/${userName}/edit/groups`}
    >
      <Form.Fragment
        dataItemProps={{
          dataType: 'userGroups',
        }}
        onValidate={data => {
          const errors = {};
          if (!data.userName)
            errors.userName = 'User is required';

          return errors;
        }}
        submitLabel="Add"
        submittingLabel="Adding..."
        defaultValue={{
          userName: userName,
        }}
        renderContent={() => (
          <React.Fragment>
            <PageAvatar src="" type="icon">
              user
            </PageAvatar>
            <Typography
              style={{
                marginTop: 8,
                textAlign: 'center',
              }}
              color="inherit"
            >
              {userName}
            </Typography>
            <Grid container direction="column">
              <Grid item>
                <Grid container {...formGridProps}>
                  <Grid item sm={4} xs={12}>
                    <FormLabel htmlFor="GroupName" required>
                      Group
                    </FormLabel>
                  </Grid>
                  <Grid item sm xs>
                    <Form.Fields.DataSelect
                      name="GroupName"
                      dataType="userGroups"
                      id="groups"
                      label={null}
                      dataProps={{
                        include: false,
                        userName: userName,
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </React.Fragment>
        )}
        onSuccess={() => {
          history.push('/user/' + userName + '/edit/groups');
        }}
        onCancel={() => { }}
      />
    </PageLayout>
  );
};

export default withRouter(UserGroupsAdd);
