import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  item: {
    width: "100%",
    height: "3px",
    backgroundColor: "rgb(94, 108, 132)",
  },
}));

const Rating = ({ score }) => {
  const classes = useStyles();
  const [color, setColor] = useState("rgb(94, 108, 132)");

  useEffect(() => {
    if (score < 1) setColor("rgb(94, 108, 132");
    else if (score === 1) setColor("rgb(191, 38, 0)");
    else if (score === 2) setColor("rgb(255, 86, 48)");
    else if (score === 3) setColor("rgb(255, 171, 0)");
    else if (score === 4) setColor("rgb(54, 179, 126)");
    else if (score > 4) setColor("rgb(0, 135, 90)");
  }, [score]);

  return (
    <Grid container direction="row" spacing={1}>
      <Grid item xs>
        <div
          className={classes.item}
          style={score > 0 ? { backgroundColor: color } : {}}
        />
      </Grid>
      <Grid item xs>
        <div
          className={classes.item}
          style={score > 1 ? { backgroundColor: color } : {}}
        />
      </Grid>
      <Grid item xs>
        <div
          className={classes.item}
          style={score > 2 ? { backgroundColor: color } : {}}
        />
      </Grid>
      <Grid item xs>
        <div
          className={classes.item}
          style={score > 3 ? { backgroundColor: color } : {}}
        />
      </Grid>
      <Grid item xs>
        <div
          className={classes.item}
          style={score > 4 ? { backgroundColor: color } : {}}
        />
      </Grid>
    </Grid>
  );
};

Rating.defaultProps = {
  score: 0,
};

export default Rating;
