//@ts-check
import React from "react";
import PageLayout from "../components/PageLayout";
import { DataItem, Form } from "ecrion-components";
import { Typography, Grid, FormLabel } from "@material-ui/core";
import PageAvatar from "../components/PageAvatar";
import SubTitle from "../components/SubTitle";

const formGridProps = {
  alignItems: "center"
};

const AppEditAccessRightsAdd = ({ match, history }) => {
  const appId =
    match && match.params && match.params.id ? match.params.id : null;

  function renderHeader(app) {
    if (!app) return <div />;

    return <div />;
  }

  function renderForm(app) {
    if (!app) return <div />;

    return (
      <Form.Fragment
        submitLabel="Save"
        submittingLabel="Saving..."
        dataItemProps={{
          dataType: "microserviceAppPrincipal",
          dataProps: {
            appId: appId
          }
        }}
        renderContent={values => renderContent(app, values)}
        onSuccess={() => {
          history.push(
            "/app/" + app.MicroserviceAppManifest.Id + "/edit/accessrights/"
          );
        }}
        onCancel={() => {}}
      />
    );
  }

  function renderContent(app, values) {
    return (
      <>
        <SubTitle>
          <PageAvatar src={app.MicroserviceAppManifest.IconURL} type="icon">
            {app.MicroserviceAppManifest.Name}
          </PageAvatar>
          <Typography
            style={{
              marginTop: 8,
              textAlign: "center"
            }}
            color="inherit"
          >
            {app.MicroserviceAppManifest.Name}
          </Typography>
        </SubTitle>
        <Grid container direction="column">
          <Grid item>
            <Grid container {...formGridProps}>
              <Grid item xs={3}>
                <FormLabel htmlFor="Id">Users/Groups</FormLabel>
              </Grid>
              <Grid item xs>
                <Form.Fields.DataSelect
                  name="Id"
                  dataType="microserviceAppPrincipal"
                  id="principals"
                  label={null}
                  required
                  dataProps={{
                    include: false,
                    appId: appId
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }

  return (
    <PageLayout
      title="Add Access Rights"
      variant="small"
      navBarType="closable"
      returnTo={`/app/${appId}/edit/accessRights`}
    >
      <DataItem dataType="microserviceApp" id={appId}>
        {({ value }) => {
          return (
            <div>
              {renderHeader(value)}
              {renderForm(value)}
            </div>
          );
        }}
      </DataItem>
    </PageLayout>
  );
};

export default AppEditAccessRightsAdd;
