import { Modals } from "ecrion-components";

//confirm saving user data
export const onSave = (isUsernameChanged, isCurrentUser) => {
    return new Promise((resolve, reject) => {

        var message = "";

        //if username is changed
        if (isUsernameChanged) {
            message = message + `When updating the email, the username will be updated with the same value. `
            //if current username is edited
            if (isCurrentUser) {
                message = message + `After saving changes, you will be redirect to logout.`;
            }
        }

        if (message !== "") {
            return Modals.open({
                type: Modals.TYPES.CONFIRMATION,
                props: {
                    title: "Save user",
                    message: message,
                    onConfirm: () => {
                        return resolve(true);
                    },
                    onClose: () => {
                        return resolve(false);
                    }
                },
            });
        } else {
            return resolve(true);
        }

    });
};

const UserConfirm = {
    onSave
};

export default UserConfirm;
