import React from "react";
import { Route } from "react-router-dom";
import Authorize from "../pages/Unauthorized";
// import { withAuth } from 'ecrion-components';

class PrivateRoute extends React.Component {
  renderRoute = (props) => {
    const { component: Component } = this.props;

    // show authorize page if something goes wrong
    // if (!auth.token || !!auth.error) return <Authorize auth={auth} />;

    return <Component {...props} />;
  };

  render() {
    const { component, auth, ...rest } = this.props;

    return <Route {...rest} render={this.renderRoute} />;
  }
}

PrivateRoute.propTypes = Route.propTypes;

// const PrivateRouteAuth = withAuth(PrivateRoute);

export default PrivateRoute;
