//@ts-check
import React from "react";
import PageLayout from "../components/PageLayout";
import { DataList, DataFilter, DropdownButton, Icon } from "ecrion-components";
import App from "../components/App";
import SubTitle from "../components/SubTitle";
import { Grid, Typography, CircularProgress } from "@material-ui/core";

const FILTER_OPTIONS = [
  { label: "All", value: null },
  {
    label: "Featured",
    value: { categoryName: "Featured" },
  },
  {
    label: "Installed",
    value: { categoryName: "Installed" },
  },
  "separator",
];

const buttonProps = {
  hoverable: true,
};

const ManageApps = ({ history, location }) => {
  return (
    <PageLayout title="Apps" navBarType="closable">
      <SubTitle>
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item>
            <DataFilter
              history={history}
              location={location}
              options={FILTER_OPTIONS}
              searchIn="appName"
            >
              <DropdownButton.DataList
                key="microserviceAppCategory"
                dataType="microserviceAppCategory"
                createDataOptionValue={(data) => ({ categoryName: data.Name })}
              />
            </DataFilter>
          </Grid>
          <Grid item>
            <div
              style={{
                border: "1px solid",
                height: "100%",
                marginLeft: 8,
                minHeight: 8 * 4,
              }}
            />
          </Grid>
          <Grid item>
            <Icon
              type="category"
              onClick={() => history.push("/categories")}
              label="Categories"
              tooltipPlacement="bottom"
              {...buttonProps}
            />
          </Grid>
        </Grid>
      </SubTitle>
      <Grid container direction="column" justify="center">
        <Grid item>
          <DataList
            id="availableMicroserviceApp"
            dataType="availableMicroserviceApp"
            dataProps={{
              filter: DataFilter.createFromLocation(location),
            }}
            itemsPerPage={999999}
          >
            {({ state }) =>
              state.view.isLoading ? (
                <div style={{ textAlign: "center" }}>
                  <CircularProgress />
                </div>
              ) : state.data.length === 0 ? (
                <Typography color="inherit" style={{ textAlign: "center" }}>
                  No apps available
                </Typography>
              ) : (
                <Grid justify="center" container>
                  {state.data.map((app) => (
                    <Grid
                      key={app.MicroserviceAppManifest.Id}
                      item
                      xs={4}
                      style={{ paddingLeft: 8 * 4, paddingBottom: 8 * 6 }}
                    >
                      <App app={app} />
                    </Grid>
                  ))}
                </Grid>
              )
            }
          </DataList>
        </Grid>
      </Grid>
    </PageLayout>
  );
};

export default ManageApps;
