import ReleaseOfflineDialog from "./ReleaseOffline";
import AlertDialog from "./Alert";
import YesNo from "./YesNo";
import ChangePassword from "./ChangePassword";

export default {
  RELEASE_OFFLINE: ReleaseOfflineDialog,
  ALERT: AlertDialog,
  YES_NO: YesNo,
  CHANGE_PASSWORD: ChangePassword,
};
