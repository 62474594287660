//@ts-check
import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { Icon, DataFilter, DataAction, Modals, Utils } from "ecrion-components";
import { LinearProgress, Typography, List, Grid } from "@material-ui/core";
import Item from "../../../components/Item";

import ProgressiveDataList from "../../../components/ProgressiveDataList";
import SubTitle from "../../../components/SubTitle";
import { useAccountsAuth } from "../../../context/AccountsContext";
import { useSettings } from "../../../context/SettingsContext";

const buttonProps = {
  hoverable: true,
};

const Licensing = ({ history, location, online }) => {
  const { token } = useAccountsAuth();
  const settings = useSettings();
  const [forceRefresh, setForceRefresh] = useState(0);

  function getDescription(license) {
    let description = "";
    let dateStr = "";

    if (license.MaintenanceExpirationDate) {
      const date = new Date(license.MaintenanceExpirationDate);
      const month = date.getMonth() + 1;
      dateStr += `${month}/${date.getDate()}/${date.getFullYear()}`;
    }

    description += `Used by ${license.AssignedTo.length} of ${license.UsersCount}, expires on ${dateStr}. License Id: ${license.ProductKeyId}`;

    return description;
  }

  function hasExpired(license) {
    const date = new Date(license.MaintenanceExpirationDate);
    const now = new Date();

    return date < now;
  }

  function getQueryParameters() {
    const parsed = Utils.url.parseUrl(window.location.search).query;
    if (
      parsed &&
      parsed["returnUrl"] &&
      (parsed["returnUrl"].startsWith("http://") ||
        parsed["returnUrl"].startsWith("https://"))
    ) {
      const encodedCurrentReturnUrl = encodeURIComponent(parsed["returnUrl"]);
      const encodedReturnUrl = encodeURIComponent(
        `/licensing?returnUrl=${encodedCurrentReturnUrl}`
      );
      return `?returnUrl=${encodedReturnUrl}`;
    }

    return "";
  }

  return (
    <>
      <SubTitle>
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item>
            <DataFilter
              history={history}
              location={location}
              searchIn="query"
            />
          </Grid>
          <Grid item>
            <div
              style={{
                border: "1px solid",
                height: "100%",
                marginLeft: 8,
                minHeight: 8 * 4,
              }}
            />
          </Grid>
          <Grid item>
            <Icon
              type="add"
              label="Add"
              tooltipPlacement="bottom"
              onClick={() =>
                history.push(`/licensing/add${getQueryParameters()}`)
              }
              {...buttonProps}
            />
          </Grid>
          {online && (
            <Grid item>
              <Icon
                type="refresh"
                label="Refresh licenses"
                tooltipPlacement="right"
                onClick={() => {
                  Modals.open({
                    type: Modals.TYPES.CONFIRMATION,
                    props: {
                      title: "Refresh Licenses",
                      message: "Are you sure you want to refresh licenses?",
                      onConfirm: () => {
                        return Utils.request({
                          url:
                            "/backend/environmentLicensing/refreshLicenses?accountToken=" +
                            encodeURIComponent(token),
                          method: "PUT",
                        }).then(() => {
                          setForceRefresh(forceRefresh + 1);
                        });
                      },
                    },
                  });
                }}
                {...buttonProps}
              />
            </Grid>
          )}
        </Grid>
      </SubTitle>
      <Grid container justify="center">
        <Grid item xs key={forceRefresh}>
          <ProgressiveDataList
            id="environmentLicenses"
            key={location.search}
            dataType="environmentLicenses"
            pageStart={0}
            itemsPerPage={999999}
            dataProps={{
              filter: DataFilter.createFromLocation(location),
            }}
          >
            {({ state }) => {
              return (
                <div>
                  {state.data.length === 0 ? (
                    state.view.page === 0 ? (
                      state.view.isLoading ? (
                        <LinearProgress />
                      ) : (
                        <div style={{ textAlign: "center" }}>
                          {state.view.page === 0 && (
                            <Typography color="inherit">
                              No licenses.
                            </Typography>
                          )}
                        </div>
                      )
                    ) : (
                      <div />
                    )
                  ) : (
                    <List>
                      {state.data.map(
                        (license) =>
                          typeof license !== "undefined" && (
                            <Item
                              key={license.ProductKeyId}
                              label={license.Key}
                              iconType={!license.IconURL ? "key" : null}
                              to={`/licensing/${
                                license.ProductKeyId
                              }/users${getQueryParameters()}`}
                              iconURL={license.IconURL}
                              secondaryLabel={getDescription(license)}
                              labelColor={
                                hasExpired(license) ? "red" : "inherit"
                              }
                              onDelete={{
                                click: function () {
                                  if (!online) {
                                    Modals.open({
                                      type: "RELEASE_OFFLINE",
                                      props: {
                                        productKeyId: license.ProductKeyId,
                                        accountsUrl: settings["AccountsURL"],
                                      },
                                    });
                                  } else {
                                    DataAction.item({
                                      dataType: "environmentLicenses",
                                      id: license.ProductKeyId,
                                      dataProps: {
                                        accountToken: online ? token : null,
                                        offline: !online,
                                      },
                                    }).delete({
                                      useConfirmation: true,
                                    });
                                  }
                                },
                              }}
                            />
                          )
                      )}
                    </List>
                  )}
                  {state.view.isLoading && <LinearProgress />}
                </div>
              );
            }}
          </ProgressiveDataList>
        </Grid>
      </Grid>
    </>
  );
};

export default withRouter(Licensing);
