//@ts-check
import React from "react";
import NewLocalUser from "./NewLocalUser";
import NewSSOUser from "./NewSSOUser";

const NewUser = ({ ssoEnabled }) => {
  return ssoEnabled ? <NewSSOUser /> : <NewLocalUser />;
};

export default NewUser;
