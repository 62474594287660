import microserviceAppCategory from "./microserviceAppCategory";
import microserviceApp from "./microserviceApp";
import availableMicroserviceApp from "./availableMicroserviceApp";
import group from "./group";
import principal from "./principal";
import microserviceAppGroup from "./microserviceAppGroup";
import microserviceAppPrincipal from "./microserviceAppPrincipal";
import moduleCategory from "./moduleCategory";
import availableModuleCategory from "./availableModuleCategory";
import timezone from "./timezone";
import organization from "./organization";
import sso from "./sso";
import userGroups from "./userGroups";
import groupWorkspaces from "./groupWorkspaces";
import groupUsers from "./groupUsers";
import environments from "./environments";
import licensedUsers from "./licensedUsers";
import availableProductKeys from "./availableProductKeys";
import ssoEnvironment from "./ssoEnvironment";
import environmentLicenses from "./environmentLicenses";
import accountsEnvironmentLicenses from "./accountsEnvironmentLicenses";

export default {
  microserviceAppCategory,
  microserviceApp,
  availableMicroserviceApp,
  group,
  availableModuleCategory,
  moduleCategory,
  timezone,
  microserviceAppGroup,
  organization,
  sso,
  userGroups,
  groupWorkspaces,
  groupUsers,
  environments,
  licensedUsers,
  availableProductKeys,
  ssoEnvironment,
  environmentLicenses,
  accountsEnvironmentLicenses,
  microserviceAppPrincipal,
  principal
};
