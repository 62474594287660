//@ts-check
import React from "react";
import { withRouter } from "react-router-dom";
import { Grid, FormLabel, Typography, Tooltip } from "@material-ui/core";
import PageLayout from "../../../components/PageLayout";
import PageAvatar from "../../../components/PageAvatar";
import {
  Form,
  withUserInfo,
  DataItem,
  DataAction,
  Icon,
  Modals,
  Utils,
  withAuth,
} from "ecrion-components";
import FormaterDataList from "../../../components/FormaterDataList";
import Button from "../../../components/Button";
import Chip from "../../../components/Chip";
import { getDisplayName } from "../../../utils/getDisplayName";
import { withStyles, createStyles } from "@material-ui/core/styles";
import SubTitle from "../../../components/SubTitle";
import UserConfirm from "../../UserConfirm";
const formGridProps = {
  alignItems: "center",
};

const styles = (theme) =>
  createStyles({
    avatar: {
      "&:hover": {
        backgroundColor: "blue !important",
      },
    },
    avatarContainer: {
      margin: "auto",
      "& div": {
        "&:focus": {
          outline: "0px",
        },
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
  });

const EditUser = ({
  history,
  userInfo,
  classes,
  userName,
  ssoEnabled,
  logoutUser,
  editProfileURL,
}) => {
  function renderMoreGroupItems(moreNumber) {
    if (moreNumber <= 0) return;

    return (
      <Typography
        color="inherit"
        display="inline"
        style={{
          paddingLeft: "8px",
        }}
      >
        + {moreNumber} more
      </Typography>
    );
  }

  function renderGroupItem(group) {
    return <Chip iconType="group" label={group.Name} />;
  }

  function getPageAvatar(file, displayName) {
    if (!file) {
      return (
        <SubTitle includedInFormFragment>
          <PageAvatar type="text">{displayName}</PageAvatar>
        </SubTitle>
      );
    }

    return (
      <SubTitle includedInFormFragment>
        <PageAvatar src={file.preview}>user</PageAvatar>
      </SubTitle>
    );
  }

  function getDateDiff(date) {
    if (!date || date === "--") return "Never logged in";

    let returnMsg = "Last logged on ";

    const t1 = new Date();
    const t2 = new Date(date);

    const diffMS = t1 - t2;
    const diffS = diffMS / 1000;
    const diffM = diffS / 60;
    const diffH = diffM / 60;
    const diffD = diffH / 24;

    if (diffD > 1) return returnMsg + Math.round(diffD) + " days ago";
    if (diffH > 1) return returnMsg + Math.round(diffH) + " hours ago";
    if (diffM > 1) return returnMsg + Math.round(diffM) + " minutes ago";

    return "few moments ago";
  }

  function deleteUser() {
    DataAction.item({
      dataType: "user",
      id: userName,
    })
      .delete({
        useConfirmation: true,
      })
      .then((res) => {
        history.push("/users");
      });
  }

  return (
    <PageLayout
      variant="small"
      title="User"
      navBarType="closable"
      returnTo="/users"
    >
      <DataItem dataType="user" id={userName}>
        {({ value: user }) =>
          !user ? (
            <div />
          ) : (
            <Form.Fragment
              dataItemProps={{
                dataType: "user",
                id: userName,
              }}
              confirmSubmit={(data) => { return UserConfirm.onSave(userName !== data.Email, userInfo.userName == userName)}}
              onValidate={(data) => {
                const errors = {};
                if (!data.Email)
                  errors.Email = "Email is required";

                return errors;
              }}
              defaultValue={{
                Enabled: true,
                AutoGenerate: true,
              }}
              submitAlign="center"
              renderFooterLeft={() =>
                userInfo.userName !== userName && (
                  <Button
                    variant="outlined"
                    onClick={() => deleteUser()}
                    style={{ marginLeft: 8 }}
                  >
                    Delete User
                  </Button>
                )
              }
              renderContent={() => (
                <React.Fragment>
                  <Grid container direction="column">
                    <Grid item className={classes.avatarContainer}>
                      <Form.Fields.LocalFile
                        name="ProfileImage"
                        preview
                        label={null}
                        accept="image/*"
                        className={classes.avatar}
                      >
                        {(file) =>
                          getPageAvatar(
                            file,
                            getDisplayName(
                              user.FirstName,
                              user.LastName,
                              user.UserName
                            )
                          )
                        }
                      </Form.Fields.LocalFile>
                    </Grid>
                    <Grid item>
                      <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                      >
                        <Grid item>
                          <Icon
                            className="fa fa-envelope"
                            label="Send welcome email"
                            tooltipPlacement="right"
                            onClick={() => {
                              Modals.open({
                                type: Modals.TYPES.CONFIRMATION,
                                props: {
                                  title: "Send welcome email",
                                  message: `Are you sure you want to send welcome email to ${user.Email}?`,
                                  onConfirm: () => {
                                    return Utils.request({
                                      url:
                                        "/backend/users/sendwelcomeemail?userName=" +
                                        encodeURIComponent(user.UserName),
                                      method: "POST",
                                    });
                                  },
                                },
                              });
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item className={classes.avatarContainer}>
                      <Typography
                        color="inherit"
                        style={{
                          textAlign: "center",
                        }}
                      >
                        {!user ? null : getDateDiff(user.LastLogIn)}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Grid container {...formGridProps}>
                        <Grid item sm={4} xs={12}>
                          <FormLabel htmlFor="Email" required>
                            Email
                          </FormLabel>
                        </Grid>
                        <Grid item sm xs>
                          <Form.Fields.Email
                            name="Email"
                            id="email"
                            label={null}
                            required
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container {...formGridProps}>
                        <Grid item sm={4} xs={12}>
                          <FormLabel htmlFor="FirstName" disabled={ssoEnabled}>
                            First Name
                          </FormLabel>
                        </Grid>
                        <Tooltip
                          placement="right"
                          title={
                            ssoEnabled
                              ? "First Name cannot be changed because SSO is enabled."
                              : ""
                          }
                        >
                          <Grid item sm xs>
                            <Form.Fields.Name
                              name="FirstName"
                              id="firstName"
                              label={null}
                              disabled={ssoEnabled}
                              type="firstName"
                            />
                          </Grid>
                        </Tooltip>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container {...formGridProps}>
                        <Grid item sm={4} xs={12}>
                          <FormLabel htmlFor="LastName" disabled={ssoEnabled}>
                            Last Name
                          </FormLabel>
                        </Grid>
                        <Tooltip
                          placement="right"
                          title={
                            ssoEnabled
                              ? "Last Name cannot be changed because SSO is enabled."
                              : ""
                          }
                        >
                          <Grid item sm xs>
                            <Form.Fields.Name
                              name="LastName"
                              id="lastName"
                              label={null}
                              disabled={ssoEnabled}
                              type="lastName"
                            />
                          </Grid>
                        </Tooltip>
                      </Grid>
                    </Grid>

                    <Grid item>
                      <Grid container {...formGridProps}>
                        <Grid item sm={4} xs={12}>
                          <FormLabel htmlFor="Groups">Groups</FormLabel>
                        </Grid>
                        <Grid item xs>
                          <FormaterDataList
                            id={userName}
                            maxCount={3}
                            render={renderGroupItem}
                            renderMore={renderMoreGroupItems}
                            dataType="userGroups"
                            dataProps={{
                              userName: userName,
                            }}
                            actionLabel="Manage Groups"
                            actionClick={() =>
                              history.push("/user/" + userName + "/edit/groups")
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Form.Fields.Checkbox
                        name="Enabled"
                        label="Enabled"
                        disabled={!user.Email || userInfo.email === user.Email}
                      />
                    </Grid>
                  </Grid>
                </React.Fragment>
              )}
              onSuccess={(data) => {

                //if the current user has changed their username
                if(userInfo.userName === userName && userName !== data.Email){
                  logoutUser();
                }else{
                history.push("/users");
                }
              }}
              onCancel={() => { }}
            />
          )
        }
      </DataItem>
    </PageLayout>
  );
};

export default withStyles(styles)(withUserInfo(withAuth(withRouter(EditUser))));

