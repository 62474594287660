//@ts-check

/**@type {import("theme/types").PartialEcrionTheme} */
const theme = {
  variant: "microservice",
  common: {
    Page: {
      Common: {
        style: {
          backgroundColor: "#FFF",
        },
        props: {
          copyright: true,
          reloadBackendInfo: true,
        },
      },
    },
    Form: {
      Fragment: {
        props: {
          variant: "filled",
        },
      },
    },
  },
  palette: {
    type: "dark",
  },
  overrides: {
    MuiFilledInput: {
      input: {
        "&:-webkit-autofill": {
          WebkitBoxShadow: "0 0 0 100px white inset",
          WebkitTextFillColor: "#000000",
        },
      },
    },
    MuiButton: {
      root: {
        minWidth: 120,
      },
      outlined: {
        border: "1px solid rgba(0, 0, 0, 0.23)",
      },
    },
    MuiIcon: {
      root: {
        fontSize: "0.75em",
        minWidth: "26px",
      },
    },
    MuiAvatar: {
      root: {
        height: "32px",
        width: "32px",
      },
    },
  },
  props: {
    MuiFormControl: {
      variant: "filled",
    },
    MuiInputLabel: {
      variant: "filled",
      shrink: false,
    },
    MuiTextField: {
      variant: "filled",
    },
    MuiButton: {
      color: "primary",
    },
  },
};

export default theme;
