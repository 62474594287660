//@ts-check
import React from 'react';
import { Grid, Typography, List, LinearProgress } from '@material-ui/core';
import { Icon, DataFilter, DataAction, withUserInfo } from 'ecrion-components';
import Item from '../components/Item';
import PageLayout from '../components/PageLayout';
import ProgressiveDataList from '../components/ProgressiveDataList';
import PageAvatar from '../components/PageAvatar';
import SubTitle from '../components/SubTitle';
import { getDisplayName } from '../utils/getDisplayName';

const buttonProps = {
  hoverable: true,
};

const EditGroupUsers = ({ history, location, match, userInfo }) => {
  let groupName =
    match && match.params && match.params.id ? match.params.id : null;

  function renderHeader() {
    return (
      <SubTitle>
        <PageAvatar src="" type="icon">
          group
        </PageAvatar>
        <Typography
          style={{
            marginTop: 8,
            textAlign: 'center',
          }}
          color="inherit"
        >
          {groupName}
        </Typography>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          style={{ marginTop: '8px' }}
        >
          <Grid item>
            <DataFilter
              history={history}
              location={location}
              searchIn="query"
            />
          </Grid>
          <Grid item>
            <div
              style={{
                border: '1px solid',
                height: '100%',
                marginLeft: 8,
                minHeight: 8 * 4,
              }}
            />
          </Grid>
          <Grid item>
            <Icon
              type="add"
              onClick={() => history.push('/group/' + groupName + '/users/add')}
              {...buttonProps}
            />
          </Grid>
        </Grid>
      </SubTitle>
    );
  }

  function removeUser(id) {
    DataAction.item({
      dataType: 'groupUsers',
      id: id,
      dataProps: {
        groupName: groupName,
      },
    }).delete({
      useConfirmation: true,
      message:
        'Are you sure you want to remove user ' +
        id +
        ' from group ' +
        groupName +
        '?',
    });
  }

  return (
    <PageLayout
      variant="small"
      title="Group Users"
      navBarType="closable"
      returnTo={`/group/${groupName}`}
    >
      {renderHeader()}
      <Grid container direction="column" justify="center">
        <Grid item>
          <ProgressiveDataList
            key={'groupUsers-' + groupName + '-' + location.search}
            dataType="groupUsers"
            pageStart={0}
            dataProps={{
              filter: DataFilter.createFromLocation(location),
              groupName: groupName,
            }}
          >
            {({ state }) => {
              return (
                <div>
                  {state.data.length === 0 ? (
                    state.view.isLoading ? (
                      <LinearProgress />
                    ) : (
                        <div style={{ textAlign: 'center' }}>
                          <Typography color="inherit">No users.</Typography>
                        </div>
                      )
                  ) : (
                      <List>
                        {state.data.map(
                          user =>
                            typeof user !== 'undefined' && (
                              <Item
                                tooltip={user.Email}
                                key={user.UserName}
                                label={
                                  user.DisplayName
                                    ? user.DisplayName
                                    : user.UserName
                                }
                                iconURL={user.ProfileImage}
                                letterAvatar={
                                  !user.ProfileImage &&
                                  getDisplayName(
                                    user.FirstName,
                                    user.LastName,
                                    user.UserName
                                  )
                                }
                                onRemove={{
                                  click: () => removeUser(user.UserName),
                                  disabled: groupName === 'Everyone' || (groupName === 'Administrators' && userInfo.userName === user.UserName),
                                }}
                              />
                            )
                        )}
                      </List>
                    )}
                  {state.view.isLoading && <LinearProgress />}
                </div>
              );
            }}
          </ProgressiveDataList>
        </Grid>
      </Grid>
    </PageLayout>
  );
};

export default withUserInfo(EditGroupUsers);
