//@ts-check
import React from 'react';
import { withRouter } from 'react-router-dom';
import { Grid, Typography, useMediaQuery } from '@material-ui/core';
import PageAvatar from './PageAvatar';
import Button from './Button';
import { DataItem, Icon } from 'ecrion-components';
import SubTitle from './SubTitle';
import dompurify from 'dompurify';

const sanitizer = dompurify.sanitize;

const AppInfo = ({ history, appId }) => {
  function renderContent(app) {
    if (!app) return <div />;

    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const contentPadding = isSmallScreen ? 8 * 6 : 0;

    return (
      <div style={{ paddingLeft: contentPadding, paddingRight: contentPadding }}>
        <SubTitle>
          <PageAvatar src={app.MicroserviceAppManifest.IconURL} type="icon">
            {app.MicroserviceAppManifest.Name}
          </PageAvatar>
        </SubTitle>

        {renderAppInfo(app.MicroserviceAppManifest)}
        <div style={{ marginTop: 8 * 4 }}>
          {renderActions(app.MicroserviceAppManifest)}
        </div>
      </div>
    );
  }

  function renderAppInfo(manifest) {
    return (
      <Typography
        color="inherit"
        dangerouslySetInnerHTML={{
          __html: sanitizer(manifest.Description),
        }}
      />
    );
  }

  function getState(manifest, state) {
    return manifest.States.find(
      (s) => s.Type.toLowerCase() === state.toLowerCase()
    );
  }

  function renderActions(manifest) {
    const unavailableType = getState(manifest, 'unavailable');
    if (!unavailableType) return <div />;

    return (
      <div>
        <Typography
          color="inherit"
          dangerouslySetInnerHTML={{
            __html: sanitizer(unavailableType.Description),
          }}
        />
        <Grid
          container
          justify="center"
          spacing={2}
          style={{ marginTop: 8 * 6, marginBottom: 8 * 2 }}
        >
          {unavailableType.Actions.map((a) => {
            return (
              <Grid key={a.Type} item>
                {renderAction(a)}
              </Grid>
            );
          })}
        </Grid>
      </div>
    );
  }

  function renderAction(action) {
    switch (action.Type.toLowerCase()) {
      case 'link':
        return (
          <Button
            variant="outlined"
            onClick={() => window.open(action.Url, '_blank')}
            style={{ marginRight: 8 }}
          >
            {action.Caption}
          </Button>
        );
      case 'getitnow':
        return (
          <Button
            color="primary"
            variant="contained"
            style={{ marginRight: 8 }}
            onClick={() => history.push('/app/' + appId + '/install')}
          >
            Get It Now >
          </Button>
        );
      case 'download':
        return (
          <Button
            onClick={() => window.open(action.Url, '_blank')}
            style={{ marginRight: 8 }}
          >
            <Icon
              style={{ fontSize: '16px', marginRight: 8 }}
              type="download"
            />
            {action.Caption}
          </Button>
        );
      default:
        break;
    }
  }

  return (
    <DataItem dataType="availableMicroserviceApp" id={appId}>
      {({ value }) => renderContent(value)}
    </DataItem>
  );
};

export default withRouter(AppInfo);
