//@ts-check

/**
 * @typedef { object } ModuleCategoryModel Data model returned by the sever representing a Microservice Category
 * @property { string } Name Microservice category name
 * @property { int } Order Microservice category order
 * @property { int } Columns Microservice category Columns
 * */

/**@type {import('data/types').DataType<ModuleCategoryModel>} */
const APP_DATA_TYPE = {
  data: {
    url: "/backend/availablemodulecategory",
    createItemUrl:
      "/backend/availablemodulecategory?categoryName={categoryName}",
    itemUrl: "/backend/availablemodulecategory?categoryName={id}",
    dataKey: "modulecategory",
    idKeyProp: "Name",
    filterUrlKey: "name",

    onBeforeRequest: (config, request) => {
      let url = request.url;

      if (request.type === "createItem") {
        url = url
          .split("{categoryName}")
          .join(encodeURIComponent(request.data.Name));
      }

      return {
        url
      };
    }
  },

  placeholders: {
    label: data => data.Name,
    type: () => "category",
    icon: () => "availablemodulecategory"
  }
};

export default APP_DATA_TYPE;
