//@ts-check

const APP_DATA_TYPE = {
  data: {
    url: "/backend/environmentLicensing/users?keyId={keyId}",
    dataKey: "licensedUsers",
    idKeyProp: "UserName",
    onBeforeRequest: (config, request) => {
      if (!config.dataProps) return;
      if (request.type === "loadView") {
        let {
          dataProps: { keyId }
        } = config;

        let url = request.url;
        url = url.split("{keyId}").join(keyId);
        return {
          url
        };
      }

      return;
    }
  },

  placeholders: {
    label: data => data.UserName,
    type: () => "license",
    icon: () => "user",
    delete: () => "remove",
    deleting: () => "removing"
  }
};

export default APP_DATA_TYPE;
