//@ts-check

const APP_DATA_TYPE = {
  data: {
    url:
      '/backend/availableProductKeys?accountToken={accountToken}&componentType={component}',
    idKeyProp: 'Key',
    dataKey: 'availableProductKeys',
    onBeforeRequest: (config, request) => {
      if (!config.dataProps) return;
      if (request.type === 'loadView') {
        let {
          dataProps: { component, accountToken },
        } = config;

        let url = request.url;
        url = url.split('{component}').join(component);
        url = url.split('{accountToken}').join(accountToken);
        return {
          url,
        };
      }

      return;
    },
  },

  placeholders: {
    label: data => data.Key,
    type: () => 'category',
    icon: () => 'availableProductKeys',
  },
};

export default APP_DATA_TYPE;
