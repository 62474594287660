//@ts-check

import React from 'react';
import { Grid, Typography, FormLabel } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import PageLayout from '../components/PageLayout';
import PageAvatar from '../components/PageAvatar';
import { Form } from 'ecrion-components';
import SubTitle from '../components/SubTitle';

const formGridProps = {
  alignItems: 'center',
};

const EditGroupWorkspacesAdd = ({ history, match }) => {
  let groupName =
    match && match.params && match.params.id ? match.params.id : null;

  function renderHeader() {
    return (
      <SubTitle>
        <PageAvatar src="" type="icon">
          group
        </PageAvatar>
        <Typography
          style={{
            marginTop: 8,
            textAlign: 'center',
          }}
          color="inherit"
        >
          {groupName}
        </Typography>
      </SubTitle>
    );
  }

  return (
    <PageLayout
      variant="small"
      title="Add workspace to group"
      navBarType="closable"
      returnTo={`/group/${groupName}/workspaces`}
    >
      <Form.Fragment
        dataItemProps={{
          dataType: 'groupWorkspaces',
          dataProps: {
            AccessPermission: true,
          },
        }}
        onValidate={data => {
          const errors = {};
          if (!data.WorkspaceName)
            errors.WorkspaceName = 'Workspace is required';

          return errors;
        }}
        submitLabel="Add"
        submittingLabel="Adding..."
        defaultValue={{
          groupName: groupName,
        }}
        renderContent={() => (
          <React.Fragment>
            {renderHeader()}
            <Grid container direction="column">
              <Grid item>
                <Grid container {...formGridProps}>
                  <Grid item sm={4} xs={12}>
                    <FormLabel htmlFor="WorkspaceName" required>
                      Workspace
                    </FormLabel>
                  </Grid>
                  <Grid item sm xs>
                    <Form.Fields.DataSelect
                      name="WorkspaceName"
                      dataType="groupWorkspaces"
                      id="groups"
                      label={null}
                      dataProps={{
                        include: false,
                        groupName: groupName,
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </React.Fragment>
        )}
        onSuccess={() => {
          history.push('/group/' + groupName + '/workspaces');
        }}
        onCancel={() => { }}
      />
    </PageLayout>
  );
};

export default withRouter(EditGroupWorkspacesAdd);
