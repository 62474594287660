//@ts-check

/**
 * @typedef { object } ModuleCategoryModel Data model returned by the sever representing a Microservice Category
 * @property { string } Name Microservice category name
 * */

/**@type {import('data/types').DataType<ModuleCategoryModel>} */
const APP_DATA_TYPE = {
  data: {
    url: "/backend/modulecategory",
    dataKey: "modulecategory",
    idKeyProp: "Name"
  },
  placeholders: {
    label: data => data.Name,
    type: () => "category",
    icon: () => "category"
  }
};

export default APP_DATA_TYPE;
