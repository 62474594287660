//@ts-check
import React from "react";
import { withRouter } from "react-router-dom";
import { Grid, FormLabel, Chip } from "@material-ui/core";
import PageLayout from "../../../../components/PageLayout";
import PageAvatar from "../../../../components/PageAvatar";
import SubTitle from "../../../../components/SubTitle";
import { Form, withUserInfo, Icon } from "ecrion-components";

const formGridProps = {
  alignItems: "center",
};

const GrantAccessPage = ({ history, email, back }) => {
  return (
    <PageLayout
      variant="small"
      title="Grant User Access"
      navBarType="closable"
      returnTo="/users"
    >
      <div style={{ textAlign: "center" }}>
        <SubTitle>
          <PageAvatar type="icon">user</PageAvatar>
          <Chip
            style={{ marginTop: 8 }}
            icon={
              <Icon
                className="fa fa-user-circle"
                style={{ width: "22px", height: "22px", fontSize: "22px" }}
              />
            }
            label={email}
            onDelete={back}
          />
        </SubTitle>
      </div>
      <Form.Fragment
        dataItemProps={{
          dataType: "user",
        }}
        defaultValue={{
          Email: email,
          Enabled: true,
          AutoGenerate: true,
          SendEmailInvitation: true,
          Groups: ["Everyone"],
          Invite: true,
        }}
        renderContent={() => (
          <React.Fragment>
            <Grid container direction="column">
              <Grid item>
                <Grid container {...formGridProps}>
                  <Grid item sm={4} xs={12}>
                    <FormLabel htmlFor="Groups">Groups</FormLabel>
                  </Grid>
                  <Grid item xs>
                    <Form.Fields.DataSelect
                      name="Groups"
                      multi
                      label={null}
                      dataType="group"
                      id="groups"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Form.Fields.Checkbox name="Enabled" label="Enabled" />
              </Grid>
              <Grid item>
                <Form.Fields.Checkbox
                  name="SendEmailInvitation"
                  label="Send an email invitation"
                  disabled
                />
              </Grid>
            </Grid>
          </React.Fragment>
        )}
        onSuccess={() => {
          history.push("/users");
        }}
        submitLabel="Grant"
        onCancel={() => {}}
      />
    </PageLayout>
  );
};

export default withUserInfo(withRouter(GrantAccessPage));
