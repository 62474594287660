//@ts-check
import React from "react";
import PageLayout from "../components/PageLayout";
import { DataItem, DataAction } from "ecrion-components";
import { Typography, Grid, List } from "@material-ui/core";
import Item from "../components/Item";
import { Icon } from "ecrion-components";
import PageAvatar from "../components/PageAvatar";
import SubTitle from "../components/SubTitle";
import ProgressiveDataList from "../components/ProgressiveDataList";

const AppEditAccessRights = ({ match, history }) => {
  const appId =
    match && match.params && match.params.id ? match.params.id : null;

  function renderHeader(app) {
    if (!app) return <div />;

    return (
      <SubTitle>
        <PageAvatar src={app.MicroserviceAppManifest.IconURL} type="icon">
          {app.MicroserviceAppManifest.Name}
        </PageAvatar>
        <Typography
          style={{
            marginTop: 8,
            textAlign: "center"
          }}
          color="inherit"
        >
          {app.MicroserviceAppManifest.Name}
        </Typography>
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item>
            <Icon
              type="add"
              hoverable
              onClick={() =>
                history.push("/app/" + appId + "/edit/accessRights/add")
              }
            />
          </Grid>
        </Grid>
      </SubTitle>
    );
  }

  function renderContent(value) {
    if (!value) return <div />;

    return (
      <Grid container direction="column" justify="center">
        <Grid item>
          <ProgressiveDataList
            id={appId + "principals"}
            dataType="microserviceAppPrincipal"
            dataProps={{
              appId: appId
            }}
          >
            {({ state }) =>
              state.data.length === 0 ? (
                <Typography color="inherit" style={{ textAlign: "center" }}>
                  <i>No access rights defined</i>
                </Typography>
              ) : (
                <List>
                  {state.data.map(principal => (
                    <Item
                      key={principal.Id}
                      label={principal.DisplayName}
                      iconType={principal.PrincipalType}
                      onRemove={{
                        click: () => removeAccessRight(principal.Id)
                      }}
                    />
                  ))}
                </List>
              )
            }
          </ProgressiveDataList>
        </Grid>
      </Grid>
    );
  }

  function removeAccessRight(id) {
    DataAction.item({
      dataType: "microserviceAppPrincipal",
      id: id,
      dataProps: {
        appId: appId
      }
    }).delete({
      useConfirmation: true
    });
  }

  return (
    <PageLayout
      title="Access Rights"
      variant="small"
      navBarType="closable"
      returnTo={`/apps`}
    >
      <DataItem dataType="microserviceApp" id={appId}>
        {({ value }) => {
          return (
            <div>
              {renderHeader(value)}
              {renderContent(value)}
            </div>
          );
        }}
      </DataItem>
    </PageLayout>
  );
};

export default AppEditAccessRights;
