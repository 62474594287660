import React from 'react';
import { useAccountsAuth } from '../context/AccountsContext';
import { Redirect } from 'react-router-dom';
import { Utils } from 'ecrion-components';

const AccountsCallback = props => {
  const { handleAuthentication } = useAccountsAuth();

  handleAuthentication();

  const query = Utils.url.parseUrl(props.location.search).query;

  return <Redirect to={query.routePath || '/'} />;
};

export default AccountsCallback;
