import React, { useState } from "react";
import { TextField, InputAdornment } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Icon } from "ecrion-components";

const useStyles = makeStyles((theme) => ({
  icon: {
    width: "22px",
  },
}));

const PasswordTextField = ({ onChange, disabled }) => {
  const [showPassword, setShowPassword] = useState(false);
  const classes = useStyles();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <TextField
      required
      id="password"
      label="Password"
      type={showPassword ? "text" : "password"}
      autoComplete="current-password"
      fullWidth
      onChange={onChange}
      disabled={disabled}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Icon
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              className={
                showPassword
                  ? `fa fa-eye ${classes.icon}`
                  : `fa fa-eye-slash  ${classes.icon}`
              }
            />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default PasswordTextField;
