//@ts-check
/**
 * @typedef { object } Group
 * @property { number } URL
 * @property { string } Type

/**@type {import('data/types').DataType<GroupModel>} */
const APP_DATA_TYPE = {
  data: {
    url: '/backend/ssourl',
    createItemUrl: '/backend/ssourl',
    dataKey: 'sso',
    idKeyProp: 'EnvironmentName',
  },
  placeholders: {
    label: data => data.URL,
    type: () => 'sso',
    icon: () => 'sso',
  },
};

export default APP_DATA_TYPE;
