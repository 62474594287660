//@ts-check

/**
 * @typedef { object } MicroserviceAppManifest
 * @property { string } Id App's Id
 * @property { string } Name App's name
 * @property { string } IconURL App's icon URL
 * @property { string } HomeURL App's login URL
 * @property { string } Description App's description
 * @property { string } Color App's color
 * @property { 'Apps' | 'Digital Experiences' | 'Developer' | string } Category App's categroy
 * @property { 'FREE_TRIAL' | 'REQUEST_TRIAL' |  'NO_TRIAL' } Trial App's trial
 * @property { string } Version App's version
 * @property { MicroserviceAppManifestState[] } States App's states
 * @property { object[] } DefaultPrincipals Default principals
 *
 * @typedef { object } MicroserviceAppManifestState
 * @property { 'available' | 'unavailable' } Type State's type e.g. available, unavailable
 * @property { string } Description State's description
 * @property { MicroserviceAppManifestStateAction[] } Actions State's actions
 *
 * @typedef { object } MicroserviceAppManifestStateAction
 * @property { 'GetItNow' | 'Remove' | 'Link'  } Type Action's type e.g. remove, getitnow, link
 * @property { string } Caption Action's caption
 * @property { string } Url Action's url
 *
 * @typedef { object } MicroserviceAppInstallation
 * @property { number } Id Unique identifier
 * @property { boolean } Trial
 * @property { 'INSTALLED' | 'INSTALLED_TRIAL' | 'NOT_INSTALLED_FREE_TRIAL' | 'NOT_INSTALLED_REQUEST_TRIAL' } Installation
 * @property { string } Category
 * @property { groups[] } Groups
 *
 * @typedef { object } MicroserviceAppModel Data model returned by the sever representing a Microservices App
 * @property { MicroserviceAppManifest } Manifest Microservice manifest
 * @property { MicroserviceAppInstallation } Installation Microservice status
 * */

/**@type {import('data/types').DataType<MicroserviceAppModel>} */

const APP_DATA_TYPE = {
  data: {
    url: "/backend/microservices2?category={category}",
    createItemUrl: "/backend/microservices2",
    itemUrl: "/backend/microservices2?appId={id}",
    dataKey: "microservices",
    idKeyProp: "MicroserviceAppInstallation.Id",
    onBeforeRequest: (config, request) => {
      if (!config.dataProps) return;

      if (request.type !== "loadView") return;

      let {
        dataProps: { category },
      } = config;

      let url = request.url;

      url = url.split("{category}").join(encodeURIComponent(category));

      return {
        url,
      };
    },
  },
  placeholders: {
    label: (data) =>
      data && data.MicroserviceAppManifest
        ? data.MicroserviceAppManifest.Name
        : "",
    type: (data) => data && data.MicroserviceAppManifest
      ? data.MicroserviceAppManifest.Name
      : "",
    icon: () => "app",
    name: (data) => data.MicroserviceAppInstallation.Name,
    delete: () => "uninstall",
    deleting: () => "uninstalling",
  },
};

export default APP_DATA_TYPE;
