import React from "react";
import PrivateRoute from "./PrivateRoute";
import { Route, Switch } from "react-router-dom";
import Home from "../pages/Home";
import Apps from "../pages/Apps";
import App from "../pages/App";
import AppInstall from "../pages/AppInstall";
import AppEdit from "../pages/AppEdit";
import AppEditAccessRights from "../pages/AppEditAccessRights";
import AppEditAccessRightsAdd from "../pages/AppEditAccessRightsAdd";
import AppGetStarted from "../pages/AppGetStarted";
import Account from "../pages/Account";
import Users from "../pages/Users";
import User from "../pages/User";
import Groups from "../pages/Groups";
import Group from "../pages/Group";
import EditGroupUsers from "../pages/EditGroupUsers";
import EditGroupUsersAdd from "../pages/EditGroupUsersAdd";
import Organization from "../pages/Organization";
import SSO from "../pages/SSO";
import Workspaces from "../pages/Workspaces";
import Workspace from "../pages/Workspace";
import GroupWorkspaces from "../pages/GroupWorkspaces";
import EditGroupWorkspacesAdd from "../pages/EditGroupWorkspacesAdd";
import UserGroups from "../pages/UserGroups";
import UserGroupsAdd from "../pages/UserGroupsAdd";
import NotFound from "../pages/NotFound";
import Categories from "../pages/Categories";
import Category from "../pages/Category";
import AppLicensing from "../pages/AppLicensing";
import Licensing from "../pages/Licensing";
import AccountsAuthCallback from "../pages/AccountsAuthCallback";

const Routes = () => {
  return (
    <Switch>
      <PrivateRoute exact path="/" component={Home} />
      <PrivateRoute exact path="/apps" component={Apps} />
      <PrivateRoute exact path="/app/:id" component={App} />
      <PrivateRoute exact path="/app/:id/install" component={AppInstall} />
      <PrivateRoute exact path="/app/:id/edit" component={AppEdit} />
      <PrivateRoute
        exact
        path="/app/:id/edit/accessrights"
        component={AppEditAccessRights}
      />
      <PrivateRoute
        exact
        path="/app/:id/edit/accessrights/add"
        component={AppEditAccessRightsAdd}
      />
      <PrivateRoute
        exact
        path="/app/:id/getstarted"
        component={AppGetStarted}
      />
      <PrivateRoute exact path="/app/:id/licensing" component={AppLicensing} />
      <PrivateRoute
        exact
        path="/app/:id/licensing/add"
        component={AppLicensing}
      />
      <PrivateRoute exact path="/account" component={Account} />
      <PrivateRoute exact path="/users" component={Users} />
      <PrivateRoute exact path={["/user", "/user/:id"]} component={User} />
      <PrivateRoute exact path="/categories" component={Categories} />
      <PrivateRoute
        exact
        path={["/category", "/category/:id"]}
        component={Category}
      />
      <PrivateRoute exact path="/groups" component={Groups} />
      <PrivateRoute exact path={["/group", "/group/:id"]} component={Group} />
      <PrivateRoute exact path="/group/:id/users" component={EditGroupUsers} />
      <PrivateRoute
        exact
        path="/group/:id/users/add"
        component={EditGroupUsersAdd}
      />
      <PrivateRoute
        route={PrivateRoute}
        exact
        path="/organization"
        component={Organization}
      />
      <PrivateRoute
        route={PrivateRoute}
        exact
        path={["/licensing", "/licensing/add", "/licensing/:id/users"]}
        component={Licensing}
      />

      <PrivateRoute route={PrivateRoute} exact path="/sso" component={SSO} />
      <PrivateRoute
        route={PrivateRoute}
        exact
        path="/workspaces"
        component={Workspaces}
      />
      <PrivateRoute exact path={["/sso", "/sso/:id"]} component={SSO} />
      <PrivateRoute
        exact
        path="/group/:id/workspaces"
        component={GroupWorkspaces}
      />
      <PrivateRoute
        exact
        path={["/workspace", "/workspace/:id"]}
        component={Workspace}
      />
      <PrivateRoute
        exact
        path="/group/:id/workspaces/add"
        component={EditGroupWorkspacesAdd}
      />
      <PrivateRoute exact path="/user/:id/edit/groups" component={UserGroups} />
      <PrivateRoute
        exact
        path="/user/:id/groups/add"
        component={UserGroupsAdd}
      />
      <PrivateRoute
        exact
        path="/accounts-auth-callback"
        component={AccountsAuthCallback}
      />
      <Route component={NotFound} />
    </Switch>
  );
};

export default Routes;
