//@ts-check
import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import { DataList } from "ecrion-components";
import PropTypes from "prop-types";

const ProgressiveDataList = ({
  dataType,
  useWindow,
  children,
  getScrollParent,
  pageStart,
  ...rest
}) => {
  const [page, setPage] = useState(pageStart);
  const [isFetching, setIsFetching] = useState(false);
  const containerRef = useRef(null);
  const regex = /(auto|scroll)/;

  useEffect(() => {
    const scrollElement = getScrollElement();

    if (scrollElement) scrollElement.addEventListener("scroll", handleScroll);
    return () => {
      if (scrollElement)
        scrollElement.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (!isFetching) return;
    setPage(page + 1);
    setIsFetching(false);
  }, [isFetching]);

  function getScrollElement() {
    if (useWindow) return window;
    if (getScrollParent) return getScrollParent();

    return getParent();
  }

  function handleScroll() {
    const scrollElement = getScrollElement();

    if (
      scrollElement.scrollHeight - scrollElement.scrollTop ===
      scrollElement.clientHeight
    )
      setPage(page + 1);
  }

  function style(node, prop) {
    return getComputedStyle(node, null).getPropertyValue(prop);
  }

  function scroll(node) {
    return regex.test(style(node, "overflow") + style(node, "overflow-y"));
  }

  function scrollparent(node) {
    return !node || node === document.body
      ? document.body
      : scroll(node)
      ? node
      : scrollparent(node.parentNode);
  }

  function getParent() {
    return containerRef ? scrollparent(containerRef.current) : null;
  }

  let dataLists = [];
  for (let i = 0; i < page + 1; i++) {
    const localPage = i;

    // note: we add a key prop here to allow react to uniquely identify each
    // element in this array.
    dataLists.push(
      <div key={localPage} style={{ marginTop: "-8px", marginBottom: "-8px" }}>
        <DataList
          {...rest}
          id={"progresive-" + dataType + "-" + localPage}
          page={localPage}
          dataType={dataType}
        >
          {children}
        </DataList>
      </div>
    );
  }

  return <div ref={containerRef}>{dataLists}</div>;
};

ProgressiveDataList.defaultProps = {
  useWindow: false,
  pageStart: 0,
  itemsPerPage: 20
};

ProgressiveDataList.propTypes = {
  /** Add scroll listeners to the window, or else, the component's parentNode. */
  useWindow: PropTypes.bool.isRequired,

  /** The number of the first page to load, With the default of 0, the first page is 1. */
  pageStart: PropTypes.number.isRequired,

  /** Override method to return a different scroll listener if it's not the immediate parent of InfiniteScroll. */
  getScrollParent: PropTypes.func
};

export default withRouter(ProgressiveDataList);
