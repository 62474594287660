//@ts-check
import React from "react";
import { withRouter } from "react-router-dom";
import { withUserInfo } from "ecrion-components";
import EditGroup from "./subpages/EditGroup";
import NewGroup from "./subpages/NewGroup";

const Group = ({ history, userInfo, match }) => {
  let userName =
    match && match.params && match.params.id ? match.params.id : null;

  return !userName ? (
    <NewGroup history={history} userInfo={userInfo} />
  ) : (
    <EditGroup history={history} userInfo={userInfo} match={match} />
  );
};

export default withUserInfo(withRouter(Group));
