import React, { useState } from "react";
import { ModalWindow, Form, withUserInfo, Utils } from "ecrion-components";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Grid, TextField, Typography, FormLabel } from "@material-ui/core";
import PasswordTextField from "../PasswordTextField";
import Rating from "../Rating";

const useStyles = makeStyles((theme) =>
  createStyles({
    content: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  })
);

const ContentForm = ({
  handleSubmit,
  error,
  oldPassword,
  setOldPassword,
  newPassword,
  setNewPassword,
  submitting,
}) => {
  function getPasswordText(password) {
    if (password.length < 6)
      return "Password must have at least 6 characters";

    const score = scorePassword(password);
    if (score === 1) return "Weak";
    else if (score === 2) return "Fair";
    else if (score === 3) return "Good";
    else if (score === 4) return "Strong";
    else if (score > 4) return "Very Strong";

    return "Password must have at least 6 characters";
  }

  function scorePassword(pass) {
    let score = 0;
    if (!pass || pass.length < 6) return score;

    // award every unique letter until 5 repetitions
    let letters = {};
    for (var i = 0; i < pass.length; i++) {
      letters[pass[i]] = (letters[pass[i]] || 0) + 1;
      score += 5.0 / letters[pass[i]];
    }

    // bonus points for mixing it up
    const variations = {
      digits: /\d/.test(pass),
      lower: /[a-z]/.test(pass),
      upper: /[A-Z]/.test(pass),
      nonWords: /\W/.test(pass),
    };

    let variationCount = 0;
    for (let check in variations) {
      variationCount += variations[check] === true ? 1 : 0;
    }
    score += (variationCount - 1) * 10;

    return Math.ceil(score / 20);
  }

  return (
    <Grid container direction="column" spacing={2}>
      {error && (
        <Grid item>
          <Form.Error>Could not change password. {error}</Form.Error>
        </Grid>
      )}
      <Grid item>
        <FormLabel htmlFor="oldPassword" required>
          Old Password
        </FormLabel>
      </Grid>
      <Grid item>
        <TextField
          required
          id="oldPassword"
          label="Old Password"
          type="password"
          fullWidth
          onChange={(e) => setOldPassword(e.target.value)}
          disabled={submitting}
        />
      </Grid>
      <Grid item>
        <FormLabel htmlFor="newPassword" required>
          New Password
        </FormLabel>
      </Grid>
      <Grid item>
        <PasswordTextField
          disabled={submitting}
          onChange={(e) => setNewPassword(e.target.value)}
        />
      </Grid>
      <Grid item>
        <Rating score={scorePassword(newPassword)} />
        <Typography variant="caption" style={{ textAlign: "center" }}>
          {getPasswordText(newPassword)}
        </Typography>
      </Grid>
    </Grid>
  );
};

const ChangePassword = ({ onClose, userInfo }) => {
  const [error, setError] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const classes = useStyles({});

  function onFailChangePassword(error) {
    let errorMessage = "";
    if (error && error.message) errorMessage = error.message;
    if (error && error.response && error.response.body)
      errorMessage = error.response.body;

    if (errorMessage.Message) errorMessage = errorMessage.Message;

    setError(errorMessage);
    setSubmitting(false);

    throw errorMessage;
  }

  function onChangePassword() {
    onClose();
    setSubmitting(false);
  }

  function handleSubmit() {
    setSubmitting(true);
    return Utils.request({
      url: "/backend/users",
      method: "PUT",
      data: {
        OldUserName: userInfo.userName,
        Entity: {
          UserName: userInfo.userName,
          OldPassword: oldPassword,
          NewPassword: newPassword,
          ConfirmPassword: newPassword,
        },
      },
    }).then(onChangePassword, onFailChangePassword);
  }

  return (
    <ModalWindow
      onClose={onClose}
      header="Change Password"
      footerButtons={{
        submit: {
          children: "Change",
          onClick: () => handleSubmit(),
          disabled: (newPassword.length < 6),
        },
      }}
      content={
        <div className={classes.content}>
          <ContentForm
            handleSubmit={handleSubmit}
            oldPassword={oldPassword}
            setOldPassword={setOldPassword}
            newPassword={newPassword}
            setNewPassword={setNewPassword}
            error={error}
            submitting={submitting}
          />
        </div>
      }
    />
  );
};

export default withUserInfo(ChangePassword);
