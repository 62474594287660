//@ts-check
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { withUserInfo, Utils } from "ecrion-components";
import EditUser from "./Edit";
import NewUser from "./New";
import PageLayout from "../../components/PageLayout";
import Loading from "../../components/Loading";

const User = ({ match }) => {
  let userName =
    match && match.params && match.params.id ? match.params.id : null;

  const [loading, setLoading] = useState(true);
  const [ssoEnabled, setSSOEnabled] = useState(false);
  const [editProfileURL, setEditProfileURL] = useState(null);

  useEffect(() => {
    Utils.request({
      url: `/backend/ssourl`,
    }).then((sso) => {
      if (sso && sso.Type && sso.Type.toLowerCase() !== "enterprise") {
        setSSOEnabled(true);

        if (sso.EditProfileUrl) setEditProfileURL(sso.EditProfileUrl);
      }

      setLoading(false);
    });
  }, []);

  return !loading ? (
    !userName ? (
      <NewUser ssoEnabled={ssoEnabled} />
    ) : (
      <EditUser
        ssoEnabled={ssoEnabled}
        editProfileURL={editProfileURL}
        userName={userName}
      />
    )
  ) : (
    <PageLayout
      variant="small"
      title=""
      navBarType="closable"
      returnTo="/users"
    >
      <Loading />
    </PageLayout>
  );
};

export default withUserInfo(withRouter(User));
