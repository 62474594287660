//@ts-check
import React from 'react';
import { withRouter } from 'react-router-dom';
import { withUserInfo } from 'ecrion-components';
import EditCategory from './subpages/EditCategory';
import NewCategory from './subpages/NewCategory';

const Category = ({ history, match }) => {
  let categoryName =
    match && match.params && match.params.id ? match.params.id : null;

  return !categoryName ? (
    <NewCategory history={history} />
  ) : (
    <EditCategory history={history} match={match} />
  );
};

export default withUserInfo(withRouter(Category));
