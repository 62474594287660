//@ts-check
import React from "react";
import {
  Grid,
  Avatar,
  Typography,
  Button,
  Box,
  Tooltip,
} from "@material-ui/core";
import { withStyles, createStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { Icon } from "ecrion-components";

const styles = (theme) =>
  createStyles({
    appCaption: {
      textAlign: "center",
    },
    appCaptionText: {},
    appDescription: {
      minHeight: 65,
    },
    appAvatarRebranding: {
      borderRadius: "5%",
    },
  });

/**
 * @typedef {object} Props
 * @property {import('../data/microserviceApp').MicroserviceAppModel} app
 *
 **/
const App = ({ app, classes, history }) => {
  function navigateToApp(app) {
    if (!app.MicroserviceAppInstallation)
      history.push("/app/" + app.MicroserviceAppManifest.Id + "/getstarted");
    else history.push("/app/" + app.MicroserviceAppManifest.Id + "/edit");
  }

  return (
    <div>
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="center"
      >
        <Grid
          container
          item
          className={classes.appCaption}
          direction="row"
          justify="center"
          alignItems="center"
          spacing={1}
        >
          <Grid item className={classes.appCaptionPicture}>
            <Avatar
              className={classes.appAvatarRebranding}
              alt={app.MicroserviceAppManifest.Name}
              src={app.MicroserviceAppManifest.IconURL}
            />
          </Grid>
          <Grid item className={classes.appCaptionText}>
            <Typography color="inherit" display="inline">
              {app.MicroserviceAppManifest.Name}
            </Typography>
            {app.MicroserviceAppInstallation && (
              <Tooltip
                placement="right"
                title={
                  app.MicroserviceAppInstallation.Upgrade
                    ? `Update available, version ${app.MicroserviceAppInstallation.Upgrade.Version}. Currently installed ${app.MicroserviceAppInstallation.Version}.`
                    : "Installed"
                }
              >
                <Box ml={1} component={"span"}>
                  <Icon
                    className={
                      app.MicroserviceAppInstallation.Upgrade
                        ? "fa fa-arrow-circle-up"
                        : "fa fa-check-circle"
                    }
                    style={{
                      fontSize: "14px",
                      verticalAlign: "middle",
                      width: "1.1em",
                    }}
                  />
                </Box>
              </Tooltip>
            )}
          </Grid>
        </Grid>
        <Grid item style={{ paddingTop: 8 * 4, paddingBottom: 8 * 4 }}>
          <Typography
            color="inherit"
            className={classes.appDescription}
            dangerouslySetInnerHTML={{
              __html: app.MicroserviceAppManifest.Description,
            }}
          />
        </Grid>
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            style={{ minWidth: 130 }}
            onClick={() => navigateToApp(app)}
          >
            More >
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

App.defaultProps = {
  app: null,
};

export default withStyles(styles, { withTheme: true })(withRouter(App));
