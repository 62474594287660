//@ts-check
import React from "react";
import { Typography, Grid, CircularProgress } from "@material-ui/core";
import { DataList, Utils, withUserInfo } from "ecrion-components";
import PageLayout from "../components/PageLayout";
import Button from "../components/Button";
import Module from "../components/Module";
import AppAutoInstall from "../components/AppAutoInstall";

const pageProps = {
  title: "EngageCX",
  titleVariant: "h5",
  permissions: [],
};

const getMd = (m) => {
  if (m.AppsCount === 1) return 3;
  else if (m.AppsCount === 2 && m.Columns === 3) return 6;
  else return 3 * m.Columns;
};

const getColumnsCount = (m) => {
  if (m.AppsCount === 1) return 1;
  else if (m.AppsCount === 2 && m.Columns === 3) return 2;
  else return m.Columns;
};

const Home = ({ history, location, userInfo }) => {
  const searchParams = Utils.url.getSearchParams(location);
  const appName = searchParams["install-app"];

  const { permissions = [] } = userInfo;

  if (appName) {
    return (
      <PageLayout {...pageProps} variant="small">
        <AppAutoInstall appName={appName} history={history} />
      </PageLayout>
    );
  } else {
    return (
      <PageLayout {...pageProps}>
        <DataList
          id="moduleCategory"
          dataType="moduleCategory"
          itemsPerPage={999999}
        >
          {({ state }) =>
            state.view.isLoading ? (
              <div style={{ textAlign: "center" }}>
                <CircularProgress />
              </div>
            ) : state.data.length === 0 ? (
              <div style={{ textAlign: "center" }}>
                <div style={{ marginBottom: 8 * 4 }} />
                {permissions.includes("ManageEnvironment") ? (
                  <Typography color="inherit">
                    Welcome to EngageCX, you can install one or more apps.
                  </Typography>
                ) : (
                  <Typography color="inherit">
                    Welcome to EngageCX, contact your organization administrator
                    to install one or more apps.
                  </Typography>
                )}
                <div style={{ marginBottom: 8 * 4 }} />
                {permissions.includes("ManageEnvironment") && (
                  <Button
                    onClick={() =>
                      history.push("/apps" + history.location.search)
                    }
                  >
                    Explore Apps
                  </Button>
                )}
              </div>
            ) : (
              <Grid container justify="center" spacing={2}>
                {state.data.map((m) => (
                  <Grid key={m.Name} item xs={12} md={getMd(m)}>
                    <div
                      style={{
                        backgroundColor: "#FFF",
                        outline: "1px solid rgb(211, 211, 211)",
                        borderRadius: "10px",
                        height: "100%",
                      }}
                    >
                      <Module
                        moduleName={m.Name}
                        columnsCount={getColumnsCount(m)}
                      />
                    </div>
                  </Grid>
                ))}
              </Grid>
            )
          }
        </DataList>
      </PageLayout>
    );
  }
};

export default withUserInfo(Home);
