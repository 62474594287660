//@ts-check

import React from "react";
import { Switch } from "react-router-dom";
import Main from "./Main";
import AddLicense from "./AddLicense";
import License from "./License";
import PrivateRoute from "../../router/PrivateRoute";

const AppLicensing = ({ location }) => {
  return (
    <Switch>
      <PrivateRoute path="/licensing" exact component={Main} />
      <PrivateRoute path="/licensing/add" exact component={AddLicense} />
      <PrivateRoute path="/licensing/:id/users" exact component={License} />
    </Switch>
  );
};

export default AppLicensing;
