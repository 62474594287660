//@ts-check

import { FormLabel, Grid, Tooltip } from "@material-ui/core";
import { Form, Icon, Utils, withUserInfo, DataAction } from "ecrion-components";
import React, { useRef, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import Button from "../components/Button";
import PageAvatar from "../components/PageAvatar";
import PageLayout from "../components/PageLayout";
import SubTitle from "../components/SubTitle";
import { useSettings } from "../context/SettingsContext";

const formGridProps = {
  alignItems: "center",
  style: { minHeight: 62 },
};

const formMapProps = {
  minWidth: "auto",
  marginLeft: "auto",
  top: "-40px",
  right: "-40px",
  position: "relative",
};

const SSO = ({ history, userInfo }) => {
  const settings = useSettings();
  const [environmentId, setEnvironmentId] = useState(null);
  const [loading, setIsLoading] = useState(true);
  const ACSURLRef = useRef(null);

  function openTestPage() {
    if (settings != null) {
      window.open(
        settings.FormatedWebsiteURL +
          "/Security/Login?environment=" +
          userInfo.environmentName +
          "&RelayState=" +
          settings.FormatedWebsiteURL +
          "/home",
        "_blank"
      );
    }
  }

  function getACSURL(userInfo) {
    if (settings !== null) {
      return (
        settings.FormatedWebsiteURL +
        "/Security/ConsumeSAMLToken?environment=" +
        userInfo.environmentName
      );
    }
  }

  function validateForm(values) {
    const errors = {};
    if (values.Type == null) errors.Type = "Protocol is required";
    if (values.URL == null) errors.URL = "Login URL is required";
    if (values.LogOutURL == null) errors.LogOutURL = "Logout URL is required";
    if (values.X509Certificate == null)
      errors.X509Certificate = "Certificate is required";
    return errors;
  }

  function validateMappings(values) {
    const errors = {};

    if (values != null && Array.isArray(values)) {
      let mappingsDoneCount = 0;
      let keys = values.map((val) => val != null && val.EOSField);

      values.forEach((element) => {
        let input = element.SSOField;
        let select = element.EOSField;
        if (input != null && select != null) {
          if (
            (input.length !== 0 && select.length !== 0) ||
            (input.length === 0 && select.length === 0)
          )
            ++mappingsDoneCount;
        }
      });

      if (
        mappingsDoneCount !== values.length ||
        new Set(keys).size !== keys.length
      )
        errors.Mappings = "Mappings are not correct.";
    }

    return errors;
  }

  useEffect(() => {
    Utils.request({
      method: "GET",
      url: "/backend/ssourl",
      dataType: "json",
    }).then((sso) => {
      if (sso !== null && sso.EnvironmentId !== null) {
        setEnvironmentId(sso.EnvironmentId);
        setIsLoading(false);
      }
    });
  }, []);

  function removeSSO() {
    DataAction.item({
      dataType: "sso",
    })
      .delete({
        useConfirmation: true,
      })
      .then((response) => {
        if (response && response.payload && response.payload.RedirectURL)
          window.location.href = response.payload.RedirectURL;
        else history.push("/organization");
      });
  }

  return (
    <PageLayout
      variant="small"
      title="Single Sign-On"
      navBarType="closable"
      returnTo="/organization"
    >
      {!loading && (
        <Form.Fragment
          onValidate={validateForm}
          dataItemProps={{
            dataType: "sso",
            id: environmentId,
          }}
          defaultValue={{
            ACSURL: getACSURL(userInfo),
          }}
          renderContent={() => (
            <React.Fragment>
              <SubTitle includedInFormFragment>
                {!userInfo.EnvironmentLogo ? (
                  <PageAvatar src="" type="icon">
                    organization
                  </PageAvatar>
                ) : (
                  <PageAvatar src={userInfo.EnvironmentLogo}>user</PageAvatar>
                )}
                {environmentId ? (
                  <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                  >
                    <Grid item>
                      <Icon
                        className="fa fa-check"
                        hoverable
                        label="Test SSO connection"
                        tooltipPlacement="left"
                        onClick={openTestPage}
                      />
                    </Grid>
                  </Grid>
                ) : (
                  <React.Fragment></React.Fragment>
                )}
              </SubTitle>
              <Grid container direction="column">
                <Grid item>
                  <Grid container {...formGridProps}>
                    <Grid item sm={4} xs={12}>
                      <FormLabel required htmlFor="Type">
                        Protocol
                      </FormLabel>
                    </Grid>
                    <Grid item sm xs>
                      <Form.Fields.MultiSelect
                        required
                        name="Type"
                        id="Type"
                        label={null}
                        variant="filled"
                        options={[
                          {
                            label: "SAML20",
                            value: "saml20",
                          },
                        ]}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container {...formGridProps}>
                    <Grid item sm={4} xs={12}>
                      <FormLabel htmlFor="ACSURL">ACS URL</FormLabel>
                    </Grid>
                    <Grid item sm xs>
                      <Form.Fields.Text
                        disabled
                        name="ACSURL"
                        id="ACSURL"
                        label={null}
                        variant="filled"
                        inputRef={ACSURLRef}
                      />
                      <Button
                        variant="text"
                        onClick={() =>
                          Utils.copyToClipboard(
                            getACSURL(userInfo),
                            ACSURLRef.current
                          )
                        }
                      >
                        Copy to clipboard
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container {...formGridProps}>
                    <Grid item sm={4} xs={12}>
                      <FormLabel required htmlFor="URL">
                        Log-in URL
                      </FormLabel>
                    </Grid>
                    <Grid item sm xs>
                      <Form.Fields.Text
                        required
                        name="URL"
                        id="URL"
                        label={null}
                        variant="filled"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container {...formGridProps}>
                    <Grid item sm={4} xs={12}>
                      <FormLabel required htmlFor="LogOutURL">
                        Log-out URL
                      </FormLabel>
                    </Grid>
                    <Grid item sm xs>
                      <Form.Fields.Text
                        required
                        name="LogOutURL"
                        id="LogOutURL"
                        label={null}
                        variant="filled"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container {...formGridProps}>
                    <Grid item sm={4} xs={12}>
                      <FormLabel required htmlFor="X509Certificate">
                        Signing Certificate
                      </FormLabel>
                    </Grid>
                    <Grid item sm xs>
                      <Form.Fields.Description
                        required
                        name="X509Certificate"
                        id="X509Certificate"
                        label={null}
                        variant="filled"
                        rows="5"
                        rowsMax="10"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container {...formGridProps}>
                    <Grid item sm={4} xs={12}>
                      <FormLabel htmlFor="EditProfileUrl">
                        Edit Profile URL
                      </FormLabel>
                    </Grid>
                    <Grid item sm xs>
                      <Form.Fields.Text
                        name="EditProfileUrl"
                        id="EditProfileUrl"
                        label={null}
                        variant="filled"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Form.Fields.Checkbox
                    name="CreateUser"
                    label="Allow Anonymous"
                  />
                </Grid>
                <Grid item>
                  <Grid container>
                    <Form.FieldArray
                      name="Mappings"
                      id="Mappings"
                      validate={validateMappings}
                    >
                      {({ fields }) => (
                        <React.Fragment>
                          {fields.length > 0 && (
                            <React.Fragment>
                              <Grid item sm={5} xs={5}>
                                <FormLabel>Enage Fields</FormLabel>
                              </Grid>
                              <Grid item sm={1} xs={1} />
                              <Grid item sm={5} xs={5}>
                                <FormLabel>IDP Fields</FormLabel>
                              </Grid>
                            </React.Fragment>
                          )}
                          {fields.map((name, index) => (
                            <React.Fragment key={name}>
                              <Grid item sm={5} xs={5}>
                                <Form.Fields.MultiSelect
                                  name={name + ".EOSField"}
                                  id={name + ".EOSField"}
                                  label={null}
                                  variant="filled"
                                  options={[
                                    {
                                      label: "First Name",
                                      value: "first_name",
                                    },
                                    {
                                      label: "Last Name",
                                      value: "last_name",
                                    },
                                    {
                                      label: "Email",
                                      value: "email",
                                    },
                                    {
                                      label: "Job Title",
                                      value: "job_title",
                                    },
                                    {
                                      label: "Groups",
                                      value: "groups",
                                    },
                                  ]}
                                />
                              </Grid>
                              <Grid item sm={1} xs={1} />
                              <Grid item sm={5} xs={5}>
                                <Form.Fields.Text
                                  name={name + ".SSOField"}
                                  id={name + ".SSOField"}
                                  label={null}
                                  variant="filled"
                                />
                              </Grid>
                              <Grid item sm={1} xs={1}>
                                <Icon
                                  type="remove"
                                  onClick={() => fields.remove(index)}
                                />
                              </Grid>
                            </React.Fragment>
                          ))}
                          <Tooltip title="Add mapping" placement="right">
                            <Button
                              onClick={() => fields.push("")}
                              style={
                                fields.length !== 0 ? { ...formMapProps } : {}
                              }
                            >
                              {fields.length !== 0 ? "+" : "Add Mapping"}
                            </Button>
                          </Tooltip>
                        </React.Fragment>
                      )}
                    </Form.FieldArray>
                  </Grid>
                </Grid>
              </Grid>
            </React.Fragment>
          )}
          renderFooterLeft={() =>
            environmentId ? (
              <Button
                variant="outlined"
                onClick={() => removeSSO()}
                style={{ marginLeft: 8 }}
              >
                Remove
              </Button>
            ) : (
              <React.Fragment></React.Fragment>
            )
          }
          onSuccess={() => {
            history.push("/organization");
          }}
          onCancel={() => {}}
        />
      )}
    </PageLayout>
  );
};

export default withUserInfo(withRouter(SSO));
