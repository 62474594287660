//@ts-check
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Utils } from "ecrion-components";
import { LinearProgress } from "@material-ui/core";
import PageLayout from "../../components/PageLayout";
import { useSettings } from "../../context/SettingsContext";
import AddOfflineLicense from "./components/AddOfflineLicense";
import AddOnlineLicense from "./components/AddOnlineLicense";
import AccountsRoute from "./components/AccountsRoute";

const AddLicense = ({ history, location }) => {
  const settings = useSettings();
  const [isLoading, setIsLoading] = useState(true);
  const [online, setOnline] = useState(false);
  const { pathname, search } = location;

  useEffect(() => {
    settings &&
      Utils.request({
        url: "/backend/environmentLicensing/status",
      }).then(
        function () {
          setOnline(true);
          setIsLoading(false);
        },
        function () {
          setOnline(false);
          setIsLoading(false);
        }
      );
  }, [settings]);

  return (
    <PageLayout
      variant="small"
      title="Add License"
      navBarType="closable"
      returnTo="/licensing"
    >
      {isLoading ? (
        <LinearProgress />
      ) : online ? (
        <AccountsRoute
          routePath={`${pathname}${search}`}
          component={AddOnlineLicense}
        />
      ) : (
        <AddOfflineLicense />
      )}
    </PageLayout>
  );
};

export default withRouter(AddLicense);
