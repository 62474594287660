//@ts-check

import React from "react";
import { withRouter } from "react-router-dom";

const AppLicensing = ({ history, location }) => {
  history.replace("/licensing");

  return <></>;
};

export default withRouter(AppLicensing);
