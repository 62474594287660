//@ts-check
import React from 'react';
import { withRouter } from 'react-router-dom';
import { Grid, FormLabel, Typography } from '@material-ui/core';
import PageLayout from '../../components/PageLayout';
import PageAvatar from '../../components/PageAvatar';
import { Form, withUserInfo, DataAction } from 'ecrion-components';
import FormaterDataList from '../../components/FormaterDataList';
import Button from '../../components/Button';
import Chip from '../../components/Chip';
import SubTitle from '../../components/SubTitle';

const formGridProps = {
  alignItems: 'center',
};

const EditGroup = ({ history, userInfo, match }) => {
  let groupName =
    match && match.params && match.params.id ? match.params.id : null;
  const fromDisabled =
    groupName === 'Administrators' || groupName === 'Everyone';

  function renderMoreWorkspaceItems(moreNumber) {
    if (moreNumber <= 0) return;

    return (
      <Typography
        color="inherit"
        display="inline"
        style={{
          paddingLeft: '8px',
        }}
      >
        + {moreNumber} more
      </Typography>
    );
  }

  function renderWorkspaceItem(workspace) {
    return <Chip iconType="workspace" label={workspace.WorkspaceName} />;
  }

  function renderMoreUsersItems(moreNumber) {
    if (moreNumber <= 0) return;

    return (
      <Typography
        color="inherit"
        display="inline"
        style={{
          paddingLeft: '8px',
        }}
      >
        + {moreNumber} more
      </Typography>
    );
  }

  function renderUserItem(user) {
    return <Chip iconType="user" label={user.DisplayName} />;
  }

  function deleteGroup() {
    DataAction.item({
      dataType: 'group',
      id: groupName,
    })
      .delete({
        useConfirmation: true,
      })
      .then(res => {
        history.return('/groups');
      });
  }

  return (
    <PageLayout
      variant="small"
      title="Edit Group"
      navBarType="closable"
      returnTo="/groups"
    >
      {userInfo.environmentName && (
        <Form.Fragment
          dataItemProps={{
            dataType: 'group',
            id: groupName,
          }}
          submitDisabled={fromDisabled}
          onValidate={data => {
            const errors = {};
            if (!data.Name)
              errors.Name = 'Name is required';

            return errors;
          }}
          renderFooterLeft={() =>
            !fromDisabled && (
              <Button
                variant="outlined"
                onClick={() => deleteGroup()}
                style={{ marginLeft: 8 }}
              >
                Delete Group
              </Button>
            )
          }
          renderContent={() => (
            <React.Fragment>
              <SubTitle includedInFormFragment>
                <PageAvatar src="" type="icon">
                  group
                </PageAvatar>
                <Typography
                  style={{
                    marginTop: 8,
                    textAlign: 'center',
                  }}
                  color="inherit"
                >
                  {groupName}
                </Typography>
              </SubTitle>

              <Grid container spacing={1} direction="column">
                <Grid item>
                  <Grid container {...formGridProps}>
                    <Grid item sm={4} xs={12}>
                      <FormLabel htmlFor="Name" required>
                        Name
                      </FormLabel>
                    </Grid>
                    <Grid item sm xs>
                      <Form.Fields.Name
                        name="Name"
                        id="name"
                        label={null}
                        required
                        disabled={fromDisabled}
                        type="groupName"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container {...formGridProps}>
                    <Form.Fields.Checkbox
                      name="HasEnvironmentRights"
                      label="Can manage organization"
                      disabled={groupName === 'Administrators'}
                    />
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container {...formGridProps}>
                    <Grid item sm={4} xs={12}>
                      <FormLabel htmlFor="Groups">Workspaces</FormLabel>
                    </Grid>
                    <Grid item xs>
                      <FormaterDataList
                        id={groupName}
                        maxCount={3}
                        render={renderWorkspaceItem}
                        renderMore={renderMoreWorkspaceItems}
                        dataType="groupWorkspaces"
                        dataProps={{
                          groupName: groupName,
                        }}
                        actionLabel="Manage Workspaces"
                        actionClick={() =>
                          history.push('/group/' + groupName + '/workspaces')
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container {...formGridProps}>
                    <Grid item sm={4} xs={12}>
                      <FormLabel htmlFor="Users">Users</FormLabel>
                    </Grid>
                    <Grid item xs>
                      <FormaterDataList
                        id={groupName}
                        maxCount={3}
                        render={renderUserItem}
                        renderMore={renderMoreUsersItems}
                        dataType="groupUsers"
                        dataProps={{
                          groupName: groupName,
                        }}
                        actionLabel="Manage Users"
                        actionClick={() =>
                          history.push('/group/' + groupName + '/users')
                        }
                      />
                      {/* <Button
                        variant="outlined"
                        onClick={() =>
                          history.push('/group/' + groupName + '/users')
                        }
                        style={{ marginLeft: 8 }}
                      >
                        Manage Users
                      </Button> */}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </React.Fragment>
          )}
          onSuccess={() => {
            history.return('/groups');
          }}
          onCancel={() => { }}
        />
      )}
    </PageLayout>
  );
};

export default withUserInfo(withRouter(EditGroup));
