//@ts-check

import React from "react";
import { withRouter } from "react-router-dom";
import PageLayout from "../../components/PageLayout";
import PageAvatar from "../../components/PageAvatar";
import Item from "../../components/Item";
import SubTitle from "../../components/SubTitle";
import ProgressiveDataList from "../../components/ProgressiveDataList";
import { getDisplayName } from "../../utils/getDisplayName";

import { DataItem, DataFilter } from "ecrion-components";
import { Typography, Grid, List } from "@material-ui/core";

const License = ({ history, location, match }) => {
  let keyId = match && match.params && match.params.id ? match.params.id : null;

  function getDescription(license) {
    return `${license.AssignedTo.length} of ${license.UsersCount}`;
  }

  function renderContent(value, licensedUsers) {
    if (!value) return <div />;

    return (
      <div>
        <SubTitle>
          <PageAvatar src={value.IconURL} type="icon">
            key
          </PageAvatar>
          <Typography
            style={{
              marginTop: 8,
              textAlign: "center",
            }}
            color="inherit"
          >
            {value.Key + ` (License Id: ${keyId})`}
          </Typography>
          <Typography
            style={{
              marginTop: 8,
              textAlign: "center",
            }}
            color="inherit"
          >
            {getDescription(value)}
          </Typography>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item>
              <DataFilter
                history={history}
                location={location}
                searchIn="query"
              />
            </Grid>
          </Grid>
        </SubTitle>

        <Grid container direction="column" justify="center">
          <Grid item>
            {licensedUsers.data.length === 0 ? (
              <Typography color="inherit" style={{ textAlign: "center" }}>
                <i>No licensed user</i>
              </Typography>
            ) : (
              <List>
                {licensedUsers.data.map((user) => (
                  <Item
                    key={user.UserName}
                    label={getDisplayName(
                      user.FirstName,
                      user.LastName,
                      user.UserName
                    )}
                    iconURL={user.ProfileImage}
                    letterAvatar={
                      !user.ProfileImage &&
                      getDisplayName(
                        user.FirstName,
                        user.LastName,
                        user.UserName
                      )
                    }
                  />
                ))}
              </List>
            )}
          </Grid>
        </Grid>
      </div>
    );
  }

  return (
    <DataItem dataType="environmentLicenses" id={keyId}>
      {({ value }) => (
        <PageLayout
          variant="small"
          title="Licensed Users"
          navBarType="closable"
          returnTo="/licensing"
        >
          {!value ? (
            <div />
          ) : (
            <ProgressiveDataList
              id={keyId + "licensedUsers"}
              dataType="licensedUsers"
              dataProps={{
                filter: DataFilter.createFromLocation(location),
                keyId: keyId,
              }}
            >
              {({ state }) => {
                return <div>{renderContent(value, state)}</div>;
              }}
            </ProgressiveDataList>
          )}
        </PageLayout>
      )}
    </DataItem>
  );
};

export default withRouter(License);
