import React from "react";
import { useAccountsAuth } from "../../../context/AccountsContext";

function AccountsRoute({ component: Component, routePath, ...rest }) {
  const { isAuthenticated, signIn } = useAccountsAuth();

  if (!isAuthenticated()) {
    signIn({ routePath: routePath });
    return <div />;
  }

  return <Component {...rest} />;
}

export default AccountsRoute;
