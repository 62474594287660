import React, { useRef, useState } from "react";
import { ModalWindow, Utils, DataAction, Form } from "ecrion-components";
import { Grid, Typography, CircularProgress } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const ReleaseOfflineDialog = ({
  productKeyId,
  accountsUrl,
  onClose,
  theme,
  classes
}) => {
  const keyPreview = useRef(null);
  const [uninstallKey, setUninstallKey] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const copyToClipboard = () => {
    Utils.copyToClipboard(uninstallKey, keyPreview.current);
  };

  const onRemove = function() {
    setLoading(true);
    return DataAction.item({
      dataType: "environmentLicenses",
      id: productKeyId,
      dataProps: {
        offline: true
      }
    })
      .delete()
      .then(function(data) {
        setLoading(false);
        setUninstallKey(data.payload.Data[0]);
      })
      .catch(() => {
        setLoading(false);
        setError(error);
      });
  };

  const renderHeader = () => "Release license offline";
  const renderContentKey = () => {
    return (
      <Grid style={{ margin: theme.spacing(2) }}>
        <Grid item>
          <Typography style={{ marginBottom: theme.spacing(2) }}>
            {`Use the key bellow to uninstall license on `}
            <a
              target="_blank"
              href={`${accountsUrl}/home/product-keys`}
              rel="noopener noreferrer"
            >
              Ecrion Accounts
            </a>
            {` for product key: '${productKeyId}'`}
          </Typography>
        </Grid>
        <Grid item>
          <Form.Input.Text
            multiline
            value={uninstallKey}
            inputRef={keyPreview}
            readOnly
          />
        </Grid>
      </Grid>
    );
  };

  const renderContent = () => {
    return (
      <Grid style={{ margin: theme.spacing(2) }}>
        <Grid item>{error && <Form.Error>error</Form.Error>}</Grid>
        <Grid item>
          <Typography style={{ marginBottom: theme.spacing(2) }}>
            {"You will receive an uninstall key which must be used on "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`${accountsUrl}/home/product-keys`}
            >
              Ecrion Accounts
            </a>
          </Typography>
          <Typography style={{ marginBottom: theme.spacing(2) }}>
            <b>Are you sure you want to release license in offline mode?</b>
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const renderLoading = () => {
    return <CircularProgress className={classes.loadingIndicator} />;
  };

  return (
    <ModalWindow
      onClose={onClose}
      renderHeader={renderHeader}
      renderContent={
        loading
          ? renderLoading
          : !uninstallKey
          ? renderContent
          : renderContentKey
      }
      preventWindowClosing={true}
      footerButtons={{
        submit: !uninstallKey
          ? {
              children: "Remove",
              onClick: () => onRemove()
            }
          : null,
        cancel: uninstallKey ? {} : null,
        leftAction: uninstallKey
          ? {
              children: <Typography>Copy to clipboard</Typography>,
              onClick: () => copyToClipboard()
            }
          : null
      }}
    />
  );
};

const styles = theme => ({
  content: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  loadingIndicator: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginLeft: "-25px",
    marginTop: "-25px"
  }
});

ReleaseOfflineDialog.defaultProps = {
  title: "Embed",
  message: "Are you sure you want to do this?",
  confirmationButtonLabel: "Confirm"
};

export default withStyles(styles, { withTheme: true })(ReleaseOfflineDialog);
